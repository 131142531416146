import { FC, memo } from "react";
import { Navigate } from "react-router-dom";
import { useAuthUser } from "../../hooks/use-auth-user";

interface RouteAuthGuardProps {
  component: React.ReactNode;
  redirectPath?: string;
  redirectComponent?: React.ReactNode;
}

const RouteAuthGuard: FC<RouteAuthGuardProps> = memo(
  ({ component, redirectPath, redirectComponent }) => {
    const { userProfile } = useAuthUser();

    // 認証済みのユーザーでない場合
    // リダイレクトする場合
    if (!userProfile?.userName && redirectPath) {
      return <Navigate to={redirectPath} replace={false} />;
    }

    // コンポーネントを切り替える場合
    if (!userProfile?.userName && redirectComponent) {
      return <>{redirectComponent}</>;
    }

    return <>{component}</>;
  }
);

export default RouteAuthGuard;
