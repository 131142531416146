/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { css } from "@emotion/react";
import BasicButton from "../../atoms/buttons/basic-button";
import {
  DEFAULT_BUTTON_BG_COLOR,
  WHITE_COLOR,
} from "../../../constants/style/color.constant";

interface CloseButtonProps {
  caption?: string;
  color?: string;
  width?: string;
  height?: string;
  margin?: string;
  padding?: string;
  buttonWidth?: string;
  onClickCloseButton: () => void;
}

const CloseButton: FC<CloseButtonProps> = memo(
  ({
    caption = "CLOSE",
    color = DEFAULT_BUTTON_BG_COLOR,
    width = "100%",
    height = "auto",
    margin = "0px",
    padding,
    buttonWidth = "130px",
    onClickCloseButton,
  }) => {
    const buttonWrapper = css`
      width: ${width};
      height: ${height};
      margin: ${margin};
      text-align: center;
    `;

    return (
      <div css={buttonWrapper}>
        <BasicButton
          width={buttonWidth}
          caption={caption}
          onClickButton={onClickCloseButton}
          color={WHITE_COLOR}
          backgroundColor={color}
          padding={padding}
        />
      </div>
    );
  }
);

export default CloseButton;
