import { FC } from "react";
import Dashboard from "../../../../components/template/dashboard/dashboard";
import { DashboardData } from "../../../../types/dashboard/dashboard.type";
import { DEMO_CLIENTS_DATA } from "../../../../constants/demo/clients.constant";
import { DEMO_FINANCE_DATA } from "../../../../constants/demo/finance.constant";
import {
  DEMO_COMPACT_TASK_DATA,
  DEMO_COMPACT_PROJECT_DATA,
} from "../../../../constants/demo/dashboard.constant";
import { DEMO_LAST_MONTH_PRESON_HOURS_DATA } from "../../../../constants/demo/person-hours.constant";
import { DEMO_CALENDAR_DATA } from "../../../../constants/demo/schedule.constant";

const DemoDashboardPage: FC = () => {
  return <Dashboard dashboardData={dashboardData} />;
};

export default DemoDashboardPage;

const dashboardData: DashboardData = {
  incomeAndExpenditure: {
    targetAnnualIncome: DEMO_FINANCE_DATA.TARGET_ANNUAL_INCOME,
    totalMonthlyIncome: DEMO_FINANCE_DATA.TOTAL_MONTHLY_INCOME,
    monthlyIncome: DEMO_FINANCE_DATA.MONTHLY_INCOME,
    monthlyExpenditure: DEMO_FINANCE_DATA.MONTHLY_EXPENDITURE,
  },
  clients: [
    {
      companyName: DEMO_CLIENTS_DATA[0].companyName,
      contractStartDate: DEMO_CLIENTS_DATA[0].contractStartDate,
      contractEndDate: DEMO_CLIENTS_DATA[0].contractEndDate,
    },
    {
      companyName: DEMO_CLIENTS_DATA[1].companyName,
      contractStartDate: DEMO_CLIENTS_DATA[1].contractStartDate,
      contractEndDate: DEMO_CLIENTS_DATA[1].contractEndDate,
    },
    {
      companyName: DEMO_CLIENTS_DATA[2].companyName,
      contractStartDate: DEMO_CLIENTS_DATA[2].contractStartDate,
      contractEndDate: DEMO_CLIENTS_DATA[2].contractEndDate,
    },
  ],
  tasks: DEMO_COMPACT_TASK_DATA,
  projects: DEMO_COMPACT_PROJECT_DATA,
  personHours: DEMO_LAST_MONTH_PRESON_HOURS_DATA,
  schedule: DEMO_CALENDAR_DATA,
};
