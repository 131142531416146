/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { css, Interpolation, Theme } from "@emotion/react";
import { STAUS_COLORS } from "../../../../constants/style/color.constant";
import { StatusLabel } from "../../../../types/index.type";
import { convertStatusToLabel } from "../../../../lib/converter";
import { WHITE_COLOR } from "../../../../constants/style/color.constant";

interface StatusIconProps {
  statusLabelList: StatusLabel[];
  statusColorList?: string[];
  status: number;
  fontSize?: string;
  width?: string;
  padding?: string;
  style?: Interpolation<Theme>;
}

const StatusIcon: FC<StatusIconProps> = memo(
  ({
    statusLabelList,
    statusColorList = STAUS_COLORS,
    status,
    fontSize = "14px",
    width = "100px",
    padding = "5px",
    style,
  }) => {
    const statusColor = css`
      background-color: ${statusColorList[status]};
    `;
    const textStyle = css`
      font-size: ${fontSize};
    `;
    const iconSize = css`
      width: ${width};
      padding: ${padding};
    `;
    return (
      <div css={[container, statusColor, textStyle, iconSize, style]}>
        <b>{convertStatusToLabel(statusLabelList, status)}</b>
      </div>
    );
  }
);

export default StatusIcon;

const container = css`
  border-radius: 10px;
  color: ${WHITE_COLOR};
  display: flex;
  justify-content: center;
  align-self: center;
  cursor: default;
`;
