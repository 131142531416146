import { FC, memo } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { formatWithComma } from "../../../../lib/formatter";
import BarChartTooltip from "../../tooltip/bar-chart-tooltip";

interface VerticalBarChartProps {
  data: any[];
  width?: string;
  minWidth: number;
  height: string;
  dataKey: string;
  valueKey: string;
  fillColor?: string;
  barSize?: number;
  fontSize?: string;
  unit: string;
}

const VerticalBarChart: FC<VerticalBarChartProps> = memo(
  ({
    data,
    width = "100%",
    minWidth = "unset",
    height,
    dataKey,
    valueKey,
    fillColor = "#5abbae",
    barSize = 20,
    fontSize = "10px",
    unit,
  }) => {
    return (
      <ResponsiveContainer width={width} minWidth={minWidth} height={height}>
        <BarChart data={data} layout="vertical" compact={false}>
          <CartesianGrid strokeDasharray={1} />
          <XAxis
            tickFormatter={(value) => formatWithComma(value)}
            type="number"
            fontSize={13}
            cx={"50%"}
            unit={unit}
          />
          <YAxis type="category" dataKey={dataKey} fontSize={14} cy={"50%"} />
          <Tooltip content={<BarChartTooltip />} />
          <Bar
            dataKey={valueKey}
            fontSize={fontSize}
            fill={fillColor}
            stackId="a"
            barSize={barSize}
            unit={unit}
          />
        </BarChart>
      </ResponsiveContainer>
    );
  }
);

export default VerticalBarChart;
