/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { css } from "@emotion/react";
import BasicButton from "../../../../atoms/buttons/basic-button";
import { MEDIA_QUERY } from "../../../../../constants/style/media.constant";

interface HeroTitleProps {
  onClickPlayDemo: () => void;
}

const HeroTitle: FC<HeroTitleProps> = memo(({ onClickPlayDemo }) => {
  return (
    <div css={container}>
      <div>
        <p css={[titleStyle, textStyle]}>
          <b>自由</b>を<b>加速</b>させる
          <br />
          新しいパートナー
        </p>

        <div css={descriptionWrapper}>
          <p css={textStyle}>
            Solo Syncはフリーランスや個人事業主向けの
            <br />
            オールインワンの業務効率化アプリです。
          </p>
          <br />
          <p css={textStyle}>
            あなたのビジネスをよりスムーズに、
            <br />
            より効率的に運営するためのパートナーになります。
          </p>
        </div>

        <BasicButton
          width="150px"
          height="50px"
          caption="デモを体験する"
          onClickButton={onClickPlayDemo}
        />
      </div>
    </div>
  );
});

export default HeroTitle;

const container = css`
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  ${MEDIA_QUERY.SP} {
    width: 90%;
    margin: 0px auto;
  }
`;

const titleStyle = css`
  font-size: 50px;

  ${MEDIA_QUERY.SP} {
    font-size: 35px;
  }
`;

const textStyle = css`
  color: #ffffff;
`;

const descriptionWrapper = css`
  margin: 30px 0px;
`;
