/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { css } from "@emotion/react";
import { MEDIA_QUERY } from "../../../constants/style/media.constant";
import { BG_COLOR } from "../../../constants/style/color.constant";

interface HeaderContainerProps {
  width?: string;
  height?: string;
  margin?: string;
  backgroundColor?: string;
  children: React.ReactNode;
}

const HeaderContainer: FC<HeaderContainerProps> = memo(
  ({
    width = "100%",
    height = "70px",
    margin = "0px auto",
    backgroundColor = BG_COLOR,
    children,
  }) => {
    const container = css`
      height: ${height};
      width: ${width};
      margin: ${margin};
      position: fixed;
      z-index: 999;
      background-color: ${backgroundColor};

      display: flex;
      justify-content: center;
      align-items: center;

      ${MEDIA_QUERY.SP} {
        height: 55px;
      }
    `;

    return (
      <header css={[container]}>
        <div css={wrapper}>{children}</div>
      </header>
    );
  }
);

export default HeaderContainer;

const wrapper = css`
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
