/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { css } from "@emotion/react";
import Title from "../../../atoms/characters/title/title";
import { TITLE_SIZE } from "../../../../constants/element/element.constant";
import ThemeFrame from "../../../atoms/frame/theme-frame";

interface ChartFrameProps {
  title?: string;
  width?: string;
  height?: string;
  children: React.ReactNode;
  margin?: string;
  flexGrow?: number;
  gap?: string;
  scrollX?: boolean;
  scrollY?: boolean;
}

const ChartFrame: FC<ChartFrameProps> = memo(
  ({
    width,
    height,
    margin,
    flexGrow,
    title,
    children,
    gap = 0,
    scrollX = false,
    scrollY = false,
  }) => {
    const overflowX = scrollX ? "scroll" : "hidden";
    const overflowY = scrollY ? "scroll" : "hidden";

    const contentWrapper = css`
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      position: relative;
      overflow-x: ${overflowX};
      overflow-y: ${overflowY};
      gap: ${gap};
    `;

    return (
      <ThemeFrame
        width={width}
        height={height}
        margin={margin}
        flexGrow={flexGrow}
      >
        {title ? (
          <div css={titleWrapper}>
            <Title text={title} titleSize={TITLE_SIZE.SMALL} fontSize="20px" />
          </div>
        ) : null}
        <div css={contentWrapper}>{children}</div>
      </ThemeFrame>
    );
  }
);

export default ChartFrame;

const titleWrapper = css`
  width: 90%;
  margin: auto;
`;
