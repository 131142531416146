import { FC, memo } from "react";
import TrendsCombinationChart from "../../../organisms/charts/combination-charts/trends-combination-chart";
import { ProfitTrendsData } from "../../../../types/finance/finance.type";
import {
  PROFIT_TRENDS_BAR_CHART_DATA,
  PROFIT_TRENDS_LINE_CHART_DATA,
} from "../../../../constants/charts/charts.constant";
import {
  MONTH_UNIT,
  PERCENT_UNIT,
  YEN_UNIT,
} from "../../../../constants/unit/unit.constant";
import { convertToTenThousand } from "../../../../lib/converter";
import { formatWithComma } from "../../../../lib/formatter";

interface ProfitTrendsProps {
  data: ProfitTrendsData[];
  width?: string;
  height: number;
}

const ProfitTrends: FC<ProfitTrendsProps> = memo(
  ({ data, width = "100%", height }) => {
    return (
      <TrendsCombinationChart
        data={data}
        xDataKey="month"
        y1DataKey={PROFIT_TRENDS_BAR_CHART_DATA[0].dataKey}
        y2DataKey={PROFIT_TRENDS_LINE_CHART_DATA.dataKey}
        barChartData={PROFIT_TRENDS_BAR_CHART_DATA}
        lineChartData={PROFIT_TRENDS_LINE_CHART_DATA}
        yAxisLabel1="金額(万円)"
        yAxisLabel2="利益率(%)"
        width={width}
        height={height}
        unitXAxis={MONTH_UNIT}
        unitXBar={[YEN_UNIT, YEN_UNIT, YEN_UNIT]}
        unitYLine={PERCENT_UNIT}
        tickFormatter1={(value) => formatWithComma(convertToTenThousand(value))}
      />
    );
  }
);

export default ProfitTrends;
