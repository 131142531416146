/** @jsxImportSource @emotion/react */
import { FC } from "react";
import { css, keyframes } from "@emotion/react";
import { useDialog } from "../../../hooks/use-dialog";
import ThemeFrame from "../../atoms/frame/theme-frame";
import { MEDIA_QUERY } from "../../../constants/style/media.constant";
import Text from "../../atoms/characters/text/text";
import ConfirmButtons from "../../molecules/buttons/confirm-buttons";
import { DialogType } from "../../../types/index.type";
import CloseButton from "../../molecules/buttons/close-button";
import { WHITE_COLOR } from "../../../constants/style/color.constant";

const CustomDialog: FC = () => {
  const { showDialogFlg, message, dialogType, closeDialog, onClickOKButton } =
    useDialog();

  const generateButtons = (dialogType: DialogType) => {
    switch (dialogType) {
      case "caution":
        return (
          <CloseButton
            padding="10px"
            margin="20px 0px 0px 0px"
            onClickCloseButton={closeDialog}
          />
        );
      case "confirm":
        return (
          <ConfirmButtons
            padding="10px"
            margin="20px 0px 0px 0px"
            onClickOKButton={onClickOKButton}
            onClickCancelButton={closeDialog}
          />
        );
      default:
        return null;
    }
  };

  return showDialogFlg ? (
    <ThemeFrame
      position="fixed"
      width="500px"
      height="350px"
      color={WHITE_COLOR}
      style={container}
      padding="35px"
    >
      <div css={wrapper}>
        <div css={textWrapper}>
          <div>
            <Text text={message} />
          </div>
        </div>

        <div css={buttonWrapper}>{generateButtons(dialogType)}</div>
      </div>
    </ThemeFrame>
  ) : null;
};

export default CustomDialog;

const display = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const container = css`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  justify-content: center;
  align-items: center;

  animation: ${display} 0.8s ease;
  z-index: 9999;

  ${MEDIA_QUERY.TABLET} {
    width: 420px;
    height: 280px;
  }

  ${MEDIA_QUERY.SP} {
    width: 300px;
    height: 200px;
  }
`;

const textWrapper = css`
  height: 80%;

  display: flex;
  justify-content: center;
  align-items: center;

  ${MEDIA_QUERY.TABLET} {
    height: 75%;
  }

  ${MEDIA_QUERY.SP} {
    height: 70%;
  }
`;

const wrapper = css`
  position: relative;
  width: 100%;
  height: 100%;
`;

const buttonWrapper = css`
  /* position: absolute;
  width: 100%;
  height: 100%;
  bottom: 5%;
  right: 50%;
  left: 50%; */
`;
