/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { css, Interpolation, Theme } from "@emotion/react";
import {
  DEFAULT_FRAME_COLOR,
  DEFAULT_FRAME_SHADOW,
} from "../../../constants/style/color.constant";

interface ThemeFrameProps {
  children: React.ReactNode;
  color?: string;
  borderRadius?: string;
  shadow?: string;
  width?: string;
  height?: string;
  maxWidth?: string;
  maxHeight?: string;
  minWidth?: string;
  minHeight?: string;
  margin?: string;
  padding?: string;
  flexGrow?: number;
  gradation?: boolean;
  position?: string;
  style?: Interpolation<Theme>;
}

const ThemeFrame: FC<ThemeFrameProps> = memo(
  ({
    children,
    color = DEFAULT_FRAME_COLOR,
    borderRadius = "15px",
    shadow = DEFAULT_FRAME_SHADOW,
    width = "100%",
    height = "100%",
    maxWidth = "unset",
    maxHeight = "unset",
    minWidth = "unset",
    minHeight = "unset",
    margin = "30px 0px",
    padding = "25px",
    flexGrow = 0,
    position = "unset",
    gradation = false,
    style,
  }) => {
    const container = css`
      border-radius: ${borderRadius};
      box-shadow: ${shadow};
      width: ${width};
      height: ${height};
      margin: ${margin};
      padding: ${padding};
      flex-grow: ${flexGrow};
      max-width: ${maxWidth};
      max-height: ${maxHeight};
      min-width: ${minWidth};
      min-height: ${minHeight};
      position: ${position};
    `;

    const normalColor = css`
      background-color: ${color};
    `;

    const gradationColor = css`
      background-image: ${color};
    `;

    return (
      <div css={[container, gradation ? gradationColor : normalColor, style]}>
        {children}
      </div>
    );
  }
);

export default ThemeFrame;
