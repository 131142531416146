/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { css } from "@emotion/react";
import {
  DEFAULT_LINE_COLOR,
  DEFAULT_FRAME_COLOR,
  DEFAULT_FRAME_SHADOW,
} from "../../../../constants/style/color.constant";
import Text from "../../../atoms/characters/text/text";
import {
  DEFAULT_QUICK_START_CARD_HEIGHT,
  DEFAULT_QUICK_START_CARD_WIDTH,
} from "../../../../constants/style/size.constant";

interface QuickStartCardProps {
  boldText?: string;
  text?: string;
  width?: string;
  height?: string;
  maxWidth?: string;
  maxHeight?: string;
  lineColor?: string;
  backgroundColor?: string;
  flexGrow?: number;
  icon?: React.ReactNode;
  onClickQuickStart?: () => void;
}

const QuickStartCard: FC<QuickStartCardProps> = memo(
  ({
    boldText,
    text,
    width = DEFAULT_QUICK_START_CARD_WIDTH,
    height = DEFAULT_QUICK_START_CARD_HEIGHT,
    maxWidth = "unset",
    maxHeight = "unset",
    lineColor = DEFAULT_LINE_COLOR,
    backgroundColor = DEFAULT_FRAME_COLOR,
    flexGrow = 0,
    icon,
    onClickQuickStart,
  }) => {
    const container = css`
      width: ${width};
      height: ${height};
      max-width: ${maxWidth};
      max-height: ${maxHeight};
      display: flex;
      border-radius: 10px;
      overflow: hidden;
      flex-grow: ${flexGrow};
      background-color: ${backgroundColor};
      box-shadow: ${DEFAULT_FRAME_SHADOW};
      transition: transform 0.3s ease;
      :hover {
        transform: translateY(-10px);
      }
    `;

    const lineStyle = css`
      background-color: ${lineColor};
      width: 10px;
    `;

    return (
      <div css={container} onClick={onClickQuickStart}>
        <span css={lineStyle}></span>
        <div css={wrapper}>
          <div css={textWrapper}>
            {text ? <Text text={text} isBold={false} /> : null}
            {boldText ? <Text text={boldText} isBold={true} /> : null}
          </div>
          <div>{icon ? icon : null}</div>
        </div>
      </div>
    );
  }
);

export default QuickStartCard;

const wrapper = css`
  padding: 20px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const textWrapper = css`
  text-align: center;
  width: 180px;
`;
