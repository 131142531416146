/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { css, Interpolation, Theme } from "@emotion/react";
import { WHITE_COLOR } from "../../../constants/style/color.constant";

interface ThemeScreenProps {
  color?: string;
  width?: string;
  height?: string;
  gradation?: boolean;
  children: React.ReactNode;
  style?: Interpolation<Theme>;
}

const ThemeScreen: FC<ThemeScreenProps> = memo(
  ({
    color = WHITE_COLOR,
    width = "100vw",
    height = "100vh",
    gradation = false,
    children,
    style,
  }) => {
    const container = css`
      width: ${width};
      height: ${height};
    `;

    const normalColor = css`
      background-color: ${color};
    `;

    const gradationColor = css`
      background-image: ${color};
    `;

    return (
      <div css={[container, style, gradation ? gradationColor : normalColor]}>
        {children}
      </div>
    );
  }
);

export default ThemeScreen;
