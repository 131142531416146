import { FC, useCallback } from "react";
import Finance from "../../../components/template/finance/finance";

const FinancePage: FC = () => {
  const onClickAddAnnualIncome = useCallback(() => {}, []);

  const onClickAddAnnualExpenditure = useCallback(() => {}, []);

  const onClickAddMonthlyIncome = useCallback(() => {}, []);

  const onClickAddMonthlyExpenditure = useCallback(() => {}, []);

  return (
    <Finance
      monthlyIncome={300000}
      monthlyExpenditure={120000}
      totalMonthlyIncome={4000000}
      totalMonthlyExpenditure={2000000}
      targetAnnualIncome={5000000}
      targetAnnualProfit={3000000}
      targetAnnualProfitRatio={65}
      profitTrendsData={[
        {
          month: 1,
          income: 450000,
          expenditure: 90000,
          profit: 360000,
          profitRatio: 85,
        },
        {
          month: 2,
          income: 550000,
          expenditure: 10000,
          profit: 540000,
          profitRatio: 98,
        },
        {
          month: 3,
          income: 420000,
          expenditure: 50000,
          profit: 370000,
          profitRatio: 82,
        },
        {
          month: 4,
          income: 650000,
          expenditure: 150000,
          profit: 500000,
          profitRatio: 78,
        },
        {
          month: 5,
          income: 370000,
          expenditure: 30000,
          profit: 340000,
          profitRatio: 92,
        },
        {
          month: 6,
          income: 250000,
          expenditure: 30000,
          profit: 220000,
          profitRatio: 89,
        },
        {
          month: 7,
          income: 700000,
          expenditure: 120000,
          profit: 580000,
          profitRatio: 85,
        },
        {
          month: 8,
          income: 450000,
          expenditure: 90000,
          profit: 360000,
          profitRatio: 85,
        },
        {
          month: 9,
          income: 900000,
          expenditure: 200000,
          profit: 700000,
          profitRatio: 85,
        },
        {
          month: 10,
          income: 670000,
          expenditure: 120000,
          profit: 550000,
          profitRatio: 84,
        },
        {
          month: 11,
          income: 450000,
          expenditure: 90000,
          profit: 36000,
          profitRatio: 80,
        },
        {
          month: 12,
          income: 410000,
          expenditure: 40000,
          profit: 370000,
          profitRatio: 90,
        },
      ]}
      annualIncomeAnalyticsData={[
        {
          incomeTitle: "開発(フロントエンド)",
          incomeAmount: 350000000,
        },
        {
          incomeTitle: "開発(バックエンド)",
          incomeAmount: 120000000,
        },
      ]}
      annualExpenditureAnalyticsData={[
        {
          expenditureTitle: "移動費",
          expenditureAmount: 134000,
        },
        {
          expenditureTitle: "会食費",
          expenditureAmount: 3600000,
        },
        {
          expenditureTitle: "オフィス用具",
          expenditureAmount: 120000,
        },
      ]}
      monthlyIncomeAnalyticsData={[
        {
          incomeTitle: "開発(フロントエンド)",
          incomeAmount: 2000000,
        },
        {
          incomeTitle: "開発(バックエンド)",
          incomeAmount: 450000,
        },
      ]}
      monthlyExpenditureAnalyticsData={[
        {
          expenditureTitle: "会食費",
          expenditureAmount: 400000,
        },
        {
          expenditureTitle: "移動費",
          expenditureAmount: 21000,
        },
      ]}
      onClickAddAnnualIncome={onClickAddAnnualIncome}
      onClickAddAnnualExpenditure={onClickAddAnnualExpenditure}
      onClickAddMonthlyIncome={onClickAddMonthlyIncome}
      onClickAddMonthlyExpenditure={onClickAddMonthlyExpenditure}
    />
  );
};

export default FinancePage;
