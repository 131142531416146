/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { css } from "@emotion/react";
import AchievementRatePieChart from "../../../organisms/charts/pie-charts/achievement-rate-pie-chart";
import { formatPercent } from "../../../../lib/formatter";
import { calculatePercent } from "../../../../lib/calculator";

interface AnnualIncomeProps {
  targetAnnualIncome: number;
  totalMonthlyIncome: number;
  width: number | string;
  height: number | string;
  containerWidth?: string;
  containerHeight?: string;
  displayCompact?: boolean;
  textColor?: string;
  fillColors?: string[];
  innerRadius?: string;
}

const AnnualIncome: FC<AnnualIncomeProps> = memo(
  ({
    targetAnnualIncome,
    totalMonthlyIncome,
    width,
    height,
    containerWidth = "100%",
    containerHeight = "100%",
    displayCompact = false,
    textColor,
    fillColors,
    innerRadius,
  }) => {
    const container = css`
      width: ${containerWidth};
      height: ${containerHeight};
      position: relative;
    `;

    const textStyle = css`
      font-size: 16px;
      color: ${textColor};
    `;

    return (
      <div css={container}>
        <AchievementRatePieChart
          targetValue={targetAnnualIncome}
          achievementValue={totalMonthlyIncome}
          innerRadius={innerRadius}
          width={width}
          height={height}
          displayCompact={displayCompact}
          fillColors={fillColors}
          dataType="money"
        />
        <div css={textContainer}>
          <div css={textStyle}>
            {formatPercent(
              calculatePercent(totalMonthlyIncome, targetAnnualIncome)
            )}
          </div>
        </div>
      </div>
    );
  }
);

export default AnnualIncome;

const textContainer = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;
