/**
 * 今年の数字を取得
 * @returns number
 */
export const getCurrentYear = () => {
  return new Date().getFullYear();
}

/**
 * 今月が何月か数字を取得
 * @returns number
 */
export const getCurrentMonth = () => {
  return new Date().getMonth() + 1;
};

/**
 * 先月が何月か数字を取得
 * @returns number
 */
export const getLastMonth = () => {
  return new Date().getMonth();
};

/**
 * 日数を取得
 * @returns number
 */
export const getCurrentDate = () => {
  return new Date().getDate();
};

/**
 * 過去の月を取得
 * @param value 
 * @returns number
 */
export const getPreviousMonth = (value: number): number => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  let targetMonth = currentMonth - value;

  // 計算された月が0未満の場合、年を調整する
  if (targetMonth <= 0) {
    targetMonth += 12;
  }

  return targetMonth;
}

/**
 * 過去の日付を取得
 * @param value 
 * @returns date
 */
export const getPreviousDate = (value: number): Date => {
  const currentDate = new Date();
  const previousDate = new Date(currentDate.setDate(currentDate.getDate() - value)); // 指定された日数だけ過去の日付を計算
  return previousDate;
};

/**
 * 将来の日付を取得
 * @param value 
 * @returns date
 */
export const getFutureDate = (value: number): Date => {
  const currentDate = new Date();
  const futureDate = new Date(currentDate.setDate(currentDate.getDate() + value)); // 指定された日数だけ未来の日付を計算
  return futureDate;
};





