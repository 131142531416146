/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { css } from "@emotion/react";
import {
  convertToShortName,
  convertToShortCompanyName,
} from "../../../../lib/converter";
import ListCard from "../../../molecules/cards/list-card/list-card";
import { BriefProjectInformation } from "../../../../types/projects/projects.type";
import RelatedItem from "../../../atoms/icon/related-item/related-item";
import { applyColorToList } from "../../../../lib/style-composer";
import StatusIcon from "../../../atoms/icon/status/status-icon";
import { NO_PROJECTS_IN_PROGRESS } from "../../../../constants/description/description.constant";
import Label from "../../../molecules/label/label";
import { PROJECT_PROGRESS_STATUS_COLORS } from "../../../../constants/style/color.constant";
import { PROJECT_PROGRESS_STATUS_LABEL } from "../../../../constants/label/label.constant";
import { MEDIA_QUERY } from "../../../../constants/style/media.constant";

interface CompactProjectInProgressListProps {
  projectData: BriefProjectInformation[];
  fontSize?: string;
}

const CompactProjectInProgressList: FC<CompactProjectInProgressListProps> =
  memo(({ projectData, fontSize = "14px" }) => {
    return (
      <div css={container}>
        {projectData && projectData.length !== 0 ? (
          projectData.map((project, index) => (
            <ListCard
              key={index}
              title={convertToShortName(project.projectName, 15)}
              color={applyColorToList(index)}
              fontSize={fontSize}
              gap="5px"
            >
              <StatusIcon
                statusColorList={PROJECT_PROGRESS_STATUS_COLORS}
                statusLabelList={PROJECT_PROGRESS_STATUS_LABEL}
                status={project.projectProgressStatus}
              />

              <RelatedItem
                caption={convertToShortCompanyName(project.companyName, 6)}
                style={companyStyle}
              />
            </ListCard>
          ))
        ) : (
          <Label width="100%" text={NO_PROJECTS_IN_PROGRESS} />
        )}
      </div>
    );
  });

export default CompactProjectInProgressList;

const container = css`
  width: 100%;
`;

const companyStyle = css`
  ${MEDIA_QUERY.SP} {
    display: none;
  }
`;
