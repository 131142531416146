/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { css } from "@emotion/react";
import { INTRODUCTION_TEXT_COLOR } from "../../../../../constants/style/color.constant";
import { MEDIA_QUERY } from "../../../../../constants/style/media.constant";

const FeaturesDescription: FC = memo(() => {
  return (
    <div css={textWrapper}>
      <p css={textStyle}>
        <b>
          Solo
          Syncは煩雑な業務や時間のかかる作業を自動化し、あなたの貴重な時間を節約します。
          <br />
          <br />
          請求書の作成、顧客管理、プロジェクト管理、スケジュール管理など、あらゆる面であなたの業務をサポートします。
        </b>
      </p>
    </div>
  );
});

export default FeaturesDescription;

const textWrapper = css`
  width: 400px;

  ${MEDIA_QUERY.SP} {
    width: 270px;
  }
`;

const textStyle = css`
  color: ${INTRODUCTION_TEXT_COLOR};
  font-size: 22px;
`;
