import { DEMO_PROJECTS_DATA } from "./projects.constant";
import { PersonHoursData, WorkHoursAndIncomeData } from "../../types/person-hours/person-hours.type";
import { DEMO_PROFIT_TRENDS_DATA } from "./finance.constant";

export const DEMO_PERSON_HOURS_DATA = {
  ANNUAL_WORKING_DAYS: 185,
  ANNUAL_WORKING_HOURS: 1576,
  LAST_MONTH_WORKING_DAYS: 20,
  LAST_MONTH_WORKING_HOURS: 185,
};

export const DEMO_LAST_MONTH_PRESON_HOURS_DATA: PersonHoursData[] = [
  {
    personHoursRatio: 45,
    projectName: DEMO_PROJECTS_DATA[0].projectName,
    companyName: DEMO_PROJECTS_DATA[0].companyName
  },
  {
    personHoursRatio: 35,
    projectName: DEMO_PROJECTS_DATA[1].projectName,
    companyName: DEMO_PROJECTS_DATA[1].companyName
  },
  {
    personHoursRatio: 20,
    projectName: DEMO_PROJECTS_DATA[2].projectName,
    companyName: DEMO_PROJECTS_DATA[2].companyName
  },
];

export const DEMO_ANNUAL_PRESON_HOURS_DATA: PersonHoursData[] = [
  {
    personHoursRatio: 25,
    projectName: DEMO_PROJECTS_DATA[0].projectName,
    companyName: DEMO_PROJECTS_DATA[0].companyName
  },
  {
    personHoursRatio: 20,
    projectName: DEMO_PROJECTS_DATA[1].projectName,
    companyName: DEMO_PROJECTS_DATA[1].companyName
  },
  {
    personHoursRatio: 18,
    projectName: DEMO_PROJECTS_DATA[2].projectName,
    companyName: DEMO_PROJECTS_DATA[2].companyName
  },
  {
    personHoursRatio: 17,
    projectName: DEMO_PROJECTS_DATA[3].projectName,
    companyName: DEMO_PROJECTS_DATA[3].companyName
  },
  {
    personHoursRatio: 12,
    projectName: DEMO_PROJECTS_DATA[4].projectName,
    companyName: DEMO_PROJECTS_DATA[4].companyName
  },
  {
    personHoursRatio: 8,
    projectName: DEMO_PROJECTS_DATA[5].projectName,
    companyName: DEMO_PROJECTS_DATA[5].companyName
  }
];

export const DEMO_WORK_HOURS_AND_INCOME_DATA: WorkHoursAndIncomeData[] = [
  {
    month: DEMO_PROFIT_TRENDS_DATA[0].month,
    workHours: 176,
    income: DEMO_PROFIT_TRENDS_DATA[0].income
  },
  {
    month: DEMO_PROFIT_TRENDS_DATA[1].month,
    workHours: 168,
    income: DEMO_PROFIT_TRENDS_DATA[1].income
  },
  {
    month: DEMO_PROFIT_TRENDS_DATA[2].month,
    workHours: 183,
    income: DEMO_PROFIT_TRENDS_DATA[2].income
  },
  {
    month: DEMO_PROFIT_TRENDS_DATA[3].month,
    workHours: 163,
    income: DEMO_PROFIT_TRENDS_DATA[3].income
  },
  {
    month: DEMO_PROFIT_TRENDS_DATA[4].month,
    workHours: 180,
    income: DEMO_PROFIT_TRENDS_DATA[4].income
  },
  {
    month: DEMO_PROFIT_TRENDS_DATA[5].month,
    workHours: 170,
    income: DEMO_PROFIT_TRENDS_DATA[5].income
  },
  {
    month: DEMO_PROFIT_TRENDS_DATA[6].month,
    workHours: 183,
    income: DEMO_PROFIT_TRENDS_DATA[6].income
  },
  {
    month: DEMO_PROFIT_TRENDS_DATA[7].month,
    workHours: 168,
    income: DEMO_PROFIT_TRENDS_DATA[7].income
  },
  {
    month: DEMO_PROFIT_TRENDS_DATA[8].month,
    workHours: DEMO_PERSON_HOURS_DATA.LAST_MONTH_WORKING_HOURS,
    income: DEMO_PROFIT_TRENDS_DATA[8].income
  }
]

