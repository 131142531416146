/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { css } from "@emotion/react";
import HeaderContainer from "../../atoms/container/header-container";
import SoloSyncLogo from "../../atoms/icon/logo/solo-sync-logo";
import HeaderButton from "../../molecules/buttons/header-button";
import {
  PLAY_DEMO_TITLE,
  LOGIN_TITLE,
  SIGN_UP_TITLE,
} from "../../../constants/title/title.constant";
import { MEDIA_QUERY } from "../../../constants/style/media.constant";
import MenuButton from "../../atoms/buttons/menu-button";

interface PortalHeaderProps {
  menuButtonDisabledFlg: boolean;
  onClickPlayDemo: () => void;
  onClickLogin: () => void;
  onClickSignup: () => void;
  onClickMenuButton: () => void;
}

const PortalHeader: FC<PortalHeaderProps> = memo(
  ({
    onClickPlayDemo,
    onClickLogin,
    onClickSignup,
    onClickMenuButton,
    menuButtonDisabledFlg,
  }) => {
    return (
      <HeaderContainer backgroundColor="#ffffff78">
        <SoloSyncLogo logoType="dark" style={logoStyle} />

        <div css={buttonWrapper}>
          <HeaderButton
            caption={PLAY_DEMO_TITLE}
            buttonType="info"
            onClickHeaderButton={onClickPlayDemo}
            style={headerButtonStyle}
          />

          <HeaderButton
            caption={SIGN_UP_TITLE}
            buttonType="primary"
            onClickHeaderButton={onClickSignup}
            style={headerButtonStyle}
          />

          <HeaderButton
            caption={LOGIN_TITLE}
            buttonType="secondary"
            onClickHeaderButton={onClickLogin}
            style={headerButtonStyle}
          />

          <MenuButton
            style={menuButtonStyle}
            onClickMenuButton={onClickMenuButton}
            disabledFlg={menuButtonDisabledFlg}
          />
        </div>
      </HeaderContainer>
    );
  }
);

export default PortalHeader;

const buttonWrapper = css`
  display: flex;
  align-items: center;
  gap: 15px;
`;

const logoStyle = css`
  ${MEDIA_QUERY.SP} {
    font-size: 23px;
  }
`;

const headerButtonStyle = css`
  ${MEDIA_QUERY.TABLET} {
    display: none;
  }
`;

const menuButtonStyle = css`
  display: none;

  ${MEDIA_QUERY.TABLET} {
    display: unset;
  }
`;
