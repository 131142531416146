import { ScheduleData } from "../../types/schedule/schedule.type"
import { CALENDAR_EVENT_COLORS } from "../style/color.constant"
import { getPreviousDate, getFutureDate } from "../../lib/calendar"
import { convertDateToString } from "../../lib/converter"

export const DEMO_SCHEDULE_DATA ={
  PROJECTS_TO_START_THIS_MONTH: 1,
  PROJECTS_TO_END_THIS_MONTH: 0
};


export const DEMO_CALENDAR_DATA: ScheduleData[] = [
  {
    title: "UIコンポーネント作成",
    start: convertDateToString(getPreviousDate(2)),
    end: convertDateToString(getFutureDate(1)),
    description: "コーポレートサイト開発に必要なatomsを開発",
    backgroundColor: CALENDAR_EVENT_COLORS.TASK,
    borderColor: CALENDAR_EVENT_COLORS.TASK,
    allDay: true,
  },
  {
    title: "温泉旅行",
    start: convertDateToString(getPreviousDate(10)),
    end: convertDateToString(getPreviousDate(8)),
    description: "旅館の住所：○○",
    backgroundColor: CALENDAR_EVENT_COLORS.PRIVATE,
    borderColor: CALENDAR_EVENT_COLORS.PRIVATE,
    allDay: true,
  },
  {
    title: "ディナー",
    start: `${convertDateToString(getFutureDate(6))}T19:00:00`,
    end: `${convertDateToString(getFutureDate(6))}T21:30:00`,
    description: "恵比寿のレストランで友人とディナー",
    backgroundColor: CALENDAR_EVENT_COLORS.PRIVATE,
    borderColor: CALENDAR_EVENT_COLORS.PRIVATE,
    allDay: false,
  },
  {
    title: "MTG",
    start: `${convertDateToString(new Date())}T11:00:00`,
    end: `${convertDateToString(new Date())}T12:00:00`,
    description: "クライアントとMTG",
    backgroundColor: CALENDAR_EVENT_COLORS.MEETING,
    borderColor: CALENDAR_EVENT_COLORS.MEETING,
    allDay: false,
  },
  {
    title: "報告書作成",
    start: `${convertDateToString(new Date())}T16:00:00`,
    end: `${convertDateToString(new Date())}T17:00:00`,
    description: "",
    backgroundColor: CALENDAR_EVENT_COLORS.TASK,
    borderColor: CALENDAR_EVENT_COLORS.TASK,
    allDay: false,
  },
  {
    title: "通院",
    start: `${convertDateToString(getFutureDate(3))}T15:00:00`,
    end: `${convertDateToString(getFutureDate(3))}T16:00:00`,
    description: "皮膚科",
    backgroundColor: CALENDAR_EVENT_COLORS.PRIVATE,
    borderColor: CALENDAR_EVENT_COLORS.PRIVATE,
    allDay: false,
  },
  {
    title: "出張",
    start: convertDateToString(getFutureDate(8)),
    end: convertDateToString(getFutureDate(10)),
    description: "クライアントの支社へ来訪",
    backgroundColor: CALENDAR_EVENT_COLORS.OTHER,
    borderColor: CALENDAR_EVENT_COLORS.OTHER,
    allDay: true,
  },
  {
    title: "BE開発",
    start: convertDateToString(getFutureDate(4)),
    end: convertDateToString(getFutureDate(7)),
    description: "API開発",
    backgroundColor: CALENDAR_EVENT_COLORS.TASK,
    borderColor: CALENDAR_EVENT_COLORS.TASK,
    allDay: true,
  },
  {
    title: "ソースコードリファクタ",
    start: convertDateToString(getFutureDate(16)),
    end: convertDateToString(getFutureDate(20)),
    description: "",
    backgroundColor: CALENDAR_EVENT_COLORS.TASK,
    borderColor: CALENDAR_EVENT_COLORS.TASK,
    allDay: true,
  },
  {
    title: "沖縄旅行",
    start: convertDateToString(getFutureDate(23)),
    end: convertDateToString(getFutureDate(26)),
    description: "",
    backgroundColor: CALENDAR_EVENT_COLORS.PRIVATE,
    borderColor: CALENDAR_EVENT_COLORS.PRIVATE,
    allDay: true,
  },
  {
    title: "月次定例",
    start: `${convertDateToString(getFutureDate(4))}T17:00:00`,
    end: `${convertDateToString(getFutureDate(4))}T18:00:00`,
    description: "",
    backgroundColor: CALENDAR_EVENT_COLORS.MEETING,
    borderColor: CALENDAR_EVENT_COLORS.MEETING,
    allDay: false,
  },
]