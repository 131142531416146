
export const DEMO_COMPANIES = {
  A: "Aホールディングス株式会社",
  B: "B商事株式会社",
  C: "C不動産株式会社",
  D: "Dコンサルティング株式会社",
  E: "E食品株式会社",
  F: "F水産株式会社",
  G: "Gエンジニアリング株式会社",
};

export const DEMO_PROJECTS = {
  A: "コーポレートサイト開発",
  B: "IoTデバイスの刷新",
  C: "データ分析ツール開発",
  D: "CRM導入",
  E: "マッチングアプリのUI開発",
  F: "クラウドベースのデータ設計",
  G: "API開発"
}