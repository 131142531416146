import { FC, memo } from "react";
import {
  PIE_CHART_COLORS,
  DEFAULT_CHART_FILL_COLOR,
  DEFAULT_CHART_TOOLTIP_COLOR,
} from "../../../../constants/style/color.constant";
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts";
import { formatByDataType } from "../../../../lib/formatter";
import { DataType } from "../../../../types/index.type";
import { CHART_TOOLTIP_STYLE } from "../../../../constants/style/css-properties.constant";

interface CategoryPieChartProps {
  data: any[];
  width: number | string;
  height: number | string;
  displayLabel?: boolean;
  nameKey?: string;
  dataKey?: string;
  dataType: DataType;
}

const CategoryPieChart: FC<CategoryPieChartProps> = memo(
  ({
    data,
    width,
    height,
    displayLabel = true,
    nameKey = "name",
    dataKey = "value",
    dataType,
  }) => {
    const renderCustomizedLabel = ({ name }: any) => {
      return name;
    };

    return (
      <ResponsiveContainer width={width} height={height}>
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            fill={DEFAULT_CHART_FILL_COLOR}
            nameKey={nameKey}
            dataKey={dataKey}
            label={displayLabel ? renderCustomizedLabel : undefined}
            style={{ fontSize: "11px" }}
            startAngle={90}
            endAngle={-270}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={PIE_CHART_COLORS[index % PIE_CHART_COLORS.length]}
              />
            ))}
          </Pie>

          <Tooltip
            itemStyle={{ color: DEFAULT_CHART_TOOLTIP_COLOR }}
            contentStyle={CHART_TOOLTIP_STYLE}
            separator={" "}
            formatter={(value) => formatByDataType(Number(value), dataType)}
          />
        </PieChart>
      </ResponsiveContainer>
    );
  }
);

export default CategoryPieChart;
