/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { css } from "@emotion/react";
import { WHITE_COLOR } from "../../../../constants/style/color.constant";
import Title from "../../../atoms/characters/title/title";
import { TITLE_SIZE } from "../../../../constants/element/element.constant";
import FeaturesImage from "./features-parts/features-image";
import FeaturesDescription from "./features-parts/features-description";
import IntroductionContainer from "../../../atoms/container/introduction-container";

const FeaturesSection: FC = memo(() => {
  return (
    <section id="features">
      <IntroductionContainer
        contentWidth="85%"
        margin="50px auto"
        padding="100px 30px"
        color={WHITE_COLOR}
      >
        <div>
          <Title text="Solo Syncの特徴" titleSize={TITLE_SIZE.LARGE} />

          <div css={contentWrapper}>
            <FeaturesImage />
            <FeaturesDescription />
          </div>
        </div>
      </IntroductionContainer>
    </section>
  );
});

export default FeaturesSection;

const contentWrapper = css`
  display: flex;
  justify-content: center;
  gap: 5%;
  flex-wrap: wrap;
  margin: 30px 0px;
`;
