/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { css } from "@emotion/react";
import { DEFAULT_SUMMARY_COLOR } from "../../../../constants/style/color.constant";
import { TITLE_SIZE } from "../../../../constants/element/element.constant";
import Title from "../../../atoms/characters/title/title";
import ThemeFrame from "../../../atoms/frame/theme-frame";
import { MEDIA_QUERY } from "../../../../constants/style/media.constant";
import {
  checkUnitIsPixel,
  removeUnitFromStyle,
  resizePCtoSP,
} from "../../../../lib/style-composer";

interface SummaryFrameProps {
  title: string;
  width?: string;
  height?: string;
  children?: React.ReactNode;
  titleColor?: string;
  backgroundColor?: string;
  margin?: string;
  gradation?: boolean;
  fontSize?: string;
  gap?: string;
}

const SummaryFrame: FC<SummaryFrameProps> = memo(
  ({
    title,
    width = "100%",
    height = "auto",
    margin = "0 0 30px 0",
    gradation = false,
    titleColor,
    backgroundColor = DEFAULT_SUMMARY_COLOR,
    children,
    fontSize = "25px",
    gap = "10%",
  }) => {
    const contentWrapper = css`
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: ${gap};
    `;

    const spTextStyle = css`
      ${MEDIA_QUERY.SP} {
        font-size: ${resizePCtoSP(removeUnitFromStyle(fontSize), 0.88)}px;
      }
    `;

    const mediaQuery = checkUnitIsPixel(fontSize) ? spTextStyle : undefined;

    return (
      <ThemeFrame
        width={width}
        height={height}
        margin={margin}
        color={backgroundColor}
        gradation={gradation}
      >
        <div css={wrapper}>
          <div css={titleWrapper}>
            <Title
              text={title}
              titleSize={TITLE_SIZE.MEDIUM}
              color={titleColor}
              fontSize={fontSize}
              style={mediaQuery}
            />
          </div>
          <div css={contentWrapper}>{children}</div>
        </div>
      </ThemeFrame>
    );
  }
);

export default SummaryFrame;

const wrapper = css`
  width: 100%;
  height: 100%;
`;

const titleWrapper = css`
  width: 90%;
  margin: 0 auto;
`;
