import { Route } from "react-router-dom";
import AuthPageLayout from "../page/auth/auth-page-layout";
import PortalPageLayout from "../page/portal/portal-page-layout";
import DashboardPage from "../page/auth/dashboard/dashboard-page";
import NotFoundPage from "../page/error/not-found/not-found-page";
import { authRouterList } from "./router-list/auth-router-list";
import RouteAuthGuard from "./guard/route-auth-guard";
import IntroductionPage from "../page/portal/introduction/introduction-page";
import { demoRouterList } from "./router-list/demo-router-list";
import { DEMO_INDEX } from "../constants/path/path.constant";
import DemoPageLayout from "../page/portal/demo/demo-page-layout";

const generateChildRoutes = (route: any) => {
  return route?.children
    ? route.children.map((child: any, index: number) => (
        <Route
          key={index}
          path={child.path}
          element={
            <RouteAuthGuard redirectPath="/" component={child.element} />
          }
        >
          {child.children && generateChildRoutes(child)}
        </Route>
      ))
    : null;
};

export const router = (
  <Route
    path={`/`}
    element={
      <RouteAuthGuard
        redirectComponent={<PortalPageLayout />}
        component={<AuthPageLayout />}
      />
    }
    errorElement={<NotFoundPage />}
  >
    <Route
      index
      element={
        <RouteAuthGuard
          redirectComponent={<IntroductionPage />}
          component={<DashboardPage />}
        />
      }
    />

    {authRouterList.map((route, index) => (
      <Route
        key={index}
        path={route.path}
        element={
          <RouteAuthGuard redirectPath={`/`} component={route.element} />
        }
      >
        {generateChildRoutes(route)}
      </Route>
    ))}

    <Route path={DEMO_INDEX} element={<DemoPageLayout />}>
      {demoRouterList.map((route, index) => (
        <Route key={index} path={route.path} element={route.element} />
      ))}
    </Route>
  </Route>
);
