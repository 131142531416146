/** @jsxImportSource @emotion/react */
import { FC } from "react";
import { css, keyframes } from "@emotion/react";
import { DARK_SCREEN_COLOR } from "../../../constants/style/color.constant";
import { useDarkScreen } from "../../../hooks/use-dark-screen";

const DarkScreen: FC = () => {
  const { showDarkScreenFlg } = useDarkScreen();
  return showDarkScreenFlg ? <div css={container}></div> : null;
};

export default DarkScreen;

const display = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const container = css`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 2000;
  background-color: ${DARK_SCREEN_COLOR};
  animation: ${display} 0.5s ease;
`;
