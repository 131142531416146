/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { css, Interpolation, Theme } from "@emotion/react";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";

interface ProgressBarProps {
  width?: string;
  progressValue: number;
  style?: Interpolation<Theme>;
}

const ProgressBar: FC<ProgressBarProps> = memo(
  ({ width = "100%", progressValue, style }) => {
    const container = css`
      width: ${width};
    `;
    return (
      <div css={[container, style]}>
        <BorderLinearProgress variant="determinate" value={progressValue} />
      </div>
    );
  }
);

export default ProgressBar;

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#c7c7c7",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#4482de",
  },
}));
