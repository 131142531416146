import { BarChartData, LineChartData } from "../../types/charts/charts.type"

export const INCOME_ANALYTICS_DATA = {
  NAME: "incomeTitle",
  VALUE: "incomeAmount"
};

export const EXPENDITURE_ANALYTICS_DATA = {
  NAME: "expenditureTitle",
  VALUE: "expenditureAmount"
};

export const PERSON_HOURS_RATIO_DATA = {
  NAME: "projectName",
  VALUE: "personHoursRatio"
};

export const PROFIT_TRENDS_BAR_CHART_DATA: BarChartData[] = [
  {
    dataKey: "income",
    fillColor: "#79b3e7",
    name: "収入"
  },
  {
    dataKey: "profit",
    fillColor: "#ec955f",
    name: "利益"
  },
  {
    dataKey: "expenditure",
    fillColor: "#76c682",
    name: "支出"
  }
];

export const PROFIT_TRENDS_LINE_CHART_DATA: LineChartData = {
  dataKey: "profitRatio",
  lineColor: "#4864af",
  name: "収益率"
};

export const WORK_HOURS_TRENDS_BAR_CHART_DATA: BarChartData[] = [
  {
    dataKey: "workHours",
    fillColor: "#ec955f",
    name: "労働時間"
  }
];

export const INCOME_TRENDS_LINE_CHART_DATA: LineChartData = {
  dataKey: "income",
  lineColor: "#4864af",
  name: "収入"
}

