import { FC, memo } from "react";
import TemplateContainer from "../../atoms/container/template-container";
import SummaryFrame from "../../molecules/frame/summary/summary-frame";
import {
  FINANCE_SUMMARY_COLOR,
  QUICK_START_CARD_COLORS,
  WHITE_COLOR,
  ACHIEVEMENT_RATE_WHITE_COLORS,
} from "../../../constants/style/color.constant";
import {
  EXPENDITURE_TITLE,
  INCOME_EXPENDITURE_TITLE,
  INCOME_TITLE,
  MONTHLY_INCOME_EXPENDITURE_TITLE,
  PROFIT_TITLE,
  ANNUAL_INCOME_EXPENDITURE_TITLE,
  ANNUAL_INCOME_TITLE,
  ANNUAL_EXPENDITURE_TITLE,
  PROFIT_RATE_TITLE,
  ANNUAL_TARGET_INCOME_TITLE,
  MONTHLY_INCOME_TITLE,
  MONTHLY_EXPENDITURE_TITLE,
  PROFIT_TRENDS_TITLE,
  THIS_MONTH_INCOME_TITLE,
  THIS_MONTH_EXPENDITURE_TITLE,
  TARGET_INCOME_ACHIVEMENT_RATE_TITLE,
} from "../../../constants/title/title.constant";
import InformationFrame from "../../molecules/frame/information/information-frame";
import BasicCard from "../../molecules/cards/basic-card/basic-card";
import { formatYen, formatPercent } from "../../../lib/formatter";
import QuickStartCard from "../../molecules/cards/quick-start-card/quick-start-card";
import ChartFrame from "../../molecules/frame/charts/chart-frame";
import {
  IncomeAnalyticsPieChartData,
  ExpenditureAnalyticsPieChartData,
} from "../../../types/charts/charts.type";
import { ProfitTrendsData } from "../../../types/finance/finance.type";
import DetailFrame from "../../molecules/frame/detail/detail-frame";
import {
  INCOME_ANALYTICS_DATA,
  EXPENDITURE_ANALYTICS_DATA,
} from "../../../constants/charts/charts.constant";
import FinanceAnalytics from "./charts/finance-analytics";
import ProfitTrends from "./charts/profit-trends";
import AnnualIncome from "./charts/annual-income";
import {
  DEFAULT_PAGE_WIDTH,
  DEFAULT_SUMMARY_MARGIN,
} from "../../../constants/style/size.constant";
import { calculatePercent } from "../../../lib/calculator";
import { QUICK_START_ICON } from "../../../constants/element/element.constant";

interface FinanceProps {
  monthlyIncome: number;
  monthlyExpenditure: number;
  totalMonthlyIncome: number;
  totalMonthlyExpenditure: number;
  targetAnnualIncome: number;
  targetAnnualProfitRatio: number;
  targetAnnualProfit: number;
  profitTrendsData: ProfitTrendsData[];
  annualIncomeAnalyticsData: IncomeAnalyticsPieChartData[];
  annualExpenditureAnalyticsData: ExpenditureAnalyticsPieChartData[];
  monthlyIncomeAnalyticsData: IncomeAnalyticsPieChartData[];
  monthlyExpenditureAnalyticsData: ExpenditureAnalyticsPieChartData[];
  onClickAddMonthlyIncome: () => void;
  onClickAddMonthlyExpenditure: () => void;
  onClickAddAnnualIncome: () => void;
  onClickAddAnnualExpenditure: () => void;
}

const Finance: FC<FinanceProps> = memo(
  ({
    monthlyIncome,
    monthlyExpenditure,
    totalMonthlyIncome,
    totalMonthlyExpenditure,
    targetAnnualIncome,
    targetAnnualProfit,
    targetAnnualProfitRatio,
    profitTrendsData,
    annualIncomeAnalyticsData,
    annualExpenditureAnalyticsData,
    monthlyIncomeAnalyticsData,
    monthlyExpenditureAnalyticsData,
    onClickAddMonthlyIncome,
    onClickAddMonthlyExpenditure,
    onClickAddAnnualIncome,
    onClickAddAnnualExpenditure,
  }) => {
    return (
      <TemplateContainer width={DEFAULT_PAGE_WIDTH}>
        <SummaryFrame
          title={INCOME_EXPENDITURE_TITLE}
          backgroundColor={FINANCE_SUMMARY_COLOR}
          gradation={true}
          titleColor={WHITE_COLOR}
        >
          <InformationFrame
            title={MONTHLY_INCOME_EXPENDITURE_TITLE}
            titleColor={WHITE_COLOR}
            margin={DEFAULT_SUMMARY_MARGIN}
          >
            <BasicCard
              title={INCOME_TITLE}
              description={formatYen(monthlyIncome)}
              fontSize="18px"
              color={WHITE_COLOR}
            />
            <BasicCard
              title={EXPENDITURE_TITLE}
              description={formatYen(monthlyExpenditure)}
              fontSize="18px"
              color={WHITE_COLOR}
            />
            <BasicCard
              title={PROFIT_TITLE}
              description={formatYen(monthlyIncome - monthlyExpenditure)}
              fontSize="18px"
              color={WHITE_COLOR}
            />
            <BasicCard
              title={PROFIT_RATE_TITLE}
              description={formatPercent(
                calculatePercent(
                  monthlyIncome - monthlyExpenditure,
                  monthlyIncome
                )
              )}
              fontSize="18px"
              color={WHITE_COLOR}
            />
          </InformationFrame>

          <InformationFrame
            title={ANNUAL_INCOME_EXPENDITURE_TITLE}
            titleColor={WHITE_COLOR}
            margin={DEFAULT_SUMMARY_MARGIN}
          >
            <BasicCard
              title={INCOME_TITLE}
              description={formatYen(totalMonthlyIncome)}
              fontSize="18px"
              color={WHITE_COLOR}
            />
            <BasicCard
              title={EXPENDITURE_TITLE}
              description={formatYen(totalMonthlyExpenditure)}
              fontSize="18px"
              color={WHITE_COLOR}
            />
            <BasicCard
              title={PROFIT_TITLE}
              description={formatYen(
                totalMonthlyIncome - totalMonthlyExpenditure
              )}
              fontSize="18px"
              color={WHITE_COLOR}
            />
            <BasicCard
              title={PROFIT_RATE_TITLE}
              description={formatPercent(
                calculatePercent(
                  totalMonthlyIncome - totalMonthlyExpenditure,
                  totalMonthlyIncome
                )
              )}
              fontSize="18px"
              color={WHITE_COLOR}
            />
          </InformationFrame>

          <InformationFrame
            title={ANNUAL_TARGET_INCOME_TITLE}
            titleColor={WHITE_COLOR}
            margin={DEFAULT_SUMMARY_MARGIN}
          >
            <BasicCard
              title={INCOME_TITLE}
              description={formatYen(targetAnnualIncome)}
              fontSize="18px"
              color={WHITE_COLOR}
            />
            <BasicCard
              title={PROFIT_TITLE}
              description={formatYen(targetAnnualProfit)}
              fontSize="18px"
              color={WHITE_COLOR}
            />
            <BasicCard
              title={PROFIT_RATE_TITLE}
              description={`${targetAnnualProfitRatio}%`}
              fontSize="18px"
              color={WHITE_COLOR}
            />
          </InformationFrame>

          <InformationFrame
            title={TARGET_INCOME_ACHIVEMENT_RATE_TITLE}
            titleColor={WHITE_COLOR}
            margin={DEFAULT_SUMMARY_MARGIN}
          >
            <AnnualIncome
              targetAnnualIncome={targetAnnualIncome}
              totalMonthlyIncome={totalMonthlyIncome}
              width="100%"
              height="100%"
              containerWidth="180px"
              containerHeight="150px"
              innerRadius={"40%"}
              textColor={WHITE_COLOR}
              fillColors={ACHIEVEMENT_RATE_WHITE_COLORS}
            />
          </InformationFrame>
        </SummaryFrame>

        <InformationFrame direction="row" gap="20px">
          <InformationFrame direction="row" gap="20px" flexGrow={1}>
            <QuickStartCard
              text={MONTHLY_INCOME_TITLE}
              boldText={formatYen(monthlyIncome)}
              lineColor={QUICK_START_CARD_COLORS[0]}
              flexGrow={1}
              icon={QUICK_START_ICON.ADD}
              onClickQuickStart={onClickAddMonthlyIncome}
            />

            <QuickStartCard
              text={MONTHLY_EXPENDITURE_TITLE}
              boldText={formatYen(monthlyExpenditure)}
              lineColor={QUICK_START_CARD_COLORS[1]}
              flexGrow={1}
              icon={QUICK_START_ICON.ADD}
              onClickQuickStart={onClickAddMonthlyExpenditure}
            />
          </InformationFrame>

          <InformationFrame direction="row" gap="20px" flexGrow={1}>
            <QuickStartCard
              text={ANNUAL_INCOME_TITLE}
              boldText={formatYen(totalMonthlyIncome)}
              lineColor={QUICK_START_CARD_COLORS[2]}
              flexGrow={1}
              icon={QUICK_START_ICON.ADD}
              onClickQuickStart={onClickAddAnnualIncome}
            />

            <QuickStartCard
              text={ANNUAL_EXPENDITURE_TITLE}
              boldText={formatYen(totalMonthlyExpenditure)}
              lineColor={QUICK_START_CARD_COLORS[3]}
              flexGrow={1}
              icon={QUICK_START_ICON.ADD}
              onClickQuickStart={onClickAddAnnualExpenditure}
            />
          </InformationFrame>
        </InformationFrame>

        <ChartFrame title={PROFIT_TRENDS_TITLE} scrollX={true}>
          <ProfitTrends data={profitTrendsData} height={400} />
        </ChartFrame>

        <InformationFrame direction="row" gap="3%">
          <ChartFrame
            title={THIS_MONTH_INCOME_TITLE}
            width="450px"
            flexGrow={1}
            gap="3%"
          >
            <FinanceAnalytics
              data={monthlyIncomeAnalyticsData}
              width="100%"
              height="100%"
              containerWidth="290px"
              containerHeight="250px"
              nameKey={INCOME_ANALYTICS_DATA.NAME}
              valueKey={INCOME_ANALYTICS_DATA.VALUE}
              flexGrow={1}
            />

            <DetailFrame
              width="290px"
              contentWidth="90%"
              flexGrow={1}
              data={monthlyIncomeAnalyticsData}
              nameKey={INCOME_ANALYTICS_DATA.NAME}
              valueKey={INCOME_ANALYTICS_DATA.VALUE}
              dataType="money"
            />
          </ChartFrame>

          <ChartFrame
            title={THIS_MONTH_EXPENDITURE_TITLE}
            width="450px"
            flexGrow={1}
            gap="3%"
          >
            <FinanceAnalytics
              data={monthlyExpenditureAnalyticsData}
              width="100%"
              height="100%"
              containerWidth="290px"
              containerHeight="250px"
              nameKey={EXPENDITURE_ANALYTICS_DATA.NAME}
              valueKey={EXPENDITURE_ANALYTICS_DATA.VALUE}
              flexGrow={1}
            />
            <DetailFrame
              width="290px"
              contentWidth="90%"
              flexGrow={1}
              data={monthlyExpenditureAnalyticsData}
              nameKey={EXPENDITURE_ANALYTICS_DATA.NAME}
              valueKey={EXPENDITURE_ANALYTICS_DATA.VALUE}
              dataType="money"
            />
          </ChartFrame>
        </InformationFrame>

        <InformationFrame direction="row" gap="3%">
          <ChartFrame
            title={ANNUAL_INCOME_TITLE}
            width="450px"
            flexGrow={1}
            gap="3%"
          >
            <FinanceAnalytics
              data={annualIncomeAnalyticsData}
              width="100%"
              height="100%"
              containerWidth="290px"
              containerHeight="250px"
              nameKey={INCOME_ANALYTICS_DATA.NAME}
              valueKey={INCOME_ANALYTICS_DATA.VALUE}
              flexGrow={1}
            />

            <DetailFrame
              width="290px"
              contentWidth="90%"
              flexGrow={1}
              data={annualIncomeAnalyticsData}
              nameKey={INCOME_ANALYTICS_DATA.NAME}
              valueKey={INCOME_ANALYTICS_DATA.VALUE}
              dataType="money"
            />
          </ChartFrame>

          <ChartFrame
            title={ANNUAL_EXPENDITURE_TITLE}
            width="450px"
            flexGrow={1}
            gap="3%"
          >
            <FinanceAnalytics
              data={annualExpenditureAnalyticsData}
              width="100%"
              height="100%"
              containerWidth="290px"
              containerHeight="250px"
              nameKey={EXPENDITURE_ANALYTICS_DATA.NAME}
              valueKey={EXPENDITURE_ANALYTICS_DATA.VALUE}
              flexGrow={1}
            />

            <DetailFrame
              width="290px"
              contentWidth="90%"
              flexGrow={1}
              data={annualExpenditureAnalyticsData}
              nameKey={EXPENDITURE_ANALYTICS_DATA.NAME}
              valueKey={EXPENDITURE_ANALYTICS_DATA.VALUE}
              dataType="money"
            />
          </ChartFrame>
        </InformationFrame>
      </TemplateContainer>
    );
  }
);

export default Finance;
