/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { css } from "@emotion/react";
import Title from "../../../atoms/characters/title/title";
import { TITLE_SIZE } from "../../../../constants/element/element.constant";
import { DirectionType } from "../../../../types/index.type";

interface InformationFrameProps {
  title?: string;
  children: React.ReactNode;
  width?: string;
  height?: string;
  fontSize?: string;
  titleColor?: string;
  margin?: string;
  direction?: DirectionType;
  gap?: string;
  flexGrow?: number;
  textAlign?: string;
}

const InformationFrame: FC<InformationFrameProps> = memo(
  ({
    title,
    children,
    width = "auto",
    height = "auto",

    fontSize,
    margin = "0 0 0 0",
    direction = "column",
    titleColor,
    gap = 0,
    flexGrow = 0,
    textAlign = "unset",
  }) => {
    const container = css`
      width: ${width};
      height: ${height};
      margin: ${margin};
      flex-grow: ${flexGrow};
      text-align: ${textAlign};
    `;

    const rowStyle = css`
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: ${gap};
    `;

    return (
      <div css={container}>
        {title ? (
          <Title
            text={title}
            titleSize={TITLE_SIZE.MEDIUM}
            fontSize={fontSize}
            color={titleColor}
          />
        ) : null}

        <div css={[wrapper, direction === "row" ? rowStyle : null]}>
          {children}
        </div>
      </div>
    );
  }
);

export default InformationFrame;

const wrapper = css`
  width: 100%;
  height: 100%;
`;
