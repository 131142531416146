/** @jsxImportSource @emotion/react */
import { FC } from "react";
import { Outlet } from "react-router-dom";
import { useState, useCallback } from "react";
import { useUserProfile } from "../../../hooks/use-user-profile";
import { MENU_NAV_STATUS } from "../../../constants/menu/menu.constant";
import { MenuNavStatus } from "../../../types/menu/menu.type";
import AppHeader from "../../../components/organisms/header/app-header";
import DemoAppMenu from "../../../components/organisms/menu/demo-app-menu";
import { BG_COLOR } from "../../../constants/style/color.constant";
import { css } from "@emotion/react";
import { useDarkScreen } from "../../../hooks/use-dark-screen";
import { ScrollRestoration } from "react-router-dom";

const DemoPageLayout: FC = () => {
  // hooks
  const { userProfile } = useUserProfile();
  const { toggleDarkScreen } = useDarkScreen();

  // flg
  const [menuNavDisplayFlg, setMenuNavDisplayFlg] = useState<MenuNavStatus>(
    MENU_NAV_STATUS.DEFAULT
  );

  const [menuBtnDisabledFlg, setMenuBtnDisabledFlg] = useState<boolean>(false);

  // function
  const openMenu = () => {
    setMenuNavDisplayFlg(MENU_NAV_STATUS.OPEN);
    setMenuBtnDisabledFlg(true);
  };

  const closeMenu = () => {
    setMenuNavDisplayFlg(MENU_NAV_STATUS.CLOSE);
    setMenuBtnDisabledFlg(false);
  };

  // event handler
  const onClickMenuButton = useCallback(() => {
    toggleDarkScreen(true);
    openMenu();
  }, [toggleDarkScreen]);

  const onClickCloseMenuNav = useCallback(() => {
    toggleDarkScreen(false);
    closeMenu();
  }, [toggleDarkScreen]);

  const onClickMenuNav = useCallback(() => {
    toggleDarkScreen(false);
    closeMenu();
  }, [toggleDarkScreen]);

  return (
    <>
      <div css={container}>
        <AppHeader
          userName={userProfile.userName}
          onClickMenuButton={onClickMenuButton}
          menuBtnDisabledFlg={menuBtnDisabledFlg}
        />
        <DemoAppMenu
          menuNavStatus={menuNavDisplayFlg}
          onClickClose={onClickCloseMenuNav}
          onClickMenuNav={onClickMenuNav}
        />
        <main css={mainWrapper}>
          <Outlet />
        </main>
      </div>
      <ScrollRestoration getKey={(location) => location.pathname} />
    </>
  );
};

export default DemoPageLayout;

const container = css`
  min-height: 100vh;
  background-color: ${BG_COLOR};
`;
const mainWrapper = css`
  padding: 70px 0px;
`;
