import { FC } from "react";
import Schedule from "../../../../components/template/schedule/schedule";
import {
  DEMO_SCHEDULE_DATA,
  DEMO_CALENDAR_DATA,
} from "../../../../constants/demo/schedule.constant";

const DemoSchedulePage: FC = () => {
  return (
    <Schedule
      numberOfProjectsToStartThisMonth={
        DEMO_SCHEDULE_DATA.PROJECTS_TO_START_THIS_MONTH
      }
      numberOfProjectsToEndThisMonth={
        DEMO_SCHEDULE_DATA.PROJECTS_TO_END_THIS_MONTH
      }
      scheduleData={DEMO_CALENDAR_DATA}
    />
  );
};

export default DemoSchedulePage;
