import { ProfitTrendsData } from "../../types/finance/finance.type";
import { getPreviousMonth } from "../../lib/calendar";
import { DEMO_PROJECTS } from "./common.constant";
import { IncomeAnalyticsPieChartData, ExpenditureAnalyticsPieChartData } from "../../types/charts/charts.type";

const DEMO_MONTHLY_FINANCE_DATA = [
  {
    income: 450000,
    expenditure: 41000
  },
  {
    income: 520000,
    expenditure: 59000
  },
  {
    income: 580000,
    expenditure: 72000
  },
  {
    income: 480000,
    expenditure: 31000
  },
  {
    income: 780000,
    expenditure: 110000
  },
  {
    income: 460000,
    expenditure: 50000
  },
  {
    income: 600000,
    expenditure: 31000
  },
  {
    income: 540000,
    expenditure: 75000
  },
  {
    income: 670000,
    expenditure: 79000
  },
]

export const DEMO_PROFIT_TRENDS_DATA: ProfitTrendsData[] = [
  {
    month: getPreviousMonth(9),
    income: DEMO_MONTHLY_FINANCE_DATA[0].income,
    expenditure: DEMO_MONTHLY_FINANCE_DATA[0].expenditure,
    profit: DEMO_MONTHLY_FINANCE_DATA[0].income - DEMO_MONTHLY_FINANCE_DATA[0].expenditure,
    profitRatio: Math.floor((DEMO_MONTHLY_FINANCE_DATA[0].income - DEMO_MONTHLY_FINANCE_DATA[0].expenditure) / DEMO_MONTHLY_FINANCE_DATA[0].income * 100)
  },
  {
    month: getPreviousMonth(8),
    income: DEMO_MONTHLY_FINANCE_DATA[1].income,
    expenditure: DEMO_MONTHLY_FINANCE_DATA[1].expenditure,
    profit: DEMO_MONTHLY_FINANCE_DATA[1].income - DEMO_MONTHLY_FINANCE_DATA[1].expenditure,
    profitRatio: Math.floor((DEMO_MONTHLY_FINANCE_DATA[1].income - DEMO_MONTHLY_FINANCE_DATA[1].expenditure) / DEMO_MONTHLY_FINANCE_DATA[1].income * 100)
  },
  {
    month: getPreviousMonth(7),
    income: DEMO_MONTHLY_FINANCE_DATA[2].income,
    expenditure: DEMO_MONTHLY_FINANCE_DATA[2].expenditure,
    profit: DEMO_MONTHLY_FINANCE_DATA[2].income - DEMO_MONTHLY_FINANCE_DATA[2].expenditure,
    profitRatio: Math.floor((DEMO_MONTHLY_FINANCE_DATA[2].income - DEMO_MONTHLY_FINANCE_DATA[2].expenditure) / DEMO_MONTHLY_FINANCE_DATA[2].income * 100)
  },
  {
    month: getPreviousMonth(6),
    income: DEMO_MONTHLY_FINANCE_DATA[3].income,
    expenditure: DEMO_MONTHLY_FINANCE_DATA[3].expenditure,
    profit: DEMO_MONTHLY_FINANCE_DATA[3].income - DEMO_MONTHLY_FINANCE_DATA[3].expenditure,
    profitRatio: Math.floor((DEMO_MONTHLY_FINANCE_DATA[3].income - DEMO_MONTHLY_FINANCE_DATA[3].expenditure) / DEMO_MONTHLY_FINANCE_DATA[3].income * 100)
  },
  {
    month: getPreviousMonth(5),
    income: DEMO_MONTHLY_FINANCE_DATA[4].income,
    expenditure: DEMO_MONTHLY_FINANCE_DATA[4].expenditure,
    profit: DEMO_MONTHLY_FINANCE_DATA[4].income - DEMO_MONTHLY_FINANCE_DATA[4].expenditure,
    profitRatio: Math.floor((DEMO_MONTHLY_FINANCE_DATA[4].income - DEMO_MONTHLY_FINANCE_DATA[4].expenditure) / DEMO_MONTHLY_FINANCE_DATA[4].income * 100)
  },
  {
    month: getPreviousMonth(4),
    income: DEMO_MONTHLY_FINANCE_DATA[5].income,
    expenditure: DEMO_MONTHLY_FINANCE_DATA[5].expenditure,
    profit: DEMO_MONTHLY_FINANCE_DATA[5].income - DEMO_MONTHLY_FINANCE_DATA[5].expenditure,
    profitRatio: Math.floor((DEMO_MONTHLY_FINANCE_DATA[5].income - DEMO_MONTHLY_FINANCE_DATA[5].expenditure) / DEMO_MONTHLY_FINANCE_DATA[5].income * 100)
  },
  {
    month: getPreviousMonth(3),
    income: DEMO_MONTHLY_FINANCE_DATA[6].income,
    expenditure: DEMO_MONTHLY_FINANCE_DATA[6].expenditure,
    profit: DEMO_MONTHLY_FINANCE_DATA[6].income - DEMO_MONTHLY_FINANCE_DATA[6].expenditure,
    profitRatio: Math.floor((DEMO_MONTHLY_FINANCE_DATA[6].income - DEMO_MONTHLY_FINANCE_DATA[6].expenditure) / DEMO_MONTHLY_FINANCE_DATA[6].income * 100)
  },
  {
    month: getPreviousMonth(2),
    income: DEMO_MONTHLY_FINANCE_DATA[7].income,
    expenditure: DEMO_MONTHLY_FINANCE_DATA[7].expenditure,
    profit: DEMO_MONTHLY_FINANCE_DATA[7].income - DEMO_MONTHLY_FINANCE_DATA[7].expenditure,
    profitRatio: Math.floor((DEMO_MONTHLY_FINANCE_DATA[7].income - DEMO_MONTHLY_FINANCE_DATA[7].expenditure) / DEMO_MONTHLY_FINANCE_DATA[7].income * 100)
  },
  {
    month: getPreviousMonth(1),
    income: DEMO_MONTHLY_FINANCE_DATA[8].income,
    expenditure: DEMO_MONTHLY_FINANCE_DATA[8].expenditure,
    profit: DEMO_MONTHLY_FINANCE_DATA[8].income - DEMO_MONTHLY_FINANCE_DATA[8].expenditure,
    profitRatio: Math.floor((DEMO_MONTHLY_FINANCE_DATA[8].income - DEMO_MONTHLY_FINANCE_DATA[8].expenditure) / DEMO_MONTHLY_FINANCE_DATA[8].income * 100)
  },
]



const TARGET_ANNUAL_INCOME = 10000000;
const TARGET_ANNUAL_PROFIT = 8900000;

export const DEMO_FINANCE_DATA = {
  // target
  TARGET_ANNUAL_INCOME: TARGET_ANNUAL_INCOME,
  TARGET_ANNUAL_PROFIT: TARGET_ANNUAL_PROFIT,
  TARGET_ANNUAL_PROFIT_RATIO: Math.floor(TARGET_ANNUAL_PROFIT / TARGET_ANNUAL_INCOME * 100),

  // total
  TOTAL_MONTHLY_INCOME: DEMO_PROFIT_TRENDS_DATA.reduce((acc, obj) => acc + obj.income, 0),
  TOTAL_MONTHLY_EXPENDITURE: DEMO_PROFIT_TRENDS_DATA.reduce((acc, obj) => acc + obj.expenditure, 0),

  MONTHLY_INCOME: DEMO_PROFIT_TRENDS_DATA[8].income,
  MONTHLY_EXPENDITURE: DEMO_PROFIT_TRENDS_DATA[8].expenditure
};

export const DEMO_MONTHLY_INCOME_ANALYTICS_DATA: IncomeAnalyticsPieChartData[] = [
  {
    incomeTitle: DEMO_PROJECTS.A,
    incomeAmount: DEMO_PROFIT_TRENDS_DATA[8].income * 0.5,
  },
  {
    incomeTitle: DEMO_PROJECTS.B,
    incomeAmount: DEMO_PROFIT_TRENDS_DATA[8].income * 0.3,
  },
  {
    incomeTitle: DEMO_PROJECTS.C,
    incomeAmount: DEMO_PROFIT_TRENDS_DATA[8].income * 0.2
  }
];

export const DEMO_ANNUAL_INCOME_ANALYTICS_DATA: IncomeAnalyticsPieChartData[] = [
  {
    incomeTitle: DEMO_PROJECTS.A,
    incomeAmount: 2600000,
  },
  {
    incomeTitle: DEMO_PROJECTS.B,
    incomeAmount: 1900000,
  },
  {
    incomeTitle: DEMO_PROJECTS.C,
    incomeAmount: 1620000,
  },
  {
    incomeTitle: DEMO_PROJECTS.D,
    incomeAmount: 1520000,
  },
  {
    incomeTitle: DEMO_PROJECTS.E,
    incomeAmount: 1450000,
  },
  {
    incomeTitle: DEMO_PROJECTS.F,
    incomeAmount: 
      DEMO_FINANCE_DATA.TOTAL_MONTHLY_INCOME - 
        (
          1500000 + 
          900000 + 
          110000 +
          600000 +
          550000
        )
  }
];

const DEMO_EXPENDITURE_DATA = {
  TRANSPORTATION: "移動費",
  BUISINESS_DINNER: "会食費",
  OFFICE_SUPPLIES: "オフィス用具",
}

export const DEMO_ANNUAL_EXPENDITURE_ANALYTICS_DATA: ExpenditureAnalyticsPieChartData[] = [
  {
    expenditureTitle: DEMO_EXPENDITURE_DATA.BUISINESS_DINNER,
    expenditureAmount: DEMO_FINANCE_DATA.TOTAL_MONTHLY_EXPENDITURE * 0.5
  },
  {
    expenditureTitle: DEMO_EXPENDITURE_DATA.TRANSPORTATION,
    expenditureAmount: DEMO_FINANCE_DATA.TOTAL_MONTHLY_EXPENDITURE * 0.35
  },
  {
    expenditureTitle: DEMO_EXPENDITURE_DATA.OFFICE_SUPPLIES,
    expenditureAmount: DEMO_FINANCE_DATA.TOTAL_MONTHLY_EXPENDITURE * 0.15
  }
];

export const DEMO_MONTHLY_EXPENDITURE_ANALYTICS_DATA: ExpenditureAnalyticsPieChartData[] = [
  {
    expenditureTitle: DEMO_EXPENDITURE_DATA.BUISINESS_DINNER,
    expenditureAmount: DEMO_FINANCE_DATA.MONTHLY_EXPENDITURE * 0.5
  },
  {
    expenditureTitle: DEMO_EXPENDITURE_DATA.TRANSPORTATION,
    expenditureAmount: DEMO_FINANCE_DATA.MONTHLY_EXPENDITURE * 0.35
  },
  {
    expenditureTitle: DEMO_EXPENDITURE_DATA.OFFICE_SUPPLIES,
    expenditureAmount: DEMO_FINANCE_DATA.MONTHLY_EXPENDITURE * 0.15
  }
];



