export const INDEX = '/';
export const FINANCE = '/finance';
export const CLIENTS = '/clients';
export const ADD_CLIENTS = `${CLIENTS}/add`;
export const INVOICE = '/invoice';
export const PERSON_HOURS = '/person-hours';
export const PROJECTS = '/projects';
export const SCHEDULE = '/schedule';
export const TASKS = '/tasks';
export const ADD_TASKS = `${TASKS}/add`;
export const SETTINGS = '/settings';
export const LOGIN = '/login';
export const SIGNUP = '/signup';

/**
 * intro
 */
export const INTRO_TOP = "top";
export const INTRO_ABOUT = "about";
export const INTRO_FEATURES = "features";
export const INTRO_PRICES = "prices";
export const INTRO_QA = "QA";

/**
 * demo
 */

export const DEMO_INDEX = '/demo';
export const DEMO_FINANCE = `${DEMO_INDEX}${FINANCE}`;
export const DEMO_CLIENTS = `${DEMO_INDEX}${CLIENTS}`;
export const DEMO_PERSON_HOURS = `${DEMO_INDEX}${PERSON_HOURS}`;
export const DEMO_PROJECTS = `${DEMO_INDEX}${PROJECTS}`;
export const DEMO_SCHEDULE = `${DEMO_INDEX}${SCHEDULE}`;
export const DEMO_TASKS = `${DEMO_INDEX}${TASKS}`;
export const DEMO_INVOICE = `${DEMO_INDEX}${INVOICE}`;
export const DEMO_SETTINGS = `${DEMO_INDEX}${SETTINGS}`;
