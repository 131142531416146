import { FC, memo } from "react";
import TrendsCombinationChart from "../../../organisms/charts/combination-charts/trends-combination-chart";
import { WorkHoursAndIncomeData } from "../../../../types/person-hours/person-hours.type";
import {
  WORK_HOURS_TRENDS_BAR_CHART_DATA,
  INCOME_TRENDS_LINE_CHART_DATA,
} from "../../../../constants/charts/charts.constant";
import {
  DAY_UNIT,
  HOUR_UNIT,
  MONTH_UNIT,
  YEN_UNIT,
} from "../../../../constants/unit/unit.constant";
import { formatWithComma } from "../../../../lib/formatter";
import { convertToTenThousand } from "../../../../lib/converter";

interface WorkHoursAndIncomeTrendsProps {
  data: WorkHoursAndIncomeData[];
  width: string;
  height: number;
}

const WorkHoursAndIncomeTrends: FC<WorkHoursAndIncomeTrendsProps> = memo(
  ({ data, width, height }) => {
    return (
      <TrendsCombinationChart
        data={data}
        xDataKey="month"
        y1DataKey={WORK_HOURS_TRENDS_BAR_CHART_DATA[0].dataKey}
        y2DataKey={INCOME_TRENDS_LINE_CHART_DATA.dataKey}
        barChartData={WORK_HOURS_TRENDS_BAR_CHART_DATA}
        lineChartData={INCOME_TRENDS_LINE_CHART_DATA}
        yAxisLabel1="労働時間"
        yAxisLabel2="金額(万円)"
        width={width}
        height={height}
        unitXAxis={MONTH_UNIT}
        unitXBar={[DAY_UNIT, HOUR_UNIT]}
        unitYLine={YEN_UNIT}
        tickFormatter2={(value) => formatWithComma(convertToTenThousand(value))}
      />
    );
  }
);

export default WorkHoursAndIncomeTrends;
