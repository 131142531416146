/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { css } from "@emotion/react";
import CategoryPieChart from "../../../organisms/charts/pie-charts/category-pie-chart";
import {
  IncomeAnalyticsPieChartData,
  ExpenditureAnalyticsPieChartData,
} from "../../../../types/charts/charts.type";

interface FinanceAnalyticsProps {
  data: IncomeAnalyticsPieChartData[] | ExpenditureAnalyticsPieChartData[];
  containerWidth?: string;
  containerHeight?: string;
  width: number | string;
  height: number | string;
  nameKey: string;
  valueKey: string;
  flexGrow?: number;
}

const FinanceAnalytics: FC<FinanceAnalyticsProps> = memo(
  ({
    data,
    containerWidth = "100%",
    containerHeight = "100%",
    width,
    height,
    nameKey,
    valueKey,
    flexGrow,
  }) => {
    const container = css`
      width: ${containerWidth};
      height: ${containerHeight};
      display: flex;
      justify-content: center;
      flex-grow: ${flexGrow};
    `;

    return (
      <div css={container}>
        <CategoryPieChart
          data={data}
          width={width}
          height={height}
          nameKey={nameKey}
          dataKey={valueKey}
          dataType="money"
        />
      </div>
    );
  }
);

export default FinanceAnalytics;
