/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { css } from "@emotion/react";
import { convertToShortCompanyName } from "../../../../lib/converter";
import ListCard from "../../../molecules/cards/list-card/list-card";
import { BriefClientInformation } from "../../../../types/clients/clients.type";
import EndDate from "../../../molecules/date/end-date";
import { applyColorToList } from "../../../../lib/style-composer";
import { NO_CONTRACTED_CLIENTS_REGISTERD } from "../../../../constants/description/description.constant";
import Label from "../../../molecules/label/label";

interface CompactClientListProps {
  clientData: BriefClientInformation[];
  fontSize?: string;
}

const CompactClientList: FC<CompactClientListProps> = memo(
  ({ clientData, fontSize = "13px" }) => {
    return (
      <div css={container}>
        {clientData && clientData.length !== 0 ? (
          clientData.map((client, index) => (
            <ListCard
              key={index}
              title={convertToShortCompanyName(client.companyName, 7)}
              color={applyColorToList(index)}
            >
              <EndDate
                endDate={new Date(client.contractEndDate)}
                fontSize={fontSize}
              />
            </ListCard>
          ))
        ) : (
          <Label width="100%" text={NO_CONTRACTED_CLIENTS_REGISTERD} />
        )}
      </div>
    );
  }
);

export default CompactClientList;

const container = css`
  width: 100%;
`;
