/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { css } from "@emotion/react";
import IntroductionContainer from "../../../atoms/container/introduction-container";
import {
  INTRODUCTION_FOOTER_BG_COLOR,
  WHITE_COLOR,
} from "../../../../constants/style/color.constant";
import { MEDIA_QUERY } from "../../../../constants/style/media.constant";

const EndSection: FC = memo(() => {
  return (
    <section id="end">
      <IntroductionContainer
        color={INTRODUCTION_FOOTER_BG_COLOR}
        contentWidth="80%"
      >
        <div css={textWrapper}>
          <h2 css={textStyle}>
            自由な働き方を、
            <br />
            <strong css={strongTextStyle}>さらに</strong>
            自由に。
          </h2>
        </div>
      </IntroductionContainer>
    </section>
  );
});

export default EndSection;

const textWrapper = css`
  width: 100%;
  text-align: center;
`;

const textStyle = css`
  font-size: 60px;
  color: ${WHITE_COLOR};

  ${MEDIA_QUERY.SP} {
    font-size: 35px;
  }
`;

const strongTextStyle = css`
  color: #23e5ff;
`;
