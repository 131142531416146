// common
export const DEFAULT_PAGE_WIDTH = "90%";

// Dashboard
export const DASHBOARD_FRAME_MIN_HEIGHT = "250px";
export const DASHBOARD_CALENDER_MIN_HEIGHT = "530px";
export const DASHBOARD_FRAME_HEIGHT = "27vh";
export const DASHBOARD_CALENDER_HEIGHT = "calc(54vh + 30px)";

// SUMMARY
export const DEFAULT_SUMMARY_MARGIN = "15px 0px";

// Quick start
export const DEFAULT_QUICK_START_CARD_WIDTH = "250px";
export const DEFAULT_QUICK_START_CARD_HEIGHT = "100px";

// list
export const DEFAULT_LIST_HEIGHT = "calc(100vh - 100px)";
