/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { css } from "@emotion/react";
import featuresImg from "../../../../../assets/images/features-image.png";
import { MEDIA_QUERY } from "../../../../../constants/style/media.constant";

const FeaturesImage: FC = memo(() => {
  return (
    <div css={imgWrapper}>
      <img src={featuresImg} alt="features img" css={imgStyle} />
    </div>
  );
});

export default FeaturesImage;

const imgWrapper = css`
  width: 400px;
  margin: 15px;

  ${MEDIA_QUERY.SP} {
    width: 270px;
  }
`;

const imgStyle = css`
  width: 100%;
  height: auto;
`;
