/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { MenuNavStatus } from "../../../types/menu/menu.type";
import { MENU_NAV_STATUS } from "../../../constants/menu/menu.constant";
import { css, keyframes } from "@emotion/react";
import MenuNav from "../nav/menu/menu-nav";
import {
  DEMO_INDEX,
  INTRO_ABOUT,
  INTRO_PRICES,
  INTRO_QA,
  INTRO_FEATURES,
  INTRO_TOP,
  LOGIN,
  SIGNUP,
} from "../../../constants/path/path.constant";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import InfoIcon from "@mui/icons-material/Info";
import CurrencyYenIcon from "@mui/icons-material/CurrencyYen";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import { MEDIA_QUERY } from "../../../constants/style/media.constant";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import LoginIcon from "@mui/icons-material/Login";
import CreateIcon from "@mui/icons-material/Create";

interface PortalMenuProps {
  menuNavStatus: MenuNavStatus;
  onClickClose: () => void;
  onClickMenuNav: () => void;
  onClickNoAvailable: () => void;
}

const PortalMenu: FC<PortalMenuProps> = memo(
  ({ menuNavStatus, onClickClose, onClickMenuNav, onClickNoAvailable }) => {
    const checkMenuNavStatus = () => {
      switch (menuNavStatus) {
        case MENU_NAV_STATUS.DEFAULT:
          return hidden;
        case MENU_NAV_STATUS.OPEN:
          return open;
        case MENU_NAV_STATUS.CLOSE:
          return close;
        default:
          return hidden;
      }
    };
    return (
      <div css={[container, checkMenuNavStatus()]}>
        <div css={wrapper}>
          <MenuNav
            icon={<ArrowDropUpIcon color="inherit" />}
            caption="TOP"
            path={INTRO_TOP}
            onClickMenuNav={onClickMenuNav}
            navType="id"
          />
          <MenuNav
            icon={<InfoIcon color="inherit" />}
            caption="Solo Syncとは"
            path={INTRO_ABOUT}
            onClickMenuNav={onClickMenuNav}
            navType="id"
          />
          <MenuNav
            icon={<LightbulbIcon color="inherit" />}
            caption="Solo Syncの特徴"
            path={INTRO_FEATURES}
            onClickMenuNav={onClickMenuNav}
            navType="id"
          />
          <MenuNav
            icon={<CurrencyYenIcon color="inherit" />}
            caption="料金プラン"
            path={INTRO_PRICES}
            onClickMenuNav={onClickMenuNav}
            navType="id"
          />
          <MenuNav
            icon={<QuestionAnswerIcon color="inherit" />}
            caption="QA"
            path={INTRO_QA}
            onClickMenuNav={onClickMenuNav}
            navType="id"
          />
          <MenuNav
            icon={<PlayCircleIcon color="inherit" />}
            caption="デモを体験"
            path={DEMO_INDEX}
            onClickMenuNav={onClickMenuNav}
          />

          <MenuNav
            icon={<LoginIcon color="inherit" />}
            caption="ログイン"
            path={LOGIN}
            onClickMenuNav={onClickNoAvailable}
            navType="none"
          />
          <MenuNav
            icon={<CreateIcon color="inherit" />}
            caption="アカウント作成"
            path={SIGNUP}
            onClickMenuNav={onClickNoAvailable}
            navType="none"
          />

          <div css={closeButtonWrapper}>
            <button css={closeButton} onClick={onClickClose}>
              CLOSE
            </button>
          </div>
        </div>
      </div>
    );
  }
);

export default PortalMenu;

const container = css`
  width: 400px;
  height: 100vh;
  background-image: linear-gradient(
    120deg,
    rgb(116, 172, 250),
    rgb(110, 89, 187)
  );
  z-index: 3000;
  position: fixed;
  top: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  ${MEDIA_QUERY.SP} {
    width: 90%;
  }
`;

const wrapper = css`
  width: 85%;

  ${MEDIA_QUERY.SP} {
    width: 75%;
  }
`;

const closeButtonWrapper = css`
  width: 100%;
  text-align: center;
  margin-top: 50px;
`;

const closeButton = css`
  border: none;
  background-color: transparent;
  color: #ffffff;
  margin: 0 auto;
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  background-color: transparent;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #ffffff;
    transform: scaleX(0);
    transition: transform 0.3s ease;
  }

  &:hover::before {
    transform-origin: left;
    transform: scaleX(1);
  }
`;

const openMenu = keyframes`
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
`;

const closeMenu = keyframes`
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(100%);
  }
`;

const open = css`
  animation: ${openMenu} 1s ease-in-out forwards;
`;

const close = css`
  animation: ${closeMenu} 1s ease-in-out forwards;
`;

const hidden = css`
  display: none !important;
`;
