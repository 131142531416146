/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { css, Interpolation, Theme } from "@emotion/react";
import { WHITE_COLOR } from "../../../../constants/style/color.constant";
import { SoloSyncLogoType } from "../../../../types/index.type";

interface SoloSyncLogoProps {
  fontSize?: string;
  style?: Interpolation<Theme>;
  logoType?: SoloSyncLogoType;
}

const SoloSyncLogo: FC<SoloSyncLogoProps> = memo(
  ({ fontSize = "35px", logoType = "white", style }) => {
    const logoWrapper = css`
      font-size: ${fontSize};
    `;

    const generateLogoStyle = (logoType: SoloSyncLogoType) => {
      switch (logoType) {
        case "white":
          return whiteLogoStyle;
        case "dark":
          return darkLogoStyle;
        default:
          return whiteLogoStyle;
      }
    };

    return (
      <h1 css={[logoWrapper, generateLogoStyle(logoType), style]}>
        Solo <strong css={strongStyle}>S</strong>ync
      </h1>
    );
  }
);

export default SoloSyncLogo;

const whiteLogoStyle = css`
  color: ${WHITE_COLOR};
`;

const darkLogoStyle = css`
  color: #2d4175;
`;

const strongStyle = css`
  color: #fe644d;
`;
