import { FC, useCallback } from "react";
import Projects from "../../../components/template/projects/projects";
import { DEMO_PROJECTS_DATA } from "../../../constants/demo/projects.constant";

const ProjectsPage: FC = () => {
  const onClickAddProjects = useCallback(() => {}, []);

  const onClickProjectsManagement = useCallback(() => {}, []);
  return (
    <Projects
      projectData={DEMO_PROJECTS_DATA}
      projectsToEndThisMonth={0}
      onClickAddProjects={onClickAddProjects}
      onClickProjectsManagement={onClickProjectsManagement}
    />
  );
};

export default ProjectsPage;
