/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { css } from "@emotion/react";
import ThemeFrame from "../../../atoms/frame/theme-frame";
import Title from "../../../atoms/characters/title/title";
import { TITLE_SIZE } from "../../../../constants/element/element.constant";
import { DEFAULT_LIST_HEIGHT } from "../../../../constants/style/size.constant";

interface ListFrameProps {
  width?: string;
  height?: string;
  margin?: string;
  maxWidth?: string;
  maxHeight?: string;
  flexGrow?: number;
  title?: string;
  children: React.ReactNode;
}

const ListFrame: FC<ListFrameProps> = memo(
  ({
    width,
    height,
    maxWidth,
    maxHeight = DEFAULT_LIST_HEIGHT,
    margin,
    flexGrow,
    title,
    children,
  }) => {
    return (
      <ThemeFrame
        width={width}
        height={height}
        margin={margin}
        flexGrow={flexGrow}
        maxWidth={maxWidth}
        maxHeight={maxHeight}
      >
        {title ? (
          <div css={titleWrapper}>
            <Title text={title} titleSize={TITLE_SIZE.SMALL} fontSize="20px" />
          </div>
        ) : null}
        <div css={contentWrapper}>{children}</div>
      </ThemeFrame>
    );
  }
);

export default ListFrame;

const contentWrapper = css`
  width: 100%;
  height: 100%;
  position: relative;
  overflow-x: scroll;
`;

const titleWrapper = css`
  width: 90%;
  margin: auto;
`;
