import { DEFAULT_LIST_COLORS } from "../constants/style/color.constant";

/**
 * リストに色をつける関数
 * カラーリストを指定することも可能
 * @param index 
 * @returns string
 */
export const applyColorToList = (index: number, colors?: string[]): string => {
  if (colors) {
    return index % 2 === 0 ? colors[0] : colors[1]
  } else {
    return index % 2 === 0 ? DEFAULT_LIST_COLORS[0] : DEFAULT_LIST_COLORS[1];
  }
};

/**
 * 単位がピクセルかどうか判定
 * @param size 
 * @returns boolean
 */
export const checkUnitIsPixel = (size: string): boolean => {
  return size.includes("px");
};  

/**
 * スタイルから単位を除く
 * @param style 
 * @returns number
 */
export const removeUnitFromStyle = (style: string): number => {
  const removeUnit = style.replace(/%|vw|vh|px/g, '');
  return Number(removeUnit);
};

/**
 * PCサイズからSPサイズに変更
 * @param style 
 * @param ratio 
 * @returns number
 */
export const resizePCtoSP = (style: number, ratio?: number): number => {
  const ratioNum = ratio ?? 0.8;
  return  Math.floor(style * ratioNum);
};

/**
 * PCサイズからタブレットサイズに変更
 * @param style 
 * @param ratio 
 * @returns number
 */
export const resizePCtoTablet = (style: number, ratio?: number) => {    
  const ratioNum = ratio ?? 0.9;
  return  Math.floor(style * ratioNum);
};

