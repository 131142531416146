import { FC, memo } from "react";
import BasicCard from "../../molecules/cards/basic-card/basic-card";
import { formatWithComma } from "../../../lib/formatter";
import ThemeFrame from "../../atoms/frame/theme-frame";
import {
  DEFAULT_CHART_TOOLTIP_COLOR,
  WHITE_COLOR,
} from "../../../constants/style/color.constant";

interface BarChartTooltipProps {
  active?: boolean;
  payload?: any[];
  label?: string | number | Date;
  color?: string;
}

const BarChartTooltip: FC<BarChartTooltipProps> = memo(
  ({ active, payload, label, color = DEFAULT_CHART_TOOLTIP_COLOR }) => {
    if (active && payload && payload.length) {
      const data = payload[0];
      return (
        <ThemeFrame color={WHITE_COLOR} borderRadius="10px">
          <BasicCard
            titleBold={false}
            title={String(label)}
            description={`${formatWithComma(Number(data.value))}${data.unit}`}
            color={color}
          />
        </ThemeFrame>
      );
    } else {
      return null;
    }
  }
);

export default BarChartTooltip;
