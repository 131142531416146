import { FC, useState, useCallback, useEffect } from "react";
import Introduction from "../../../components/template/introduction/introduction";
import { QAData } from "../../../types/index.type";
import { MENU_NAV_STATUS } from "../../../constants/menu/menu.constant";
import { useDarkScreen } from "../../../hooks/use-dark-screen";
import { MenuNavStatus } from "../../../types/menu/menu.type";
import { scroller } from "react-scroll";
import { useLocation, useNavigate } from "react-router-dom";
import { removeHash } from "../../../lib/formatter";
import { useDialog } from "../../../hooks/use-dialog";
import { WAIT_FOR_OFFICIAL_RELEASE } from "../../../constants/message/dialog-message";
import { DEMO_INDEX } from "../../../constants/path/path.constant";

const IntroductionPage: FC = () => {
  const hashname = useLocation().hash;
  const navigate = useNavigate();

  const { openDialog } = useDialog();

  // 初期処理
  useEffect(() => {
    if (!hashname) return;
    scroller.scrollTo(`${removeHash(hashname)}`, {
      smooth: true,
    });
  }, []);

  const { toggleDarkScreen } = useDarkScreen();

  const onClickLogin = useCallback(() => {
    openDialog(WAIT_FOR_OFFICIAL_RELEASE, "caution");
  }, [openDialog]);

  const onClickSignup = useCallback(() => {
    openDialog(WAIT_FOR_OFFICIAL_RELEASE, "caution");
  }, [openDialog]);

  const onClickPlayDemo = useCallback(() => {
    navigate(DEMO_INDEX);
  }, [navigate]);

  const freeFeatures = [
    "ダッシュボード",
    "収支管理",
    "工数管理",
    "クライアント管理",
    "プロジェクト管理",
    "スケジュール管理",
    "タスク管理",
    "請求書作成",
  ];

  const qaData: QAData[] = [
    {
      question: "このアプリはどのような業種や職種に適していますか？",
      answer:
        "当アプリは、フリーランスや独立業者など、様々な職種に適しています。",
    },
    {
      question: "このアプリの主な機能は何ですか？",
      answer:
        "主な機能には、請求書作成、収支管理、プロジェクト管理、タスク管理、クライアント管理がございます。また、今後も機能を追加していく予定です。",
    },
    {
      question: "いつ頃、正式リリースする予定でしょうか？",
      answer:
        "具体的な日程はまだ決まっておりません。開発者のやる気と、このアプリの需要次第で大きく前後します。",
    },
  ];

  // flg
  const [menuNavDisplayFlg, setMenuNavDisplayFlg] = useState<MenuNavStatus>(
    MENU_NAV_STATUS.DEFAULT
  );

  const [menuBtnDisabledFlg, setMenuBtnDisabledFlg] = useState<boolean>(false);

  // function
  const openMenu = () => {
    setMenuNavDisplayFlg(MENU_NAV_STATUS.OPEN);
    setMenuBtnDisabledFlg(true);
  };

  const closeMenu = () => {
    setMenuNavDisplayFlg(MENU_NAV_STATUS.CLOSE);
    setMenuBtnDisabledFlg(false);
  };

  // event handler
  const onClickMenuButton = useCallback(() => {
    toggleDarkScreen(true);
    openMenu();
  }, [toggleDarkScreen]);

  const onClickCloseMenuNav = useCallback(() => {
    toggleDarkScreen(false);
    closeMenu();
  }, [toggleDarkScreen]);

  const onClickMenuNav = useCallback(() => {
    toggleDarkScreen(false);
    closeMenu();
  }, [toggleDarkScreen]);

  const onClickNoAvailable = useCallback(() => {
    closeMenu();
    openDialog(WAIT_FOR_OFFICIAL_RELEASE, "caution");
  }, [openDialog]);

  return (
    <Introduction
      menuNavStatus={menuNavDisplayFlg}
      menuButtonDisabledFlg={menuBtnDisabledFlg}
      standardPrice={1200}
      proPrice={2500}
      freeFeaturesList={freeFeatures}
      standardFeaturesList={undefined}
      proFeaturesList={undefined}
      qaData={qaData}
      onClickLogin={onClickLogin}
      onClickSignup={onClickSignup}
      onClickPlayDemo={onClickPlayDemo}
      onClickMenuButton={onClickMenuButton}
      onClickMenuClose={onClickCloseMenuNav}
      onClickMenuNav={onClickMenuNav}
      onClickNoAvailable={onClickNoAvailable}
    />
  );
};

export default IntroductionPage;
