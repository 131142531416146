/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { css, Interpolation, Theme } from "@emotion/react";
import { TITLE_SIZE } from "../../../../constants/element/element.constant";
import { DEFAULT_TITLE_COLOR } from "../../../../constants/style/color.constant";
import { TitleSize } from "../../../../types/elements/elements.type";

interface TitleProps {
  text: string;
  titleSize: TitleSize;
  fontSize?: string;
  margin?: string;
  color?: string;
  style?: Interpolation<Theme>;
}

const Title: FC<TitleProps> = memo(
  ({
    text,
    titleSize,
    fontSize,
    margin = "0 0 10px 0",
    color = DEFAULT_TITLE_COLOR,
    style,
  }) => {
    const setDefaultTitleFontSize = (titleSize: number) => {
      switch (titleSize) {
        case TITLE_SIZE.LARGE:
          return "30px";
        case TITLE_SIZE.MEDIUM:
          return "21px";
        case TITLE_SIZE.SMALL:
          return "17px";
        default:
          return "20px";
      }
    };

    const titleFontSize = fontSize
      ? fontSize
      : setDefaultTitleFontSize(titleSize);

    const titleStyle = css`
      font-size: ${titleFontSize};
      color: ${color};
      margin: ${margin};
    `;

    switch (titleSize) {
      case TITLE_SIZE.LARGE:
        return <h1 css={[titleStyle, style]}>{text}</h1>;
      case TITLE_SIZE.MEDIUM:
        return <h2 css={[titleStyle, style]}>{text}</h2>;
      case TITLE_SIZE.SMALL:
        return <h3 css={[titleStyle, style]}>{text}</h3>;
      default:
        return <h2 css={[titleStyle, style]}>{text}</h2>;
    }
  }
);

export default Title;
