import { DEMO_COMPANIES, DEMO_PROJECTS } from "./common.constant"
import { ProjectInformation } from "../../types/projects/projects.type"
import { convertDateToString } from "../../lib/converter"
import { getPreviousDate, getFutureDate } from "../../lib/calendar"
import { PROJECT_STATUS, PROJECT_PROGRESS_STATUS } from "../status/status.constant"

export const DEMO_PROJECTS_DATA: ProjectInformation[] = [
  {
    projectName: DEMO_PROJECTS.A,
    companyName: DEMO_COMPANIES.A,
    projectStartDate: convertDateToString(getPreviousDate(40)),
    projectEndDate: convertDateToString(getFutureDate(200)),
    projectStatus: PROJECT_STATUS.PROGRESS,
    projectProgressStatus: PROJECT_PROGRESS_STATUS.BEHIND,
    amountOfProjectProgress: 9
  },
  {
    projectName: DEMO_PROJECTS.B,
    companyName: DEMO_COMPANIES.B,
    projectStartDate: convertDateToString(getPreviousDate(23)),
    projectEndDate:  convertDateToString(getFutureDate(250)),
    projectStatus: PROJECT_STATUS.PROGRESS,
    projectProgressStatus: PROJECT_PROGRESS_STATUS.AHEAD,
    amountOfProjectProgress: 33
  },
  {
    projectName: DEMO_PROJECTS.C,
    companyName: DEMO_COMPANIES.C,
    projectStartDate: convertDateToString(getPreviousDate(12)),
    projectEndDate:  convertDateToString(getFutureDate(290)),
    projectStatus: PROJECT_STATUS.PROGRESS,
    projectProgressStatus: PROJECT_PROGRESS_STATUS.ACCORDING_TO_SCHEDULE,
    amountOfProjectProgress: 5
  },
  {
    projectName: DEMO_PROJECTS.D,
    companyName: DEMO_COMPANIES.D,
    projectStartDate: convertDateToString(getPreviousDate(12)),
    projectEndDate:  convertDateToString(getFutureDate(290)),
    projectStatus: PROJECT_STATUS.END,
    projectProgressStatus: PROJECT_PROGRESS_STATUS.ACCORDING_TO_SCHEDULE,
    amountOfProjectProgress: 100
  },
  {
    projectName: DEMO_PROJECTS.E,
    companyName: DEMO_COMPANIES.E,
    projectStartDate: convertDateToString(getPreviousDate(12)),
    projectEndDate:  convertDateToString(getFutureDate(290)),
    projectStatus: PROJECT_STATUS.END,
    projectProgressStatus: PROJECT_PROGRESS_STATUS.ACCORDING_TO_SCHEDULE,
    amountOfProjectProgress: 100
  },
  {
    projectName: DEMO_PROJECTS.F,
    companyName: DEMO_COMPANIES.F,
    projectStartDate: convertDateToString(getPreviousDate(12)),
    projectEndDate:  convertDateToString(getFutureDate(290)),
    projectStatus: PROJECT_STATUS.END,
    projectProgressStatus: PROJECT_PROGRESS_STATUS.ACCORDING_TO_SCHEDULE,
    amountOfProjectProgress: 100
  },
  {
    projectName: DEMO_PROJECTS.G,
    companyName: DEMO_COMPANIES.G,
    projectStartDate: convertDateToString(getPreviousDate(12)),
    projectEndDate:  convertDateToString(getFutureDate(290)),
    projectStatus: PROJECT_STATUS.END,
    projectProgressStatus: PROJECT_PROGRESS_STATUS.ACCORDING_TO_SCHEDULE,
    amountOfProjectProgress: 100
  }
];