/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { css } from "@emotion/react";
import ListFrame from "../../../molecules/frame/list/list-frame";
import ListCard from "../../../molecules/cards/list-card/list-card";
import { TaskData } from "../../../../types/tasks/tasks.type";
import { applyColorToList } from "../../../../lib/style-composer";
import StatusIcon from "../../../atoms/icon/status/status-icon";
import {
  PRIORITY_LABEL,
  TASK_STATUS_LABEL,
} from "../../../../constants/label/label.constant";
import EndDate from "../../../molecules/date/end-date";
import { convertToShortName } from "../../../../lib/converter";
import Label from "../../../molecules/label/label";
import { NO_TASKS_REGISTERD } from "../../../../constants/description/description.constant";
import { PRIORITY_COLORS } from "../../../../constants/style/color.constant";
import { MEDIA_QUERY } from "../../../../constants/style/media.constant";
import BasicButton from "../../../atoms/buttons/basic-button";

interface AllTaskListProps {
  taskData: TaskData[];
  width?: string;
  height?: string;
  maxWidth?: string;
  maxHeight?: string;
  fontSize?: string;
  title: string;
  onClickDeleteTask: (taskId: any) => void;
}

const AllTaskList: FC<AllTaskListProps> = memo(
  ({
    taskData,
    title,
    width,
    height,
    maxWidth,
    maxHeight,
    fontSize = "15px",
    onClickDeleteTask,
  }) => {
    return (
      <ListFrame
        title={title}
        width={width}
        height={height}
        maxWidth={maxWidth}
        maxHeight={maxHeight}
      >
        {taskData && taskData.length !== 0 ? (
          taskData.map((task, index) => (
            <ListCard
              key={index}
              title={convertToShortName(task.taskTitle, 20)}
              color={applyColorToList(index)}
              titleWidth="60%"
              fontSize={fontSize}
            >
              <StatusIcon
                status={task.status}
                statusLabelList={TASK_STATUS_LABEL}
                style={statusStyle}
              />

              <StatusIcon
                status={task.priority}
                statusLabelList={PRIORITY_LABEL}
                statusColorList={PRIORITY_COLORS}
                width="45px"
                style={primaryStyle}
              />

              <EndDate
                width="30%"
                endDate={new Date(task.dueDate)}
                fontSize={fontSize}
                style={dateStyle}
              />

              <BasicButton
                style={deleteButtonStyle}
                caption="削除"
                onClickButton={() => onClickDeleteTask(task.id)}
              />

              {/* TODO: 削除ボタン作るか検討
            タスクが完了済みステータス以外のものはconfirmダイアログ出す */}
            </ListCard>
          ))
        ) : (
          <Label width="100%" text={NO_TASKS_REGISTERD} />
        )}
      </ListFrame>
    );
  }
);

export default AllTaskList;

const dateStyle = css`
  ${MEDIA_QUERY.TABLET} {
    display: none;
  }
`;

const deleteButtonStyle = css`
  ${MEDIA_QUERY.SP} {
    display: none;
  }
`;

const statusStyle = css`
  ${MEDIA_QUERY.TABLET} {
    width: 75px;
  }

  ${MEDIA_QUERY.SP} {
    width: 60px;
  }
`;

const primaryStyle = css`
  ${MEDIA_QUERY.SP} {
    width: 40px;
  }
`;
