/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { MenuNavStatus } from "../../../types/menu/menu.type";
import { MENU_NAV_STATUS } from "../../../constants/menu/menu.constant";
import { css, keyframes } from "@emotion/react";
import MenuNav from "../nav/menu/menu-nav";
import {
  INDEX,
  FINANCE,
  CLIENTS,
  INVOICE,
  PERSON_HOURS,
  PROJECTS,
  SCHEDULE,
  TASKS,
  SETTINGS,
} from "../../../constants/path/path.constant";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CurrencyYenIcon from "@mui/icons-material/CurrencyYen";
import WorkIcon from "@mui/icons-material/Work";
import PeopleIcon from "@mui/icons-material/People";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DescriptionIcon from "@mui/icons-material/Description";
import SettingsIcon from "@mui/icons-material/Settings";
import { MEDIA_QUERY } from "../../../constants/style/media.constant";
import TaskIcon from "@mui/icons-material/Task";

interface AppMenuProps {
  menuNavStatus: MenuNavStatus;
  onClickClose: () => void;
  onClickMenuNav: () => void;
}

const AppMenu: FC<AppMenuProps> = memo(
  ({ menuNavStatus, onClickClose, onClickMenuNav }) => {
    const checkMenuNavStatus = () => {
      switch (menuNavStatus) {
        case MENU_NAV_STATUS.DEFAULT:
          return hidden;
        case MENU_NAV_STATUS.OPEN:
          return open;
        case MENU_NAV_STATUS.CLOSE:
          return close;
        default:
          return hidden;
      }
    };
    return (
      <div css={[container, checkMenuNavStatus()]}>
        <div css={wrapper}>
          <MenuNav
            icon={<DashboardIcon />}
            caption="ダッシュボード"
            path={INDEX}
            onClickMenuNav={onClickMenuNav}
          />
          <MenuNav
            icon={<CurrencyYenIcon />}
            caption="収支"
            path={FINANCE}
            onClickMenuNav={onClickMenuNav}
          />
          <MenuNav
            icon={<WorkIcon />}
            caption="工数"
            path={PERSON_HOURS}
            onClickMenuNav={onClickMenuNav}
          />
          <MenuNav
            icon={<PeopleIcon />}
            caption="クライアント"
            path={CLIENTS}
            onClickMenuNav={onClickMenuNav}
          />
          <MenuNav
            icon={<WorkspacesIcon />}
            caption="プロジェクト"
            path={PROJECTS}
            onClickMenuNav={onClickMenuNav}
          />
          <MenuNav
            icon={<TaskIcon />}
            caption="タスク"
            path={TASKS}
            onClickMenuNav={onClickMenuNav}
          />
          <MenuNav
            icon={<CalendarMonthIcon />}
            caption="スケジュール"
            path={SCHEDULE}
            onClickMenuNav={onClickMenuNav}
          />
          <MenuNav
            icon={<DescriptionIcon />}
            caption="請求書"
            path={INVOICE}
            onClickMenuNav={onClickMenuNav}
          />
          <MenuNav
            icon={<SettingsIcon />}
            caption="設定"
            path={SETTINGS}
            onClickMenuNav={onClickMenuNav}
          />
          <div css={closeButtonWrapper}>
            <button css={closeButton} onClick={onClickClose}>
              CLOSE
            </button>
          </div>
        </div>
      </div>
    );
  }
);

export default AppMenu;

const container = css`
  width: 400px;
  height: 100vh;
  background-image: linear-gradient(
    120deg,
    rgb(116, 172, 250),
    rgb(110, 89, 187)
  );
  z-index: 3000;
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  ${MEDIA_QUERY.SP} {
    width: 90%;
  }
`;

const wrapper = css`
  width: 85%;

  ${MEDIA_QUERY.SP} {
    width: 75%;
  }
`;

const closeButtonWrapper = css`
  width: 100%;
  text-align: center;
  margin-top: 50px;
`;

const closeButton = css`
  border: none;
  background-color: transparent;
  color: #ffffff;
  margin: 0 auto;
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  background-color: transparent;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #ffffff;
    transform: scaleX(0);
    transition: transform 0.3s ease;
  }

  &:hover::before {
    transform-origin: left;
    transform: scaleX(1);
  }
`;

const openMenu = keyframes`
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
`;

const closeMenu = keyframes`
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
`;

const open = css`
  animation: ${openMenu} 1s ease-in-out forwards;

  @keyframes identifier {
  }
`;

const close = css`
  animation: ${closeMenu} 1s ease-in-out forwards;
`;

const hidden = css`
  display: none !important;
`;
