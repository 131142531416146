/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { css } from "@emotion/react";
import {
  DEFAULT_FRAME_SHADOW,
  DEFAULT_TEXT_COLOR,
  WHITE_COLOR,
} from "../../../../constants/style/color.constant";
import Text from "../../../atoms/characters/text/text";
import HelpIcon from "@mui/icons-material/Help";
import HdrAutoIcon from "@mui/icons-material/HdrAuto";

interface QACardProps {
  question: string;
  answer: string;
  backgroundColor?: string;
  color?: string;
}

const QACards: FC<QACardProps> = memo(
  ({
    question,
    answer,
    backgroundColor = WHITE_COLOR,
    color = DEFAULT_TEXT_COLOR,
  }) => {
    const textWrapper = css`
      background-color: ${backgroundColor};
      box-shadow: ${DEFAULT_FRAME_SHADOW};
      display: flex;
      gap: 15px;
      align-items: center;
      padding: 15px;
      margin: 15px 0px;
      border-radius: 10px;
    `;

    return (
      <div css={container}>
        <div css={textWrapper}>
          <HelpIcon color="info" />
          <Text color={color} text={question} />
        </div>

        <div css={textWrapper}>
          <HdrAutoIcon color="warning" />
          <Text color={color} text={answer} />
        </div>
      </div>
    );
  }
);

export default QACards;

const container = css`
  margin: 30px 0px;
`;
