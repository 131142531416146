import { FC, useCallback } from "react";
import Invoice from "../../../../components/template/invoice/invoice";
import {
  DEMO_INVOICE_MANAGEMENT_DATA,
  DEMO_INVOICE_DATA,
} from "../../../../constants/demo/invoice.constant";
import { useDialog } from "../../../../hooks/use-dialog";
import { NOT_AVAILABLE_IN_THE_DEMO } from "../../../../constants/message/dialog-message";

const DemoInvoicePage: FC = () => {
  const { openDialog } = useDialog();

  const onClickNoAvailable = useCallback(() => {
    openDialog(NOT_AVAILABLE_IN_THE_DEMO, "caution");
  }, [openDialog]);

  return (
    <Invoice
      numberOfIssuedInvoiceLastMonth={
        DEMO_INVOICE_DATA.ISSUED_INVOICE_LAST_MONTH
      }
      totalBillingAmountLastMonth={
        DEMO_INVOICE_DATA.TOTAL_BILLING_AMOUNT_LAST_MONTH
      }
      invoiceManagementData={DEMO_INVOICE_MANAGEMENT_DATA}
      onClickCreateInvoice={onClickNoAvailable}
      onClickCreatedInvoice={onClickNoAvailable}
      onClickInvoiceSetting={onClickNoAvailable}
    />
  );
};

export default DemoInvoicePage;
