/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { css } from "@emotion/react";
import ListCard from "../../../molecules/cards/list-card/list-card";
import { BriefTaskData } from "../../../../types/tasks/tasks.type";
import { TASK_STATUS_LABEL } from "../../../../constants/label/label.constant";
import { convertToShortName } from "../../../../lib/converter";
import StatusIcon from "../../../atoms/icon/status/status-icon";
import EndDate from "../../../molecules/date/end-date";
import { applyColorToList } from "../../../../lib/style-composer";
import Label from "../../../molecules/label/label";
import { NO_PENDING_TASKS } from "../../../../constants/description/description.constant";
import { MEDIA_QUERY } from "../../../../constants/style/media.constant";

interface CompactTaskListProps {
  taskData: BriefTaskData[];
  fontSize?: string;
}

const CompactTaskList: FC<CompactTaskListProps> = memo(
  ({ taskData, fontSize = "13px" }) => {
    return (
      <div css={container}>
        {taskData && taskData.length !== 0 ? (
          taskData.map((data, index) => (
            <ListCard
              key={index}
              title={convertToShortName(data.taskTitle, 20)}
              color={applyColorToList(index)}
            >
              <StatusIcon
                status={data.status}
                statusLabelList={TASK_STATUS_LABEL}
              />

              <EndDate
                endDate={new Date(data.dueDate)}
                width="50%"
                fontSize={fontSize}
                style={dateStyle}
              />
            </ListCard>
          ))
        ) : (
          <Label width="100%" text={NO_PENDING_TASKS} />
        )}
      </div>
    );
  }
);

export default CompactTaskList;

const container = css`
  width: 100%;
`;

const dateStyle = css`
  ${MEDIA_QUERY.SP} {
    display: none;
  }
`;
