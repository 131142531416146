import { FC, memo } from "react";
import QACards from "../../../molecules/cards/qa-card/qa-cards";
import { QAData } from "../../../../types/index.type";
import IntroductionContainer from "../../../atoms/container/introduction-container";
import {
  INTRODUCTION_TEXT_COLOR,
  WHITE_COLOR,
} from "../../../../constants/style/color.constant";
import Title from "../../../atoms/characters/title/title";
import { TITLE_SIZE } from "../../../../constants/element/element.constant";

interface QASectionProps {
  qaData: QAData[];
}

const QASection: FC<QASectionProps> = memo(({ qaData }) => {
  return (
    <section id="QA">
      <IntroductionContainer color={WHITE_COLOR} contentWidth="80%">
        <Title text="QA" fontSize="50px" titleSize={TITLE_SIZE.LARGE} />
        {qaData.map((qa, index) => (
          <QACards
            key={index}
            question={qa.question}
            answer={qa.answer}
            backgroundColor={"#fbfbfb"}
            color={INTRODUCTION_TEXT_COLOR}
          />
        ))}
      </IntroductionContainer>
    </section>
  );
});

export default QASection;
