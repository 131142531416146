/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { css } from "@emotion/react";
import {
  convertToShortName,
  convertToShortCompanyName,
} from "../../../../lib/converter";
import ListCard from "../../../molecules/cards/list-card/list-card";
import { ProjectInformation } from "../../../../types/projects/projects.type";
import ListFrame from "../../../molecules/frame/list/list-frame";
import { applyColorToList } from "../../../../lib/style-composer";
import RelatedItem from "../../../atoms/icon/related-item/related-item";
import StatusIcon from "../../../atoms/icon/status/status-icon";
import EndDate from "../../../molecules/date/end-date";
import { PROJECT_STATUS_LABEL } from "../../../../constants/label/label.constant";
import { PROJECT_NOT_REGISTERD } from "../../../../constants/description/description.constant";
import Label from "../../../molecules/label/label";
import { MEDIA_QUERY } from "../../../../constants/style/media.constant";

interface AllProjectListProps {
  projectData: ProjectInformation[];
  fontSize?: string;
  width?: string;
  height?: string;
  maxWidth?: string;
  maxHeight?: string;
  title: string;
}

const AllProjectList: FC<AllProjectListProps> = memo(
  ({
    projectData,
    fontSize = "15px",
    width,
    height,
    maxWidth,
    maxHeight,
    title,
  }) => {
    return (
      <ListFrame
        title={title}
        width={width}
        height={height}
        maxWidth={maxWidth}
        maxHeight={maxHeight}
      >
        {projectData && projectData.length !== 0 ? (
          projectData.map((project, index) => (
            <ListCard
              key={index}
              title={convertToShortName(project.projectName, 15)}
              color={applyColorToList(index)}
              fontSize={fontSize}
            >
              <StatusIcon
                status={project.projectStatus}
                statusLabelList={PROJECT_STATUS_LABEL}
                width="25%"
                style={statusStyle}
              />
              <RelatedItem
                width="25%"
                caption={convertToShortCompanyName(project.companyName, 6)}
                style={companyStyle}
              />
              <EndDate
                endDate={new Date(project.projectEndDate)}
                width="25%"
                style={endDateStyle}
              />
            </ListCard>
          ))
        ) : (
          <Label width="100%" text={PROJECT_NOT_REGISTERD} />
        )}
      </ListFrame>
    );
  }
);

export default AllProjectList;

const statusStyle = css`
  ${MEDIA_QUERY.TABLET} {
    width: 45%;
  }

  ${MEDIA_QUERY.SP} {
    width: 100%;
  }
`;

const companyStyle = css`
  ${MEDIA_QUERY.TABLET} {
    width: 45%;
  }

  ${MEDIA_QUERY.SP} {
    display: none;
  }
`;

const endDateStyle = css`
  ${MEDIA_QUERY.TABLET} {
    display: none;
  }
`;
