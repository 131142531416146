/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { css } from "@emotion/react";
import ListFrame from "../../../molecules/frame/list/list-frame";
import ListCard from "../../../molecules/cards/list-card/list-card";
import { ClientInformation } from "../../../../types/clients/clients.type";
import StatusIcon from "../../../atoms/icon/status/status-icon";
import { CONTRACT_STATUS_LABEL } from "../../../../constants/label/label.constant";
import StartToEndDate from "../../../molecules/date/start-to-end-date";
import { applyColorToList } from "../../../../lib/style-composer";
import { NO_CLIENTS_REGISTERD } from "../../../../constants/description/description.constant";
import Label from "../../../molecules/label/label";
import { MEDIA_QUERY } from "../../../../constants/style/media.constant";

interface ClientListProps {
  fontSize?: string;
  width?: string;
  height?: string;
  maxWidth?: string;
  maxHeight?: string;
  title: string;
  clientData: ClientInformation[];
}

const ClientList: FC<ClientListProps> = memo(
  ({
    fontSize = "15px",
    width,
    height,
    maxWidth,
    maxHeight,
    title,
    clientData,
  }) => {
    return (
      <ListFrame
        title={title}
        width={width}
        height={height}
        maxWidth={maxWidth}
        maxHeight={maxHeight}
      >
        {clientData && clientData.length !== 0 ? (
          clientData.map((client, index) => (
            <ListCard
              key={index}
              title={client.companyName}
              color={applyColorToList(index)}
            >
              <StatusIcon
                statusLabelList={CONTRACT_STATUS_LABEL}
                status={client.contractStatus}
              />
              <StartToEndDate
                startDate={new Date(client.contractStartDate)}
                endDate={new Date(client.contractEndDate)}
                fontSize={fontSize}
                style={clientListStyle}
              />
            </ListCard>
          ))
        ) : (
          <Label width="100%" text={NO_CLIENTS_REGISTERD} />
        )}
      </ListFrame>
    );
  }
);

export default ClientList;

const clientListStyle = css`
  ${MEDIA_QUERY.SP} {
    display: none;
  }
`;
