/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { css, Interpolation, Theme } from "@emotion/react";
import { DEFAULT_TEXT_COLOR } from "../../../../constants/style/color.constant";

interface TextProps {
  text: string;
  fontSize?: string;
  color?: string;
  isBold?: boolean;
  style?: Interpolation<Theme>;
  width?: string;
  minWidth?: string;
  textAlign?: string;
}

const Text: FC<TextProps> = memo(
  ({
    text,
    fontSize = "16px",
    color = DEFAULT_TEXT_COLOR,
    isBold = false,
    width = "unset",
    minWidth = "unset",
    textAlign = "unset",
    style,
  }) => {
    const textStyle = css`
      font-size: ${fontSize};
      color: ${color};
      width: ${width};
      min-width: ${minWidth};
      text-align: ${textAlign};
    `;

    return isBold ? (
      <b css={[textStyle, style]}>{text}</b>
    ) : (
      <p css={[textStyle, style]}>{text}</p>
    );
  }
);

export default Text;
