import { FC, useState } from "react";
import { DialogContext } from "../contexts/dialog.context";
import { useDarkScreen } from "../hooks/use-dark-screen";
import { DialogType } from "../types/index.type";

interface DialogProviderProps {
  children: React.ReactNode;
}

const DialogProvider: FC<DialogProviderProps> = ({ children }) => {
  const { toggleDarkScreen } = useDarkScreen();
  const [showDialogFlg, setShowDialogFlg] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [dialogType, setDialogType] = useState<DialogType>("caution");

  const toggleDialog = (showDialogFlg: boolean) => {
    setShowDialogFlg(showDialogFlg);
    toggleDarkScreen(showDialogFlg);
  };

  const openDialog = (dialogMessage: string, dialogType: DialogType) => {
    toggleDialog(true);
    setMessage(dialogMessage);
    setDialogType(dialogType);
  };

  const closeDialog = () => {
    toggleDialog(false);
    setMessage("");
  };

  const onClickOKButton = () => {
    return true;
  };

  return (
    <DialogContext.Provider
      value={{
        showDialogFlg,
        message,
        dialogType,
        openDialog,
        closeDialog,
        onClickOKButton,
      }}
    >
      {children}
    </DialogContext.Provider>
  );
};

export default DialogProvider;
