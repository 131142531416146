import { FC, memo } from "react";
import {
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ComposedChart,
  ResponsiveContainer,
} from "recharts";
import {
  BarChartData,
  LineChartData,
} from "../../../../types/charts/charts.type";
import TrendChartTooltip from "../../tooltip/trend-chart-tooltip";

interface TrendsCombinationChartProps {
  data: any[];
  width?: string;
  minWidth?: number;
  height?: number;
  barChartData: BarChartData[];
  lineChartData: LineChartData;
  xDataKey: string;
  y1DataKey: string;
  y2DataKey: string;
  yAxisLabel1: string;
  yAxisLabel2: string;
  unitXAxis: string;
  unitXBar: string[];
  unitYLine: string;
  tickFormatter1?: (value: any) => string;
  tickFormatter2?: (value: any) => string;
}

const TrendsCombinationChart: FC<TrendsCombinationChartProps> = memo(
  ({
    data,
    barChartData,
    lineChartData,
    xDataKey,
    y1DataKey,
    y2DataKey,
    yAxisLabel1,
    yAxisLabel2,
    width = "100%",
    height = 500,
    minWidth = 700,
    unitXBar,
    unitYLine,
    unitXAxis,
    tickFormatter1,
    tickFormatter2,
  }) => {
    return (
      <ResponsiveContainer width={width} minWidth={minWidth} height={height}>
        <ComposedChart data={data}>
          <XAxis
            unit={unitXAxis}
            dataKey={xDataKey}
            padding={{ right: 50, left: 50 }}
          />

          <YAxis
            dataKey={y1DataKey}
            yAxisId={1}
            label={{
              value: yAxisLabel1,
              fontSize: "13px",
              angle: 0,
              dx: -5,
              dy: 190,
            }}
            tick={{
              fontSize: "14px",
            }}
            tickFormatter={tickFormatter1}
          />

          <YAxis
            dataKey={y2DataKey}
            yAxisId={2}
            orientation="right"
            domain={[0, 5]}
            tickCount={6}
            label={{
              value: yAxisLabel2,
              fontSize: "13px",
              angle: 0,
              dx: -5,
              dy: 190,
            }}
            tick={{
              fontSize: "14px",
            }}
            tickFormatter={tickFormatter2}
          />

          <Tooltip content={<TrendChartTooltip labelUnit={unitXAxis} />} />

          <Legend />

          <CartesianGrid strokeDasharray="3 3" />
          {barChartData.map((item, index) => (
            <Bar
              key={index}
              yAxisId={1}
              dataKey={item.dataKey}
              fill={item.fillColor}
              name={item.name}
              unit={unitXBar[index]}
            />
          ))}
          <Line
            yAxisId={2}
            dataKey={lineChartData.dataKey}
            stroke={lineChartData.lineColor}
            name={lineChartData.name}
            unit={unitYLine}
          />
        </ComposedChart>
      </ResponsiveContainer>
    );
  }
);

export default TrendsCombinationChart;
