import { FC, useCallback } from "react";
import Settings from "../../../../components/template/settings/settings";
import { useDialog } from "../../../../hooks/use-dialog";
import { NOT_AVAILABLE_IN_THE_DEMO } from "../../../../constants/message/dialog-message";

const DemoSettingsPage: FC = () => {
  const { openDialog } = useDialog();

  const onClickNoAvailable = useCallback(() => {
    openDialog(NOT_AVAILABLE_IN_THE_DEMO, "caution");
  }, [openDialog]);

  return (
    <Settings
      onClickSetUserInformation={onClickNoAvailable}
      onClickSetPassword={onClickNoAvailable}
      onClickSetBusinessInformation={onClickNoAvailable}
      onClickSetFinanceInformation={onClickNoAvailable}
      onClickSetPersonHoursManagement={onClickNoAvailable}
      onClickSetClientInformation={onClickNoAvailable}
      onClickSetProjectInformation={onClickNoAvailable}
      onClickSetTaskInformation={onClickNoAvailable}
    />
  );
};

export default DemoSettingsPage;
