import { FC, useState } from "react";
import Dashboard from "../../../components/template/dashboard/dashboard";
import { DashboardData } from "../../../types/dashboard/dashboard.type";
import { CALENDAR_EVENT_COLORS } from "../../../constants/style/color.constant";

const DashboardPage: FC = () => {
  const [dashboardData, setDashboardData] = useState<DashboardData>(mockData);
  return <Dashboard dashboardData={dashboardData} />;
};

export default DashboardPage;

const mockData = {
  incomeAndExpenditure: {
    targetAnnualIncome: 10000000,
    totalMonthlyIncome: 3000000,
    monthlyIncome: 850000,
    monthlyExpenditure: 100000,
  },
  clients: [
    {
      companyName: "シンプレクス・ホールディングス株式会社",
      contractStartDate: "2023-04-01",
      contractEndDate: "2024-03-31",
    },
    {
      companyName: "LINEヤフー株式会社",
      contractStartDate: "2022-10-01",
      contractEndDate: "2024-01-31",
    },
    {
      companyName: "株式会社ミロク情報サービス",
      contractStartDate: "2023-09-01",
      contractEndDate: "2024-02-28",
    },
    {
      companyName: "オービック株式会社",
      contractStartDate: "2019-04-01",
      contractEndDate: "2024-03-31",
    },
  ],
  tasks: [
    {
      id: 1,
      taskTitle: "確定申告",
      status: 1,
      dueDate: "2024-03-31",
    },
    {
      id: 2,
      taskTitle: "プロジェクトレポート提出",
      status: 2,
      dueDate: "2024-03-25",
    },
    {
      id: 3,
      taskTitle: "FE・テストコード修正",
      status: 2,
      dueDate: "2024-04-03",
    },
    {
      id: 4,
      taskTitle: "BE・ソースコードリファクタ、テストコード実装",
      status: 2,
      dueDate: "2024-05-01",
    },
    {
      id: 5,
      taskTitle: "外部設計書ブラッシュアップ",
      status: 1,
      dueDate: "2023-06-30",
    },
  ],
  projects: [
    {
      projectName: "リポジトリ分割",
      companyName: "LINEヤフー株式会社",
      projectProgressStatus: 2,
    },
    {
      projectName: "固定資産主要機能開発",
      companyName: "株式会社ミロク情報サービス",
      projectStartDate: "2024-01-15",
      projectEndDate: "2024-06-30",
      projectProgressStatus: 1,
    },
  ],
  personHours: [
    {
      projectName: "カテゴリ1",
      personHoursRatio: 35,
      companyName: "LINEヤフー株式会社",
    },
    {
      projectName: "カテゴリ2",
      personHoursRatio: 65,
      companyName: "LINEヤフー株式会社",
    },
  ],
  schedule: [
    {
      title: "リポジトリ分割",
      start: "2024-04-20",
      end: "2024-05-10",
      description: "フロントエンド、バックエンドのリポジトリ分割",
      backgroundColor: CALENDAR_EVENT_COLORS.PROJECT,
      borderColor: CALENDAR_EVENT_COLORS.PROJECT,
      allDay: true,
    },
    {
      title: "FE単体テスト",
      start: "2024-04-28",
      end: "2024-05-08",
      description: "フロントエンドの単体テスト",
      backgroundColor: CALENDAR_EVENT_COLORS.PROJECT,
      borderColor: CALENDAR_EVENT_COLORS.PROJECT,
      allDay: true,
    },
    {
      title: "サンプルMTG A",
      start: "2024-05-02T12:30:00",
      end: "2024-05-02T13:30:00",
      description: "サンプル",
      backgroundColor: CALENDAR_EVENT_COLORS.MEETING,
      borderColor: CALENDAR_EVENT_COLORS.MEETING,
      allDay: false,
    },
    {
      title: "ディナー",
      start: "2024-05-03T19:00:00",
      end: "2024-05-03T21:30:00",
      description: "サンプル",
      backgroundColor: CALENDAR_EVENT_COLORS.PRIVATE,
      borderColor: CALENDAR_EVENT_COLORS.PRIVATE,
      allDay: false,
    },
    {
      title: "旅行",
      start: "2024-05-07",
      end: "2024-05-10",
      description: "サンプル",
      backgroundColor: CALENDAR_EVENT_COLORS.PRIVATE,
      borderColor: CALENDAR_EVENT_COLORS.PRIVATE,
      allDay: true,
    },
    {
      title: "請求書作成",
      start: "2024-05-01",
      end: "2024-05-3",
      description: "サンプル",
      backgroundColor: CALENDAR_EVENT_COLORS.TASK,
      borderColor: CALENDAR_EVENT_COLORS.TASK,
      allDay: true,
    },
    {
      title: "出張",
      start: "2024-05-17",
      end: "2024-05-20",
      description: "サンプル",
      backgroundColor: CALENDAR_EVENT_COLORS.OTHER,
      borderColor: CALENDAR_EVENT_COLORS.OTHER,
      allDay: true,
    },
  ],
};
