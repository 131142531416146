import { FC } from "react";
import Invoice from "../../../components/template/invoice/invoice";

const InvoicePage: FC = () => {
  const onClickCreateInvoice = () => {};

  const onClickCreatedInvoice = () => {};

  const onClickInvoiceSetting = () => {};

  return (
    <Invoice
      numberOfIssuedInvoiceLastMonth={3}
      totalBillingAmountLastMonth={450000}
      invoiceManagementData={[
        {
          companyName: "サンプル会社A",
          status: 0,
          billingAmount: 150000,
        },
        {
          companyName: "サンプル会社B",
          status: 2,
          billingAmount: 230000,
        },
        {
          companyName: "サンプル会社C",
          status: 1,
          billingAmount: 110000,
        },
        {
          companyName: "サンプル会社D",
          status: 1,
          billingAmount: 100000,
        },
      ]}
      onClickCreateInvoice={onClickCreateInvoice}
      onClickCreatedInvoice={onClickCreatedInvoice}
      onClickInvoiceSetting={onClickInvoiceSetting}
    />
  );
};

export default InvoicePage;
