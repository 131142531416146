import { FC, memo } from "react";
import InformationFrame from "../../molecules/frame/information/information-frame";
import BasicCard from "../../molecules/cards/basic-card/basic-card";
import { formatWithComma } from "../../../lib/formatter";
import ThemeFrame from "../../atoms/frame/theme-frame";
import { WHITE_COLOR } from "../../../constants/style/color.constant";

interface TrendChartTooltipProps {
  active?: boolean;
  payload?: any[];
  label?: string | number | Date;
  labelUnit?: string;
}

const TrendChartTooltip: FC<TrendChartTooltipProps> = memo(
  ({ active, payload, label, labelUnit }) => {
    if (active && payload && payload.length) {
      return (
        <ThemeFrame color={WHITE_COLOR} borderRadius="10px">
          <InformationFrame title={`${String(label)}${labelUnit}`}>
            {payload.map((data: any, index: number) => (
              <BasicCard
                key={index}
                titleBold={false}
                title={data.name}
                description={`${formatWithComma(Number(data.value))}${
                  data.unit
                }`}
                color={String(data.color)}
              />
            ))}
          </InformationFrame>
        </ThemeFrame>
      );
    } else {
      return null;
    }
  }
);

export default TrendChartTooltip;
