/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { css } from "@emotion/react";
import { DEFAULT_LIST_COLORS } from "../../../../constants/style/color.constant";

interface ListCardProps {
  title: string;
  width?: string;
  height?: string;
  color?: string;
  fontSize?: string;
  titleWidth?: string;
  gap?: string;
  children?: React.ReactNode;
}

const ListCard: FC<ListCardProps> = memo(
  ({
    title,
    color = DEFAULT_LIST_COLORS[1],
    width = "100%",
    height = "auto",
    fontSize = "14px",
    titleWidth = "60%",
    gap = "unset",
    children,
  }) => {
    const container = css`
      padding: 10px;
      background-color: ${color};
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 10px;
      gap: ${gap};
      transition: 0.7s;
      :hover {
        background-color: #bdbdbd;
      }
    `;

    const listSize = css`
      width: ${width};
      height: ${height};
    `;

    const textStyle = css`
      font-size: ${fontSize};
    `;

    const textWrapper = css`
      width: ${titleWidth};
    `;

    const detailWrapper = css`
      display: flex;
      justify-content: space-around;
      align-items: center;
      gap: ${gap};
      width: calc(100% - ${titleWidth});
    `;

    return (
      <div css={[container, listSize]}>
        <div css={[textWrapper, textStyle]}>{title}</div>
        <div css={detailWrapper}>{children}</div>
      </div>
    );
  }
);

export default ListCard;
