/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { css } from "@emotion/react";
import Title from "../../../atoms/characters/title/title";
import { TITLE_SIZE } from "../../../../constants/element/element.constant";
import {
  DEFAULT_FRAME_SHADOW,
  QUICK_START_CARD_COLORS,
  WHITE_COLOR,
} from "../../../../constants/style/color.constant";
import InformationFrame from "../../../molecules/frame/information/information-frame";
import QuickStartCard from "../../../molecules/cards/quick-start-card/quick-start-card";
import CurrencyYenIcon from "@mui/icons-material/CurrencyYen";
import DashboardIcon from "@mui/icons-material/Dashboard";
import WorkIcon from "@mui/icons-material/Work";
import PeopleIcon from "@mui/icons-material/People";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DescriptionIcon from "@mui/icons-material/Description";
import TaskIcon from "@mui/icons-material/Task";
import IntroductionContainer from "../../../atoms/container/introduction-container";

const AboutSection: FC = memo(() => {
  return (
    <section id="about">
      <IntroductionContainer width="85%">
        <div css={container}>
          <Title
            text="事業をサポートするオールインワン"
            titleSize={TITLE_SIZE.LARGE}
            margin="0px 0px 25px 0px"
            color={"#ffffff"}
          />
          <InformationFrame direction="row" gap="20px">
            <InformationFrame direction="row" gap="20px" flexGrow={1}>
              <QuickStartCard
                backgroundColor={WHITE_COLOR}
                flexGrow={1}
                boldText="ダッシュボード"
                lineColor={QUICK_START_CARD_COLORS[0]}
                icon={<DashboardIcon color="info" />}
              />
              <QuickStartCard
                backgroundColor={WHITE_COLOR}
                flexGrow={1}
                boldText="収支管理"
                lineColor={QUICK_START_CARD_COLORS[1]}
                icon={<CurrencyYenIcon color="info" />}
              />
            </InformationFrame>

            <InformationFrame direction="row" gap="20px" flexGrow={1}>
              <QuickStartCard
                backgroundColor={WHITE_COLOR}
                flexGrow={1}
                boldText="工数管理"
                lineColor={QUICK_START_CARD_COLORS[2]}
                icon={<WorkIcon color="info" />}
              />
              <QuickStartCard
                backgroundColor={WHITE_COLOR}
                flexGrow={1}
                boldText="顧客管理"
                lineColor={QUICK_START_CARD_COLORS[3]}
                icon={<PeopleIcon color="info" />}
              />
            </InformationFrame>
          </InformationFrame>

          <InformationFrame direction="row" gap="20px" margin="20px 0 0 0">
            <InformationFrame direction="row" gap="20px" flexGrow={1}>
              <QuickStartCard
                backgroundColor={WHITE_COLOR}
                flexGrow={1}
                boldText="プロジェクト管理"
                lineColor={QUICK_START_CARD_COLORS[4]}
                icon={<WorkspacesIcon color="info" />}
              />

              <QuickStartCard
                backgroundColor={WHITE_COLOR}
                flexGrow={1}
                boldText="タスク管理"
                lineColor={QUICK_START_CARD_COLORS[5]}
                icon={<TaskIcon color="info" />}
              />
            </InformationFrame>

            <InformationFrame direction="row" gap="20px" flexGrow={1}>
              <QuickStartCard
                backgroundColor={WHITE_COLOR}
                flexGrow={1}
                boldText="スケジュール管理"
                lineColor={QUICK_START_CARD_COLORS[6]}
                icon={<CalendarMonthIcon color="info" />}
              />
              <QuickStartCard
                backgroundColor={WHITE_COLOR}
                flexGrow={1}
                boldText="請求書作成"
                lineColor={QUICK_START_CARD_COLORS[7]}
                icon={<DescriptionIcon color="info" />}
              />
            </InformationFrame>
          </InformationFrame>
        </div>
      </IntroductionContainer>
    </section>
  );
});

export default AboutSection;

const container = css`
  width: 100%;
  border-radius: 20px;
  padding: 30px;
  background-color: #ffffff2c;
  box-shadow: ${DEFAULT_FRAME_SHADOW};
`;
