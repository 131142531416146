/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { css } from "@emotion/react";

interface IntroductionContainerProps {
  color?: string;
  width?: string;
  contentWidth?: string;
  contentMaxWidth?: string;
  margin?: string;
  padding?: string;
  children: React.ReactNode;
}

const IntroductionContainer: FC<IntroductionContainerProps> = memo(
  ({
    color = "transparent",
    width = "100vw",
    contentWidth = "100%",
    contentMaxWidth = "1920px",
    margin = "0px auto",
    padding = "90px 0px",
    children,
  }) => {
    const container = css`
      width: ${width};
      background-color: ${color};
      margin: ${margin};
      padding: ${padding};
    `;

    const wrapper = css`
      width: ${contentWidth};
      max-width: ${contentMaxWidth};
      margin: 0px auto;
    `;

    return (
      <div css={container}>
        <div css={wrapper}>{children}</div>
      </div>
    );
  }
);

export default IntroductionContainer;
