import { FC, memo } from "react";
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts";
import {
  DEFAULT_ACHIEVEMENT_RATE_COLORS,
  DEFAULT_CHART_FILL_COLOR,
  DEFAULT_CHART_TOOLTIP_COLOR,
} from "../../../../constants/style/color.constant";
import { DefaultChartData } from "../../../../types/charts/charts.type";
import { formatByDataType } from "../../../../lib/formatter";
import { DataType } from "../../../../types/index.type";
import { CHART_TOOLTIP_STYLE } from "../../../../constants/style/css-properties.constant";

interface AchievementRatePieChartProps {
  targetValue: number;
  achievementValue: number;
  innerRadius?: string;
  displayCompact?: boolean;
  width: number | string;
  height: number | string;
  fillColors?: string[];
  dataType: DataType;
}

const AchievementRatePieChart: FC<AchievementRatePieChartProps> = memo(
  ({
    targetValue,
    achievementValue,
    innerRadius = "35%",
    displayCompact = false,
    width,
    height,
    fillColors = DEFAULT_ACHIEVEMENT_RATE_COLORS,
    dataType,
  }) => {
    const data: DefaultChartData[] = [
      { name: "達成済み", value: achievementValue },
      { name: "残り", value: targetValue - achievementValue },
    ];

    return (
      <ResponsiveContainer width={width} height={height}>
        <PieChart compact={displayCompact} style={{ zIndex: "2" }}>
          <Pie
            data={data}
            cx={"50%"}
            cy={"50%"}
            labelLine={false}
            innerRadius={innerRadius}
            fill={DEFAULT_CHART_FILL_COLOR}
            dataKey="value"
            startAngle={90}
            endAngle={-270}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={fillColors[index % fillColors.length]}
              />
            ))}
          </Pie>

          <Tooltip
            itemStyle={{ color: DEFAULT_CHART_TOOLTIP_COLOR }}
            contentStyle={CHART_TOOLTIP_STYLE}
            separator={" "}
            formatter={(value) => formatByDataType(Number(value), dataType)}
          />
        </PieChart>
      </ResponsiveContainer>
    );
  }
);

export default AchievementRatePieChart;
