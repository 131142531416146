/** @jsxImportSource @emotion/react */

export const BG_COLOR = "#eef5fa";

export const DARK_SCREEN_COLOR = "#343434b9";

export const PROFILE_TEXT_COLOR = "#1f1f47";

export const DEFAULT_BUTTON_COLOR = "#ffffff";
export const DEFAULT_BUTTON_BG_COLOR = "#475099";

export const DEFAULT_OK_BUTTON_COLOR = "#5395b9";
export const DEFAULT_CANCEL_BUTTON_COLOR = "#df7a47";
export const DEFAULT_CLOSE_BUTTON_COLOR = "#696969";

export const DEFAULT_CHART_TOOLTIP_COLOR = "#475499";

export const DEFAULT_FRAME_COLOR = "#efefef58";

export const DEFAULT_FRAME_SHADOW = "0px 15px 20px rgba(0, 0, 0, 0.15)";

export const DEFAULT_GRADATION_SCREEN_COLOR =
  "linear-gradient(105deg, rgb(103, 193, 216), rgb(86, 109, 168))";

export const INTRODUCTION_TEXT_COLOR = "#373f6e";
export const INTRODUCTION_FOOTER_BG_COLOR = "#3d3d3d";

export const PIE_CHART_COLORS = [
  "#5abbae",
  "#6aade8",
  "#ef8d4f",
  "#8153be",
  "#df6262",
  "#7fd55a",
  "#5661cb",
  "#da65d0",
];

export const STAUS_COLORS = [
  "#808080",
  "#59c5b3",
  "#eb874d",
  "#69c753",
  "#5553ca",
  "#c84444",
];

export const PRIORITY_COLORS = ["#7f7f7f", "#6eb1c0", "#cd6360"];

export const PROJECT_PROGRESS_STATUS_COLORS = ["#67ce84", "#66add6", "#d45757"];

export const QUICK_START_CARD_COLORS = [
  "#5abbae",
  "#79b3e7",
  "#ec955f",
  "#9e78cf",
  "#dd7a7a",
  "#9adc7d",
  "#8a93e2",
  "#e985e1",
];

export const DEFAULT_CHART_FILL_COLOR = "#6b6b6b";

export const DEFAULT_SUMMARY_COLOR = "#e8e8e859";

export const DEFAULT_ACHIEVEMENT_RATE_COLORS = ["#5abbae", "#969696"];

export const DEFAULT_TITLE_COLOR = "#3c4a83";

export const DEFAULT_TEXT_COLOR = "#303132";

export const DEFAULT_LINE_COLOR = "#7bd8b7";

export const DEFAULT_PERCENT_COLOR = "#d67545";

export const WHITE_COLOR = "#ffffff";

export const ACHIEVEMENT_RATE_WHITE_COLORS = ["#5dd4dd", WHITE_COLOR];

export const DEFAULT_LIST_COLORS = ["#dee3f8ae", "transparent"];

/**
 * Summary color
 */
export const FINANCE_SUMMARY_COLOR =
  "linear-gradient(160deg,rgb(101, 210, 192), rgb(106, 131, 212))";

export const CLIENTS_SUMMARY_COLOR =
  "linear-gradient(160deg, rgb(234, 195, 91), rgb(229, 126, 121))";

export const PROJECT_SUMMARY_COLOR =
  "linear-gradient(160deg, rgb(188, 226, 97), rgb(86, 202, 134))";

export const SCHEDULE_SUMMARY_COLOR =
  "linear-gradient(160deg, rgb(234, 133, 168), rgb(143, 127, 235))";

export const TASK_SUMMARY_COLOR =
  "linear-gradient(160deg, rgb(136, 188, 225), rgb(109, 221, 109))";

export const PERSON_HOURS_SUMMARY_COLOR =
  "linear-gradient(160deg, rgb(103, 201, 216), rgb(210, 103, 199))";

export const INVOICE_SUMMARY_COLOR =
  "linear-gradient(160deg, rgb(216, 210, 103), rgb(207, 103, 210))";

// schedule
export const CALENDAR_EVENT_COLORS = {
  PROJECT: "#57b573",
  PRIVATE: "#dc7dc6",
  MEETING: "#6c8ed8",
  TASK: "#dc9154",
  OTHER: "#6d6d6d",
};
