/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { css } from "@emotion/react";
import { MEDIA_QUERY } from "../../../../constants/style/media.constant";

interface CalendarThemeProps {
  children: React.ReactNode;
}

const CalendarTheme: FC<CalendarThemeProps> = memo(({ children }) => {
  return <div css={calendarWrapper}>{children}</div>;
});

export default CalendarTheme;

const calendarWrapper = css`
  width: 100%;
  height: 100%;

  .fc .fc-col-header-cell {
    font-size: 0.75rem;
    font-weight: normal;
    color: #909090;
    border: none;
  }
  .fc .fc-scrollgrid {
    border-width: 0;
  }

  .fc .fc-scrollgrid-section > * {
    border: none;
  }

  .fc .fc-scrollgrid-sync-table {
    border: 1px;
  }

  .fc .fc-button-primary {
    background-color: #797dcf;
    border: none;
  }

  .fc .fc-button-active {
    background-color: #49498aff !important;
  }

  /* 土曜日 */
  td.fc-day-sat {
    background-color: #dfeaf4;
  }

  /* 日曜日 */
  td.fc-day-sun {
    background-color: #f6e7e7;
  }

  /* リストの日付、曜日の背景 */
  .fc-theme-standard .fc-list-day-cushion {
    background-color: #dadef2;
  }

  /* リストの日付、曜日のテキスト */
  .fc-list-day-text,
  .fc-list-day-side-text {
    color: #333765;
  }

  .fc-toolbar-title {
    color: #3d4977;
  }

  ${MEDIA_QUERY.SP} {
    .fc-daygrid-day-number {
      font-size: 12px;
    }

    .fc-toolbar-title {
      font-size: 15px;
    }

    .fc-button-primary {
      width: auto;
      padding: 5px 8px;
      height: 30px;
      font-size: 10px;
    }

    .fc-icon-chevron-left {
      width: 15px;
    }

    .fc-nextYear-button {
      display: none;
    }

    .fc-prevYear-button {
      display: none;
    }

    .fc-listWeek-button {
      display: none;
    }

    .fc-timegrid-slot-label-cushion {
      font-size: 14px;
    }

    .fc-event-title,
    .fc-event-time {
      font-size: 13px;
    }
  }
`;
