import { FC, memo } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import jaLocale from "@fullcalendar/core/locales/ja";
import { ScheduleData } from "../../../types/schedule/schedule.type";
import CalendarTheme from "./style/calendar-theme";

interface CompactCalendarProps {
  height?: string;
  scheduleData: ScheduleData[];
}

const CompactCalendar: FC<CompactCalendarProps> = memo(
  ({ height = "100%", scheduleData }) => {
    return (
      <CalendarTheme>
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin]}
          initialView="dayGridMonth"
          events={scheduleData}
          weekends={true}
          headerToolbar={{
            start: "today prev,next",
            center: "title",
            end: "dayGridMonth,timeGridDay",
          }}
          locale={jaLocale}
          height={height}
          // 必要であれば以下も定義
          // contentHeight={"700px"}
          // aspectRatio={0.6}
        />
      </CalendarTheme>
    );
  }
);

export default CompactCalendar;
