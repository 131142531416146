/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import MenuButton from "../../atoms/buttons/menu-button";
import UserProfile from "../profile/user/user-profile";
import HeaderContainer from "../../atoms/container/header-container";

interface AppHeaderProps {
  userName: string;
  onClickMenuButton: () => void;
  menuBtnDisabledFlg: boolean;
}

const AppHeader: FC<AppHeaderProps> = memo(
  ({ userName, onClickMenuButton, menuBtnDisabledFlg }) => {
    return (
      <HeaderContainer>
        <div>
          <MenuButton
            onClickMenuButton={onClickMenuButton}
            disabledFlg={menuBtnDisabledFlg}
          />
        </div>
        <div>
          <UserProfile userName={userName} />
        </div>
      </HeaderContainer>
    );
  }
);

export default AppHeader;
