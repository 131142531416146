/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { css } from "@emotion/react";
import HeroTitle from "./hero-parts/hero-title";
import HeroImage from "./hero-parts/hero-image";
import IntroductionContainer from "../../../atoms/container/introduction-container";

interface HeroSectionProps {
  onClickPlayDemo: () => void;
}

const HeroSection: FC<HeroSectionProps> = memo(({ onClickPlayDemo }) => {
  return (
    <section id="top">
      <IntroductionContainer contentWidth="95%" padding="90px 0px 50px 0px">
        <div css={container}>
          <div css={wrapper}>
            <HeroTitle onClickPlayDemo={onClickPlayDemo} />
            <HeroImage />
          </div>
        </div>
      </IntroductionContainer>
    </section>
  );
});

export default HeroSection;

const container = css`
  min-height: 680px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const wrapper = css`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5%;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
`;
