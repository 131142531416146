import { TitleSize } from "../../types/elements/elements.type";
import { AddCircle } from "@mui/icons-material";
import SettingsIcon from "@mui/icons-material/Settings";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import HistoryIcon from "@mui/icons-material/History";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import BusinessIcon from "@mui/icons-material/Business";
import LockIcon from "@mui/icons-material/Lock";
import CurrencyYenIcon from "@mui/icons-material/CurrencyYen";
import WorkIcon from "@mui/icons-material/Work";
import PeopleIcon from "@mui/icons-material/People";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import SplitscreenIcon from "@mui/icons-material/Splitscreen";
import TaskIcon from "@mui/icons-material/Task";

export const TITLE_SIZE: { [key: string]: TitleSize } = {
  LARGE: 0,
  MEDIUM: 1,
  SMALL: 2,
};

export const QUICK_START_ICON = {
  ADD: <AddCircle color="action" />,
  SETTING: <SettingsIcon color="action" />,
  ARROW: <ArrowForwardIosIcon color="action" />,
  HISTORY: <HistoryIcon color="action" />,
  USER: <AccountCircleIcon color="action" />,
  PASSWORD: <LockIcon color="action" />,
  COMPANY: <BusinessIcon color="action" />,
  MONEY: <CurrencyYenIcon color="action" />,
  TASK: <TaskIcon color="action" />,
  PERSON_HOURS: <WorkIcon color="action" />,
  CLIENT: <PeopleIcon color="action" />,
  PROJECT: <WorkspacesIcon color="action" />,
  MANAGEMENT: <SplitscreenIcon color="action" />,
};
