/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { css } from "@emotion/react";
import {
  convertToShortName,
  convertToShortCompanyName,
} from "../../../../lib/converter";
import ListCard from "../../../molecules/cards/list-card/list-card";
import { ProjectInformation } from "../../../../types/projects/projects.type";
import ListFrame from "../../../molecules/frame/list/list-frame";
import { applyColorToList } from "../../../../lib/style-composer";
import RelatedItem from "../../../atoms/icon/related-item/related-item";
import StartToEndDate from "../../../molecules/date/start-to-end-date";
import ProgressBar from "../../../atoms/icon/progress/progress-bar";
import { PROJECT_PROGRESS_STATUS_LABEL } from "../../../../constants/label/label.constant";
import { PROJECT_PROGRESS_STATUS_COLORS } from "../../../../constants/style/color.constant";
import StatusIcon from "../../../atoms/icon/status/status-icon";
import { NO_PROJECTS_IN_PROGRESS } from "../../../../constants/description/description.constant";
import Label from "../../../molecules/label/label";
import { MEDIA_QUERY } from "../../../../constants/style/media.constant";
import { PROJECT_STATUS } from "../../../../constants/status/status.constant";

interface ProjectInProgressListProps {
  projectData: ProjectInformation[];
  fontSize?: string;
  width?: string;
  height?: string;
  maxWidth?: string;
  maxHeight?: string;
  title: string;
}

const ProjectInProgressList: FC<ProjectInProgressListProps> = memo(
  ({
    projectData,
    fontSize = "15px",
    width,
    height,
    maxWidth,
    maxHeight,
    title,
  }) => {
    const projectInProgressData = projectData.filter(
      (project) => project.projectStatus === PROJECT_STATUS.PROGRESS
    );

    return (
      <ListFrame
        title={title}
        width={width}
        height={height}
        maxWidth={maxWidth}
        maxHeight={maxHeight}
      >
        {projectInProgressData && projectInProgressData.length !== 0 ? (
          projectInProgressData.map((project, index) => (
            <ListCard
              key={index}
              title={convertToShortName(project.projectName, 15)}
              color={applyColorToList(index)}
              fontSize={fontSize}
            >
              <ProgressBar
                width="20%"
                progressValue={project.amountOfProjectProgress}
                style={progressBarStyle}
              />

              <StatusIcon
                statusColorList={PROJECT_PROGRESS_STATUS_COLORS}
                statusLabelList={PROJECT_PROGRESS_STATUS_LABEL}
                status={project.projectProgressStatus}
                width="20%"
                style={statusStyle}
              />

              <RelatedItem
                width="20%"
                caption={convertToShortCompanyName(project.companyName, 6)}
                style={relatedItemStyle}
              />

              <StartToEndDate
                width="25%"
                startDate={new Date(project.projectStartDate)}
                endDate={new Date(project.projectEndDate)}
                style={dateStyle}
              />
            </ListCard>
          ))
        ) : (
          <Label width="100%" text={NO_PROJECTS_IN_PROGRESS} />
        )}
      </ListFrame>
    );
  }
);

export default ProjectInProgressList;

const statusStyle = css`
  ${MEDIA_QUERY.TABLET} {
    width: 45%;
  }

  ${MEDIA_QUERY.SP} {
    width: 100%;
  }
`;

const relatedItemStyle = css`
  ${MEDIA_QUERY.TABLET} {
    width: 45%;
  }

  ${MEDIA_QUERY.SP} {
    display: none;
  }
`;

const progressBarStyle = css`
  ${MEDIA_QUERY.TABLET} {
    display: none;
  }
`;

const dateStyle = css`
  ${MEDIA_QUERY.TABLET} {
    display: none;
  }
`;
