/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { css, Theme, Interpolation } from "@emotion/react";
import { convertToShortName } from "../../../../lib/converter";

interface RelatedItemProps {
  caption: string;
  fontSize?: string;
  width?: string;
  padding?: string;
  style?: Interpolation<Theme>;
}

const RelatedItem: FC<RelatedItemProps> = memo(
  ({ caption, fontSize = "14px", width = "200px", padding = "5px", style }) => {
    const textStyle = css`
      font-size: ${fontSize};
    `;
    const iconSize = css`
      width: ${width};
      padding: ${padding};
    `;
    return (
      <div css={[container, textStyle, iconSize, style ? style : null]}>
        {convertToShortName(caption, 7)}
      </div>
    );
  }
);

export default RelatedItem;

const container = css`
  border-radius: 10px;
  color: #ffffff;
  background-color: #36429a;
  display: flex;
  justify-content: center;
  align-self: center;
  cursor: default;
`;
