import { createContext } from "react";
import { DialogType } from "../types/index.type";

interface DialogContextType {
  showDialogFlg: boolean;
  message: string;
  dialogType: DialogType;
  openDialog: (dialogMessage: string, dialogType: DialogType) => void;
  closeDialog: () => void;
  onClickOKButton: () => void;
}

export const DialogContext = createContext<DialogContextType>({showDialogFlg: false, message: "", dialogType: "caution", openDialog: () => {}, closeDialog: () => {}, onClickOKButton: () => {}});