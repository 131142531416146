import { FC, useCallback } from "react";
import Finance from "../../../../components/template/finance/finance";
import {
  DEMO_FINANCE_DATA,
  DEMO_PROFIT_TRENDS_DATA,
  DEMO_ANNUAL_INCOME_ANALYTICS_DATA,
  DEMO_MONTHLY_INCOME_ANALYTICS_DATA,
  DEMO_ANNUAL_EXPENDITURE_ANALYTICS_DATA,
  DEMO_MONTHLY_EXPENDITURE_ANALYTICS_DATA,
} from "../../../../constants/demo/finance.constant";
import { useDialog } from "../../../../hooks/use-dialog";
import { NOT_AVAILABLE_IN_THE_DEMO } from "../../../../constants/message/dialog-message";

const DemoFinancePage: FC = () => {
  const { openDialog } = useDialog();

  const onClickNoAvailable = useCallback(() => {
    openDialog(NOT_AVAILABLE_IN_THE_DEMO, "caution");
  }, [openDialog]);

  return (
    <Finance
      monthlyIncome={DEMO_FINANCE_DATA.MONTHLY_INCOME}
      monthlyExpenditure={DEMO_FINANCE_DATA.MONTHLY_EXPENDITURE}
      totalMonthlyIncome={DEMO_FINANCE_DATA.TOTAL_MONTHLY_INCOME}
      totalMonthlyExpenditure={DEMO_FINANCE_DATA.TOTAL_MONTHLY_EXPENDITURE}
      targetAnnualIncome={DEMO_FINANCE_DATA.TARGET_ANNUAL_INCOME}
      targetAnnualProfit={DEMO_FINANCE_DATA.TARGET_ANNUAL_PROFIT}
      targetAnnualProfitRatio={DEMO_FINANCE_DATA.TARGET_ANNUAL_PROFIT_RATIO}
      profitTrendsData={DEMO_PROFIT_TRENDS_DATA}
      annualIncomeAnalyticsData={DEMO_ANNUAL_INCOME_ANALYTICS_DATA}
      annualExpenditureAnalyticsData={DEMO_ANNUAL_EXPENDITURE_ANALYTICS_DATA}
      monthlyIncomeAnalyticsData={DEMO_MONTHLY_INCOME_ANALYTICS_DATA}
      monthlyExpenditureAnalyticsData={DEMO_MONTHLY_EXPENDITURE_ANALYTICS_DATA}
      onClickAddAnnualIncome={onClickNoAvailable}
      onClickAddAnnualExpenditure={onClickNoAvailable}
      onClickAddMonthlyIncome={onClickNoAvailable}
      onClickAddMonthlyExpenditure={onClickNoAvailable}
    />
  );
};

export default DemoFinancePage;
