/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { css } from "@emotion/react";
import {
  INTRODUCTION_FOOTER_BG_COLOR,
  WHITE_COLOR,
} from "../../../constants/style/color.constant";

const PortalFooter: FC = memo(() => {
  return (
    <footer css={container}>
      <small>&copy; 2024 Takumasa Sugiyama</small>
    </footer>
  );
});

export default PortalFooter;

const container = css`
  padding: 5px 0px;
  width: 100%;
  text-align: center;
  color: ${WHITE_COLOR};
  background-color: ${INTRODUCTION_FOOTER_BG_COLOR};
`;
