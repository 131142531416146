import { FC, useCallback } from "react";
import PersonHours from "../../../components/template/person-hours/person-hours";

const PersonHoursPage: FC = () => {
  const onClickRegisterPersonHours = useCallback(() => {}, []);

  const onClickPersonHoursHistory = useCallback(() => {}, []);

  return (
    <PersonHours
      annualWorkingDays={195}
      annualWorkingHours={1895}
      lastMonthWorkingDays={20}
      lastMonthWorkingHours={200}
      personHoursLastMonthData={[
        {
          personHoursRatio: 65,
          projectName: "リポジトリ分割",
          companyName: "LINEヤフー株式会社",
        },
        {
          personHoursRatio: 35,
          projectName: "FE単体テスト",
          companyName: "LINEヤフー株式会社",
        },
      ]}
      annualPersonHoursData={[
        {
          personHoursRatio: 60,
          projectName: "画面開発",
          companyName: "LINEヤフー株式会社",
        },
        {
          personHoursRatio: 25,
          projectName: "リポジトリ分割",
          companyName: "LINEヤフー株式会社",
        },
        {
          personHoursRatio: 15,
          projectName: "FE単体テスト",
          companyName: "LINEヤフー株式会社",
        },
      ]}
      workHoursAndIncomeData={[
        {
          month: 1,
          workHours: 200,
          income: 400000,
        },
        {
          month: 2,
          workHours: 210,
          income: 430000,
        },
        {
          month: 3,
          workHours: 180,
          income: 305000,
        },
        {
          month: 4,
          workHours: 300,
          income: 600000,
        },
        {
          month: 5,
          workHours: 197,
          income: 430000,
        },
        {
          month: 6,
          workHours: 210,
          income: 550000,
        },
        {
          month: 7,
          workHours: 190,
          income: 390000,
        },
        {
          month: 8,
          workHours: 280,
          income: 700000,
        },
        {
          month: 9,
          workHours: 200,
          income: 500000,
        },
        {
          month: 10,
          workHours: 280,
          income: 800000,
        },
        {
          month: 11,
          workHours: 220,
          income: 550000,
        },
        {
          month: 12,
          workHours: 203,
          income: 600000,
        },
      ]}
      onClickRegisterPersonHours={onClickRegisterPersonHours}
      onClickPersonHoursHistory={onClickPersonHoursHistory}
    />
  );
};

export default PersonHoursPage;
