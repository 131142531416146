/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { css } from "@emotion/react";
import {
  DEFAULT_TEXT_COLOR,
  WHITE_COLOR,
} from "../../../../constants/style/color.constant";
import IndicatorCard from "../indicator-card/indicator-card";
import { YEN_UNIT } from "../../../../constants/unit/unit.constant";
import Text from "../../../atoms/characters/text/text";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Title from "../../../atoms/characters/title/title";
import { TITLE_SIZE } from "../../../../constants/element/element.constant";
import ThemeFrame from "../../../atoms/frame/theme-frame";

interface PricesCardProps {
  width?: string;
  height?: string;
  backgroundColor?: string;
  textColor?: string;
  price: number;
  availableFeaturesList?: string[];
  planName: string;
}

const PricesCard: FC<PricesCardProps> = memo(
  ({
    width = "auto",
    height = "auto",
    backgroundColor = WHITE_COLOR,
    textColor = DEFAULT_TEXT_COLOR,
    price,
    availableFeaturesList,
    planName,
  }) => {
    return (
      <ThemeFrame
        width={width}
        height={height}
        borderRadius="10px"
        padding="35px"
        color={backgroundColor}
      >
        <Title text={planName} titleSize={TITLE_SIZE.LARGE} />
        <IndicatorCard
          indicatorValue={price}
          withComma={true}
          indicatorUnit={YEN_UNIT}
          color={textColor}
          indicatorFontSize="60px"
        />

        <div css={listContainer}>
          {availableFeaturesList && availableFeaturesList.length ? (
            availableFeaturesList.map((feature: string, index: number) => (
              <div css={listWrapper} key={index}>
                <CheckBoxIcon color="info" />
                <Text text={feature} />
              </div>
            ))
          ) : (
            <Text
              color={textColor}
              text="準備中"
              fontSize="30px"
              isBold={true}
            />
          )}
        </div>
      </ThemeFrame>
    );
  }
);

export default PricesCard;

const listContainer = css`
  margin-top: 30px;
`;

const listWrapper = css`
  display: flex;
  gap: 10px;
`;
