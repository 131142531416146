import { getLastMonth, getCurrentMonth } from "../../lib/calendar";

/**
 * portal
 */

export const PLAY_DEMO_TITLE = "デモ体験";
export const LOGIN_TITLE = "ログイン";
export const SIGN_UP_TITLE = "アカウント作成";

/**
 * common
 */

export const TARGET_TITLE = "目標";
export const ACHIEVEMENT_TITLE = "達成";
export const ANNUAL_TITLE = "年間";
export const THIS_MONTH_TITLE = `${getCurrentMonth()}月`;

/**
 * finance
 */

export const ANNUAL_TARGET_INCOME_TITLE = "収入目標(年間)";
export const TARGET_INCOME_TITLE = "収益目標";
export const ANNUAL_INCOME_ANALYTICS_TITLE = "収益内訳(年間)";
export const ANNUAL_INCOME_EXPENDITURE_TITLE = "今年の収支";
export const ANNUAL_INCOME_TITLE = "今年の収入";
export const ANNUAL_EXPENDITURE_TITLE = "今年の支出";
export const MONTHLY_INCOME_EXPENDITURE_TITLE = "今月の収支";
export const INCOME_EXPENDITURE_TITLE = "収支";
export const MONTHLY_INCOME_TITLE = "今月の収入";
export const THIS_MONTH_INCOME_TITLE = `${getCurrentMonth()}月の収入`;
export const MONTHLY_EXPENDITURE_TITLE = "今月の支出";
export const THIS_MONTH_EXPENDITURE_TITLE = `${getCurrentMonth()}月の支出`;
export const MONTHLY_PROFIT_TITLE = "今月の利益";
export const THIS_MONTH_PROFIT_TITLE = `${getCurrentMonth()}月の利益`;
export const INCOME_TITLE = "収入";
export const EXPENDITURE_TITLE = "支出";
export const PROFIT_TITLE = "利益";
export const PROFIT_RATE_TITLE = "利益率";
export const PROFIT_TRENDS_TITLE = "利益推移";
export const TARGET_ACHIVEMENT_RATE_TITLE = "目標達成率";
export const TARGET_INCOME_ACHIVEMENT_RATE_TITLE = "目標達成率(収入)";

/**s
 * person-hours
 */
export const PERSON_HOURS_TITLE = "工数";
export const PERSON_HOURS_RATIO_LAST_MONTH_TITLE = `工数比率(${getLastMonth()}月)`;
export const ANNUAL_PERSON_HOURS_RATIO_TITLE = "工数比率(年間)";
export const PROJECTS_USE_MANY_PERSON_HOURS_LAST_MONTH_TITLE = `工数TOP5(${getLastMonth()}月)`;
export const ANNUAL_PROJECTS_USE_MANY_PERSON_HOURS_TITLE = "工数TOP5(年間)";
export const WORKING_HOURS_LAST_MONTH_TITLE = `${getLastMonth()}月の労働時間`;
export const ANNUAL_WORKING_HOURS_TITLE = "年間労働時間";
export const WORKING_DAYS_TITLE = "労働日数";
export const WORKING_HOURS_TITLE = "労働時間";
export const NUMBER_OF_PROJECTS_LAST_MONTH_TITLE = `${getLastMonth()}月のプロジェクト数`;
export const WORK_HOURS_AND_INCOME_TRENDS_TITLE = "労働時間・収益推移";
export const REGISTER_PERSON_HOURS_TITLE = "工数を登録";
export const PERSON_HOURS_HISTORY_TITLE = "工数履歴";

/**
 * clients
 */
export const CLIENT_TITLE = "クライアント";
export const CLIENTS_IN_PROGRESS_TITLE = "進行中のクライアント";
export const BUSINESS_MEETINGS_IN_PROGRESS_TITLE = "進行中の商談";
export const ANNUAL_CLOSING_RATE_TITLE = "年間成約率";
export const NUMBER_OF_CLIENTS_TITLE = "クライアント数";
export const CONTRACT_CONTINUATION_RATE_TITLE = "契約継続率";
export const CLIENT_LIST_TITLE = "クライアントリスト";
export const CLIENTS_TO_START_CONTRACT_THIS_MONTH_TITLE =
  "今月契約開始するクライアント";
export const CLIENTS_TO_END_CONTRACT_THIS_MONTH_TITLE =
  "今月契約終了するクライアント";
export const ADD_CLIENTS_TITLE = "クライアントを追加";
export const MANAGE_BUSINESS_MEETINGS_TITLE = "商談管理";

/**
 * schedule
 */
export const SCHEDULE_TITLE = "スケジュール";

/**
 * tasks
 */
export const TASK_TITLE = "タスク";
export const TASK_LIST_TITLE = "タスクリスト";
export const NOT_STARTED_TASKS_TITLE = "未着手のタスク";
export const DOING_TASKS_TITLE = "着手中のタスク";
export const COMPLETED_TASKS_TITLE = "完了したタスク";
export const ADD_TASKS_TITLE = "タスクを追加";
export const TASKS_MANAGEMENT_TITLE = "タスク管理";

/**
 * projects
 */
export const PROJECT_TITLE = "プロジェクト";
export const PROJECT_IN_PROGRESS_TITLE = "進行中のプロジェクト";
export const PROJECT_LIST_TITLE = "プロジェクトリスト";
export const DELAYED_PROJECTS_TITLE = "遅延中のプロジェクト";
export const PROJECTS_TO_START_THIS_MONTH_TITLE = "今月開始予定のプロジェクト";
export const PROJECTS_TO_END_THIS_MONTH_TITLE = "今月終了予定のプロジェクト";
export const ADD_PROJECTS_TITLE = "プロジェクトを追加";
export const PROJECTS_MANAGEMENT_TITLE = "プロジェクト管理";

/**
 * invoice
 */
export const INVOICE_TITLE = "請求書";
export const CREATED_INVOICE_LAST_MONTH_TITLE = `作成済みの請求書(${getLastMonth()}月分)`;
export const TOTAL_BILLING_AMOUNT_LAST_MONTH_TITLE = `合計請求金額(${getLastMonth()}月分)`;
export const CREATE_INVOICE_TITLE = "請求書作成";
export const CREATED_INVOICE_TITLE = "作成済みの請求書";
export const SETTING_INVOICE_FORMAT_TITLE = "請求書フォーマット設定";
export const INVOICE_MANAGEMENT_TITLE = "請求書管理";

/**
 * settings
 */
export const SETTING_TITLE = "設定";
export const USER_INFORMATION_TITLE = "ユーザー情報";
export const PASSWORD_TITLE = "パスワード";
export const BUSINESS_INFORMATION_TITLE = "事業情報";
export const FINANCE_INFORMATION_TITLE = "収支情報";
export const PERSON_HOURS_MANAGEMENT_TITLE = "工数管理";
export const CLIENT_INFORMATION_TITLE = "クライアント情報";
export const PROJECT_INFORMATION_TITLE = "プロジェクト情報";
export const TASK_INFORMATION_TITLE = "タスク情報";
