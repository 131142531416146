/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { css, Interpolation, Theme } from "@emotion/react";
import { formatWesternCalendar } from "../../../lib/formatter";

interface EndDateProps {
  endDate: Date;
  width?: string;
  fontSize?: string;
  style?: Interpolation<Theme>;
}

const EndDate: FC<EndDateProps> = memo(
  ({ endDate, width = "100%", fontSize = "14px", style }) => {
    const container = css`
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: ${width};
    `;
    const textStyle = css`
      font-size: ${fontSize};
    `;

    return (
      <div css={[container, style]}>
        <div css={textStyle}>~ {formatWesternCalendar(endDate)}</div>
      </div>
    );
  }
);

export default EndDate;
