/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { css } from "@emotion/react";
import BasicButton from "../../atoms/buttons/basic-button";
import {
  DEFAULT_CANCEL_BUTTON_COLOR,
  DEFAULT_OK_BUTTON_COLOR,
  WHITE_COLOR,
} from "../../../constants/style/color.constant";

interface ConfirmButtonsProps {
  captionOK?: string;
  captionCancel?: string;
  colorOK?: string;
  colorCancel?: string;
  width?: string;
  height?: string;
  padding?: string;
  margin?: string;
  buttonWidth?: string;
  onClickOKButton: () => void;
  onClickCancelButton: () => void;
}

const ConfirmButtons: FC<ConfirmButtonsProps> = memo(
  ({
    captionOK = "OK",
    captionCancel = "キャンセル",
    colorOK = DEFAULT_OK_BUTTON_COLOR,
    colorCancel = DEFAULT_CANCEL_BUTTON_COLOR,
    width = "100%",
    height = "auto",
    padding,
    margin = "0px",
    buttonWidth = "130px",
    onClickOKButton,
    onClickCancelButton,
  }) => {
    const buttonWrapper = css`
      width: ${width};
      height: ${height};
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: ${margin};
    `;

    return (
      <div css={buttonWrapper}>
        <BasicButton
          width={buttonWidth}
          caption={captionOK}
          onClickButton={onClickOKButton}
          color={WHITE_COLOR}
          backgroundColor={colorOK}
          padding={padding}
        />
        <BasicButton
          width={buttonWidth}
          caption={captionCancel}
          onClickButton={onClickCancelButton}
          color={WHITE_COLOR}
          backgroundColor={colorCancel}
          padding={padding}
        />
      </div>
    );
  }
);

export default ConfirmButtons;
