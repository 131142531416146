import { FC } from "react";
import Schedule from "../../../components/template/schedule/schedule";
import { CALENDAR_EVENT_COLORS } from "../../../constants/style/color.constant";

const SchedulePage: FC = () => {
  return (
    <Schedule
      numberOfProjectsToStartThisMonth={3}
      numberOfProjectsToEndThisMonth={4}
      scheduleData={[
        {
          title: "リポジトリ分割",
          start: "2024-04-20",
          end: "2024-05-10",
          description: "フロントエンド、バックエンドのリポジトリ分割",
          backgroundColor: CALENDAR_EVENT_COLORS.PROJECT,
          borderColor: CALENDAR_EVENT_COLORS.PROJECT,
          allDay: true,
        },
        {
          title: "FE単体テスト",
          start: "2024-04-28",
          end: "2024-05-08",
          description: "フロントエンドの単体テスト",
          backgroundColor: CALENDAR_EVENT_COLORS.PROJECT,
          borderColor: CALENDAR_EVENT_COLORS.PROJECT,
          allDay: true,
        },
        {
          title: "サンプルMTG A",
          start: "2024-05-02T12:30:00",
          end: "2024-05-02T13:30:00",
          description: "サンプル",
          backgroundColor: CALENDAR_EVENT_COLORS.MEETING,
          borderColor: CALENDAR_EVENT_COLORS.MEETING,
          allDay: false,
        },
        {
          title: "ディナー",
          start: "2024-05-03T19:00:00",
          end: "2024-05-03T21:30:00",
          description: "サンプル",
          backgroundColor: CALENDAR_EVENT_COLORS.PRIVATE,
          borderColor: CALENDAR_EVENT_COLORS.PRIVATE,
          allDay: false,
        },
        {
          title: "旅行",
          start: "2024-05-07",
          end: "2024-05-10",
          description: "サンプル",
          backgroundColor: CALENDAR_EVENT_COLORS.PRIVATE,
          borderColor: CALENDAR_EVENT_COLORS.PRIVATE,
          allDay: true,
        },
        {
          title: "請求書作成",
          start: "2024-05-01",
          end: "2024-05-3",
          description: "サンプル",
          backgroundColor: CALENDAR_EVENT_COLORS.TASK,
          borderColor: CALENDAR_EVENT_COLORS.TASK,
          allDay: true,
        },
        {
          title: "出張",
          start: "2024-05-17",
          end: "2024-05-20",
          description: "サンプル",
          backgroundColor: CALENDAR_EVENT_COLORS.OTHER,
          borderColor: CALENDAR_EVENT_COLORS.OTHER,
          allDay: true,
        },
      ]}
    />
  );
};

export default SchedulePage;
