/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import UserIcon from "../../../atoms/icon/user/user-icon";
import { PROFILE_TEXT_COLOR } from "../../../../constants/style/color.constant";
import { css } from "@emotion/react";

interface UserProfileProps {
  userName: string;
  fontSize?: string;
}

const UserProfile: FC<UserProfileProps> = memo(
  ({ userName, fontSize = "15px" }) => {
    const textStyle = css`
      font-size: ${fontSize};
      color: ${PROFILE_TEXT_COLOR};
    `;

    return (
      <div css={container}>
        <UserIcon iconSize={45} />
        <p css={textStyle}>{userName}</p>
      </div>
    );
  }
);

export default UserProfile;

const container = css`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
`;
