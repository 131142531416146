/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { css, Interpolation, Theme } from "@emotion/react";
import { DEFAULT_TEXT_COLOR } from "../../../../constants/style/color.constant";

interface NumberProps {
  value: number;
  fontSize?: string;
  color?: string;
  isBold?: boolean;
  withComma?: boolean;
  style?: Interpolation<Theme>;
}

const Number: FC<NumberProps> = memo(
  ({
    value,
    fontSize = "16px",
    color = { DEFAULT_TEXT_COLOR },
    isBold = false,
    withComma = false,
    style,
  }) => {
    const numberStyle = css`
      font-size: ${fontSize};
      color: ${color};
    `;

    return isBold ? (
      <b css={[numberStyle, style]}>
        {withComma ? value.toLocaleString() : value}
      </b>
    ) : (
      <p css={[numberStyle, style]}>
        {withComma ? value.toLocaleString() : value}
      </p>
    );
  }
);

export default Number;
