import { FC, memo } from "react";
import { ProjectInformation } from "../../../types/projects/projects.type";
import ProjectInProgressList from "../../organisms/list/projects/project-in-progress-list";
import TemplateContainer from "../../atoms/container/template-container";
import {
  DEFAULT_PAGE_WIDTH,
  DEFAULT_SUMMARY_MARGIN,
} from "../../../constants/style/size.constant";
import QuickStartCard from "../../molecules/cards/quick-start-card/quick-start-card";
import {
  QUICK_START_CARD_COLORS,
  PROJECT_SUMMARY_COLOR,
  WHITE_COLOR,
} from "../../../constants/style/color.constant";
import InformationFrame from "../../molecules/frame/information/information-frame";
import AllProjectList from "../../organisms/list/projects/all-project-list";
import {
  PROJECT_TITLE,
  PROJECT_IN_PROGRESS_TITLE,
  PROJECT_LIST_TITLE,
  DELAYED_PROJECTS_TITLE,
  PROJECTS_TO_END_THIS_MONTH_TITLE,
  ADD_PROJECTS_TITLE,
  PROJECTS_MANAGEMENT_TITLE,
} from "../../../constants/title/title.constant";
import SummaryFrame from "../../molecules/frame/summary/summary-frame";
import IndicatorCard from "../../molecules/cards/indicator-card/indicator-card";
import { KEN_UNIT } from "../../../constants/unit/unit.constant";
import { QUICK_START_ICON } from "../../../constants/element/element.constant";
import {
  PROJECT_PROGRESS_STATUS,
  PROJECT_STATUS,
} from "../../../constants/status/status.constant";

interface ProjectsProps {
  projectData: ProjectInformation[];
  projectsToEndThisMonth: number;
  onClickAddProjects: () => void;
  onClickProjectsManagement: () => void;
}

const Projects: FC<ProjectsProps> = memo(
  ({
    projectData,
    projectsToEndThisMonth, // TODO: フロントで計算できるようにする
    onClickAddProjects,
    onClickProjectsManagement,
  }) => {
    const projectInProgress = projectData.filter(
      (project) => project.projectStatus === PROJECT_STATUS.PROGRESS
    ).length;

    const delayedProjects = projectData.filter(
      (project) =>
        project.projectProgressStatus === PROJECT_PROGRESS_STATUS.BEHIND
    ).length;

    return (
      <TemplateContainer width={DEFAULT_PAGE_WIDTH}>
        <SummaryFrame
          title={PROJECT_TITLE}
          backgroundColor={PROJECT_SUMMARY_COLOR}
          gradation={true}
          titleColor={WHITE_COLOR}
        >
          <InformationFrame
            titleColor={WHITE_COLOR}
            title={PROJECT_IN_PROGRESS_TITLE}
            margin={DEFAULT_SUMMARY_MARGIN}
            textAlign="center"
          >
            <IndicatorCard
              indicatorValue={projectInProgress}
              indicatorUnit={KEN_UNIT}
              color={WHITE_COLOR}
            />
          </InformationFrame>

          <InformationFrame
            titleColor={WHITE_COLOR}
            title={DELAYED_PROJECTS_TITLE}
            margin={DEFAULT_SUMMARY_MARGIN}
            textAlign="center"
          >
            <IndicatorCard
              indicatorValue={delayedProjects}
              indicatorUnit={KEN_UNIT}
              color={WHITE_COLOR}
            />
          </InformationFrame>

          <InformationFrame
            titleColor={WHITE_COLOR}
            title={PROJECTS_TO_END_THIS_MONTH_TITLE}
            margin={DEFAULT_SUMMARY_MARGIN}
            textAlign="center"
          >
            <IndicatorCard
              indicatorValue={projectsToEndThisMonth}
              indicatorUnit={KEN_UNIT}
              color={WHITE_COLOR}
            />
          </InformationFrame>
        </SummaryFrame>

        <InformationFrame direction="row" gap="20px">
          <QuickStartCard
            boldText={ADD_PROJECTS_TITLE}
            lineColor={QUICK_START_CARD_COLORS[5]}
            flexGrow={1}
            icon={QUICK_START_ICON.ADD}
            onClickQuickStart={onClickAddProjects}
          />

          <QuickStartCard
            boldText={PROJECTS_MANAGEMENT_TITLE}
            lineColor={QUICK_START_CARD_COLORS[0]}
            flexGrow={1}
            icon={QUICK_START_ICON.MANAGEMENT}
            onClickQuickStart={onClickProjectsManagement}
          />
        </InformationFrame>

        <ProjectInProgressList
          projectData={projectData}
          title={PROJECT_IN_PROGRESS_TITLE}
        />

        <AllProjectList projectData={projectData} title={PROJECT_LIST_TITLE} />
      </TemplateContainer>
    );
  }
);

export default Projects;
