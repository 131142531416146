import FinancePage from "../../page/auth/finance/finance-page";
import InvoicePage from "../../page/auth/invoice/invoice-page";
import ProjectsPage from "../../page/auth/projects/projects-page";
import SchedulePage from "../../page/auth/schedule/schedule-page";
import TasksPage from "../../page/auth/tasks/tasks-page";
import PersonHoursPage from "../../page/auth/person-hours/person-hours-page";
import ClientsPage from "../../page/auth/clients/clients-page";
import SettingsPage from "../../page/auth/settings/settings-page";
import {
  FINANCE,
  CLIENTS,
  INVOICE,
  PERSON_HOURS,
  PROJECTS,
  SCHEDULE,
  TASKS,
  SETTINGS,
} from "../../constants/path/path.constant";

export const authRouterList = [
  {
    path: FINANCE,
    element: <FinancePage />,
  },
  {
    path: CLIENTS,
    element: <ClientsPage />,
  },
  {
    path: INVOICE,
    element: <InvoicePage />,
  },
  {
    path: PERSON_HOURS,
    element: <PersonHoursPage />,
  },
  {
    path: PROJECTS,
    element: <ProjectsPage />,
  },
  {
    path: SCHEDULE,
    element: <SchedulePage />,
  },
  {
    path: TASKS,
    element: <TasksPage />,
  },
  // {
  //   path: ADD_TASKS,
  //   element: <AddTasksPage />,
  // },
  {
    path: SETTINGS,
    element: <SettingsPage />,
  },
];
