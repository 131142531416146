/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { css, Interpolation, Theme } from "@emotion/react";
import {
  DEFAULT_BUTTON_COLOR,
  DEFAULT_BUTTON_BG_COLOR,
} from "../../../constants/style/color.constant";

interface BasicButtonProps {
  caption: string;
  backgroundColor?: string;
  color?: string;
  width?: string;
  height?: string;
  fontSize?: string;
  borderRadius?: string;
  border?: string;
  padding?: string;
  style?: Interpolation<Theme>;
  hoverAction?: boolean;
  onClickButton: () => void;
}

const BasicButton: FC<BasicButtonProps> = memo(
  ({
    caption,
    backgroundColor = DEFAULT_BUTTON_BG_COLOR,
    color = DEFAULT_BUTTON_COLOR,
    width = "auto",
    height = "auto",
    padding = "5px 15px",
    border = "none",
    fontSize = "14px",
    borderRadius = "10px",
    hoverAction = true,
    style,
    onClickButton,
  }) => {
    const container = css`
      background-color: ${backgroundColor};
      color: ${color};
      width: ${width};
      height: ${height};
      font-size: ${fontSize};
      padding: ${padding};
      border: ${border};
      border-radius: ${borderRadius};
    `;

    const hoverStyle = css`
      transition: 0.5s;

      :hover {
        opacity: 0.7;
      }
    `;

    return (
      <button
        css={[container, style, hoverAction ? hoverStyle : null]}
        onClick={onClickButton}
      >
        <b>{caption}</b>
      </button>
    );
  }
);

export default BasicButton;
