import { FC, useCallback } from "react";
import Clients from "../../../components/template/clients/clients";

const ClientsPage: FC = () => {
  const onClickAddClients = useCallback(() => {}, []);

  const onClickManageBusinessMeeting = useCallback(() => {}, []);
  return (
    <Clients
      numberOfClientsInProgress={3}
      numberOfBusinessMeetingsInProgress={2}
      annualClosingRate={73.5}
      contractContinuationRate={55.3}
      clients={[
        {
          companyName: "シンプレクス・ホールディングス株式会社",
          contractStartDate: "2023-04-01",
          contractEndDate: "2024-03-31",
          contractStatus: 1,
        },
        {
          companyName: "LINEヤフー株式会社",
          contractStartDate: "2022-10-01",
          contractEndDate: "2024-01-31",
          contractStatus: 1,
        },
        {
          companyName: "株式会社ミロク情報サービス",
          contractStartDate: "2023-09-01",
          contractEndDate: "2024-02-28",
          contractStatus: 1,
        },
        {
          companyName: "オービック株式会社",
          contractStartDate: "2019-04-01",
          contractEndDate: "2024-03-31",
          contractStatus: 0,
        },
      ]}
      onClickAddClients={onClickAddClients}
      onClickManageBusinessMeeting={onClickManageBusinessMeeting}
    />
  );
};

export default ClientsPage;
