/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { css } from "@emotion/react";
import Title from "../../../atoms/characters/title/title";
import { TITLE_SIZE } from "../../../../constants/element/element.constant";
import { DEFAULT_TEXT_COLOR } from "../../../../constants/style/color.constant";

interface HeaderTitleProps {
  color?: string;
  backgroundColor?: string;
  width?: string;
  height?: string;
  margin?: string;
  padding?: string;
  title: string;
  fontSize?: string;
}

const HeaderTitle: FC<HeaderTitleProps> = memo(
  ({
    color = DEFAULT_TEXT_COLOR,
    backgroundColor = "transparent",
    width = "90%",
    height = "auto",
    margin = "10px auto",
    padding = "0",
    title,
    fontSize,
  }) => {
    const container = css`
      background-color: ${backgroundColor};
      width: ${width};
      height: ${height};
      margin: ${margin};
      padding: ${padding};
    `;

    return (
      <div css={container}>
        <Title
          color={color}
          text={title}
          titleSize={TITLE_SIZE.LARGE}
          fontSize={fontSize}
        />
      </div>
    );
  }
);

export default HeaderTitle;
