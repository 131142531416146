import { DataType } from "../types/index.type";

/**
 * 円表示関数
 * @param value 
 * @returns string
 */
export const formatYen = (value: number) => {
  return `${value.toLocaleString()}円`;
};

/**
 * 西暦表示関数
 * @param date 
 * @returns string
 */
export const formatWesternCalendar = (date: Date): string => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}/${month}/${day}`;
};

/**
 * %表示関数
 * @param targetValue 
 * @param totalValue
 * @returns string
 */
export const formatPercent = (value: number) => {
  return `${value}%`;
};

export const formatMonth = (value: number) => {
  return `${value}月`;
};

/**
 * データタイプに応じて、数字をフォーマットする関数
 * @param value 
 * @param dataType 
 * @returns string
 */
export const formatByDataType = (value: number, dataType: DataType | undefined) => {
  switch (dataType) {
    case "money":
      return formatYen(value);
    case "percent":
      return formatPercent(value);
    case "month":
      return formatMonth(value);
    default:
      return String(value);
  }
};

/**
 * カンマをつけて返却
 * @param value 
 * @returns string
 */
export const formatWithComma = (value: number): string => {
  return value.toLocaleString();
};

/**
 * 値と単位を結合した値を返却
 * @param value 
 * @param unit 
 * @returns string
 */
export const formatValueWithUnit = (value: number, unit: string) => {
  return `${value}${unit}`;
};

export const removeHash = (hashName: string): string => {
  return hashName.replace(/#/g, '');
}