import { InvoiceManagementData } from "../../types/invoice/invoice.type";
import { INVOICE_MANAGEMENT_STATUS } from "../status/status.constant";
import { DEMO_COMPANIES } from "./common.constant";
import { DEMO_MONTHLY_INCOME_ANALYTICS_DATA } from "./finance.constant";

export const DEMO_INVOICE_DATA = {
  ISSUED_INVOICE_LAST_MONTH: 3,
  TOTAL_BILLING_AMOUNT_LAST_MONTH: DEMO_MONTHLY_INCOME_ANALYTICS_DATA[0].incomeAmount + DEMO_MONTHLY_INCOME_ANALYTICS_DATA[1].incomeAmount + DEMO_MONTHLY_INCOME_ANALYTICS_DATA[2].incomeAmount,
};

export const DEMO_INVOICE_MANAGEMENT_DATA: InvoiceManagementData[] = [
  {
    companyName: DEMO_COMPANIES.A,
    status: INVOICE_MANAGEMENT_STATUS.SENT,
    billingAmount: DEMO_MONTHLY_INCOME_ANALYTICS_DATA[0].incomeAmount
  },
  {
    companyName: DEMO_COMPANIES.B,
    status: INVOICE_MANAGEMENT_STATUS.CREATED,
    billingAmount: DEMO_MONTHLY_INCOME_ANALYTICS_DATA[1].incomeAmount
  },
  {
    companyName: DEMO_COMPANIES.C,
    status: INVOICE_MANAGEMENT_STATUS.CREATED,
    billingAmount: DEMO_MONTHLY_INCOME_ANALYTICS_DATA[2].incomeAmount
  }
];

