/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { css, Interpolation, Theme } from "@emotion/react";
import { MEDIA_QUERY } from "../../../constants/style/media.constant";

interface MenuButtonProps {
  onClickMenuButton: () => void;
  disabledFlg: boolean;
  style?: Interpolation<Theme>;
}

const MenuButton: FC<MenuButtonProps> = memo(
  ({ onClickMenuButton, disabledFlg, style }) => {
    return (
      <button
        css={[container, disabledFlg ? disabled : active, style]}
        onClick={onClickMenuButton}
      >
        MENU
      </button>
    );
  }
);

export default MenuButton;

const container = css`
  border: none;
  border-radius: 35px;
  color: #ffffff;
  width: 140px;
  height: 40px;
  transition: 0.7s;

  ${MEDIA_QUERY.SP} {
    width: 110px;
    height: 35px;
  }
`;

const active = css`
  background-color: #5d5aa8;
  &:hover {
    background-color: #30316e;
  }
`;

const disabled = css`
  background-color: #3a3a3a;
`;
