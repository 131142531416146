import { FC, useCallback } from "react";
import Projects from "../../../../components/template/projects/projects";
import { DEMO_PROJECTS_DATA } from "../../../../constants/demo/projects.constant";
import { useDialog } from "../../../../hooks/use-dialog";
import { NOT_AVAILABLE_IN_THE_DEMO } from "../../../../constants/message/dialog-message";

const DemoProjectsPage: FC = () => {
  const { openDialog } = useDialog();

  const onClickNoAvailable = useCallback(() => {
    openDialog(NOT_AVAILABLE_IN_THE_DEMO, "caution");
  }, [openDialog]);

  return (
    <Projects
      projectData={DEMO_PROJECTS_DATA}
      projectsToEndThisMonth={0}
      onClickAddProjects={onClickNoAvailable}
      onClickProjectsManagement={onClickNoAvailable}
    />
  );
};

export default DemoProjectsPage;
