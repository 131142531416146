import { FC, useCallback } from "react";
import PersonHours from "../../../../components/template/person-hours/person-hours";
import {
  DEMO_ANNUAL_PRESON_HOURS_DATA,
  DEMO_LAST_MONTH_PRESON_HOURS_DATA,
  DEMO_PERSON_HOURS_DATA,
  DEMO_WORK_HOURS_AND_INCOME_DATA,
} from "../../../../constants/demo/person-hours.constant";
import { useDialog } from "../../../../hooks/use-dialog";
import { NOT_AVAILABLE_IN_THE_DEMO } from "../../../../constants/message/dialog-message";

const DemoPersonHoursPage: FC = () => {
  const { openDialog } = useDialog();

  const onClickNoAvailable = useCallback(() => {
    openDialog(NOT_AVAILABLE_IN_THE_DEMO, "caution");
  }, [openDialog]);

  return (
    <PersonHours
      annualWorkingDays={DEMO_PERSON_HOURS_DATA.ANNUAL_WORKING_DAYS}
      annualWorkingHours={DEMO_PERSON_HOURS_DATA.ANNUAL_WORKING_HOURS}
      lastMonthWorkingDays={DEMO_PERSON_HOURS_DATA.LAST_MONTH_WORKING_DAYS}
      lastMonthWorkingHours={DEMO_PERSON_HOURS_DATA.LAST_MONTH_WORKING_HOURS}
      personHoursLastMonthData={DEMO_LAST_MONTH_PRESON_HOURS_DATA}
      annualPersonHoursData={DEMO_ANNUAL_PRESON_HOURS_DATA}
      workHoursAndIncomeData={DEMO_WORK_HOURS_AND_INCOME_DATA}
      onClickRegisterPersonHours={onClickNoAvailable}
      onClickPersonHoursHistory={onClickNoAvailable}
    />
  );
};

export default DemoPersonHoursPage;
