import { FC, useCallback } from "react";
import Tasks from "../../../../components/template/tasks/tasks";
import { useNavigate } from "react-router-dom";
import { ADD_TASKS } from "../../../../constants/path/path.constant";
import { DEMO_TASK_DATA } from "../../../../constants/demo/tasks.constant";
import { useDialog } from "../../../../hooks/use-dialog";
import { NOT_AVAILABLE_IN_THE_DEMO } from "../../../../constants/message/dialog-message";

const DemoTasksPage: FC = () => {
  const navigate = useNavigate();

  const { openDialog } = useDialog();

  const onClickNoAvailable = useCallback(() => {
    openDialog(NOT_AVAILABLE_IN_THE_DEMO, "caution");
  }, [openDialog]);

  // const onClickAddTasks = () => {
  //   navigate(ADD_TASKS);
  // };

  const onClickTasksManagement = () => {};

  const onClickDeleteTask = (taslId: number) => {};

  return (
    <Tasks
      onClickAddTasks={onClickNoAvailable}
      onClickDeleteTask={onClickNoAvailable}
      onClickTasksManagement={onClickNoAvailable}
      taskData={DEMO_TASK_DATA}
    />
  );
};

export default DemoTasksPage;
