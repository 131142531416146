/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { css, Interpolation, Theme } from "@emotion/react";
import Text from "../../atoms/characters/text/text";

interface LabelProps {
  width?: string;
  height?: string;
  padding?: string;
  margin?: string;
  textAlign?: string;
  text: string;
  style?: Interpolation<Theme>;
}

const Label: FC<LabelProps> = memo(
  ({
    width = "auto",
    height = "auto",
    padding = "10px",
    margin = "0",
    textAlign = "center",
    text,
    style,
  }) => {
    const container = css`
      width: ${width};
      height: ${height};
      padding: ${padding};
      margin: ${margin};
      text-align: ${textAlign};
    `;

    return (
      <div css={[container, style]}>
        <Text text={text} />
      </div>
    );
  }
);

export default Label;
