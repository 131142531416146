import { FC, useCallback } from "react";
import Clients from "../../../../components/template/clients/clients";
import { DEMO_CLIENTS_DATA } from "../../../../constants/demo/clients.constant";
import { CONTRACT_STATUS } from "../../../../constants/status/status.constant";
import { useDialog } from "../../../../hooks/use-dialog";
import { NOT_AVAILABLE_IN_THE_DEMO } from "../../../../constants/message/dialog-message";

const DemoClientsPage: FC = () => {
  const { openDialog } = useDialog();

  const onClickNoAvailable = useCallback(() => {
    openDialog(NOT_AVAILABLE_IN_THE_DEMO, "caution");
  }, [openDialog]);

  return (
    <Clients
      numberOfClientsInProgress={
        DEMO_CLIENTS_DATA.filter(
          (client) => client.contractStatus === CONTRACT_STATUS.UNDER_CONTRACT
        ).length
      }
      numberOfBusinessMeetingsInProgress={2}
      annualClosingRate={73.5}
      contractContinuationRate={55.3}
      clients={DEMO_CLIENTS_DATA}
      onClickAddClients={onClickNoAvailable}
      onClickManageBusinessMeeting={onClickNoAvailable}
    />
  );
};

export default DemoClientsPage;
