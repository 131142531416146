import { FC } from "react";
import Settings from "../../../components/template/settings/settings";

const SettingsPage: FC = () => {
  const onClickSetUserInformation = () => {};

  const onClickSetPassword = () => {};

  const onClickSetBusinessInformation = () => {};

  const onClickSetFinanceInformation = () => {};

  const onClickSetPersonHoursManagement = () => {};

  const onClickSetClientInformation = () => {};

  const onClickSetProjectInformation = () => {};

  const onClickSetTaskInformation = () => {};

  return (
    <Settings
      onClickSetUserInformation={onClickSetUserInformation}
      onClickSetPassword={onClickSetPassword}
      onClickSetBusinessInformation={onClickSetBusinessInformation}
      onClickSetFinanceInformation={onClickSetFinanceInformation}
      onClickSetPersonHoursManagement={onClickSetPersonHoursManagement}
      onClickSetClientInformation={onClickSetClientInformation}
      onClickSetProjectInformation={onClickSetProjectInformation}
      onClickSetTaskInformation={onClickSetTaskInformation}
    />
  );
};

export default SettingsPage;
