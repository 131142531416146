import { FC } from "react";
import Tasks from "../../../components/template/tasks/tasks";
import { useNavigate } from "react-router-dom";
import { ADD_TASKS } from "../../../constants/path/path.constant";

const TasksPage: FC = () => {
  const navigate = useNavigate();

  const onClickAddTasks = () => {
    // navigate(ADD_TASKS);
  };

  const onClickTasksManagement = () => {};

  const onClickDeleteTask = (taslId: number) => {};

  return (
    <Tasks
      onClickAddTasks={onClickAddTasks}
      onClickDeleteTask={onClickDeleteTask}
      onClickTasksManagement={onClickTasksManagement}
      taskData={[
        {
          id: 1,
          taskTitle: "確定申告",
          status: 1,
          priority: 0,
          dueDate: "2024-03-31",
        },
        {
          id: 2,
          taskTitle: "プロジェクトレポート提出",
          status: 2,
          priority: 1,
          dueDate: "2024-03-25",
        },
        {
          id: 3,
          taskTitle: "FE・テストコード修正",
          status: 2,
          priority: 2,
          dueDate: "2024-04-03",
        },
        {
          id: 4,
          taskTitle: "BE・ソースコードリファクタ、テストコード実装",
          status: 2,
          priority: 1,
          dueDate: "2024-05-01",
        },
        {
          id: 5,
          taskTitle: "外部設計書ブラッシュアップ",
          status: 1,
          priority: 0,
          dueDate: "2023-06-30",
        },
      ]}
    />
  );
};

export default TasksPage;
