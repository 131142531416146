/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { css } from "@emotion/react";
import ListFrame from "../../../molecules/frame/list/list-frame";
import ListCard from "../../../molecules/cards/list-card/list-card";
import { InvoiceManagementData } from "../../../../types/invoice/invoice.type";
import { NO_CLIENTS_REGISTERD_TO_SEND_INVOICE } from "../../../../constants/description/description.constant";
import StatusIcon from "../../../atoms/icon/status/status-icon";
import { INVOICE_MANAGEMENT_STATUS_LABEL } from "../../../../constants/label/label.constant";
import { applyColorToList } from "../../../../lib/style-composer";
import Label from "../../../molecules/label/label";
import { formatYen } from "../../../../lib/formatter";
import { MEDIA_QUERY } from "../../../../constants/style/media.constant";

interface InvoiceManagementListProps {
  invoiceManagementData: InvoiceManagementData[];
  fontSize?: string;
  width?: string;
  height?: string;
  maxWidth?: string;
  maxHeight?: string;
  title: string;
}

const InvoiceManagementList: FC<InvoiceManagementListProps> = memo(
  ({
    invoiceManagementData,
    fontSize = "15px",
    width,
    height,
    maxWidth,
    maxHeight,
    title,
  }) => {
    return (
      <ListFrame
        title={title}
        width={width}
        height={height}
        maxWidth={maxWidth}
        maxHeight={maxHeight}
      >
        {invoiceManagementData && invoiceManagementData.length !== 0 ? (
          invoiceManagementData.map((invoice, index) => (
            <ListCard
              key={index}
              title={invoice.companyName}
              fontSize={fontSize}
              color={applyColorToList(index)}
            >
              <StatusIcon
                status={invoice.status}
                statusLabelList={INVOICE_MANAGEMENT_STATUS_LABEL}
              />

              <Label
                text={formatYen(invoice.billingAmount)}
                width="50%"
                textAlign="right"
                style={labelWrapper}
              />
            </ListCard>
          ))
        ) : (
          <Label width="100%" text={NO_CLIENTS_REGISTERD_TO_SEND_INVOICE} />
        )}
      </ListFrame>
    );
  }
);

export default InvoiceManagementList;

const labelWrapper = css`
  ${MEDIA_QUERY.TABLET} {
    display: none;
  }
`;
