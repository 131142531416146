import { FC, useState } from "react";
import { UserProfileContext } from "../contexts/user-profile.context";
import { UserProfile } from "../types/profile/user.type";

interface UserProfileProviderProps {
  children: React.ReactNode;
}

const UserProfileProvider: FC<UserProfileProviderProps> = ({ children }) => {
  const [userProfile, setUserProfile] = useState<UserProfile>({
    userName: "test_user",
  });

  const updateUserProfile = (profile: UserProfile) => {
    setUserProfile(profile);
  };

  return (
    <UserProfileContext.Provider value={{ userProfile, updateUserProfile }}>
      {children}
    </UserProfileContext.Provider>
  );
};

export default UserProfileProvider;
