/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { NavLink } from "react-router-dom";
import { css } from "@emotion/react";
import { useLocation } from "react-router-dom";
import { Link as Scroll } from "react-scroll";
import { NavType } from "../../../../types/index.type";

interface MenuNavProps {
  icon: React.ReactNode;
  caption: string;
  path: string;
  navType?: NavType;
  onClickMenuNav: () => void;
}

const MenuNav: FC<MenuNavProps> = memo(
  ({ caption, path, icon, navType = "path", onClickMenuNav }) => {
    const pathname = useLocation().pathname;

    const generateNav = (navType: NavType) => {
      switch (navType) {
        case "path":
          return (
            <NavLink to={path}>
              <div
                css={[container, pathname === path ? active : null]}
                onClick={onClickMenuNav}
              >
                <div css={wrapper}>
                  {icon}
                  <b>{caption}</b>
                </div>
              </div>
            </NavLink>
          );

        case "id":
          return (
            <Scroll to={path} smooth={true}>
              <div css={container} onClick={onClickMenuNav}>
                <div css={wrapper}>
                  {icon}
                  <b>{caption}</b>
                </div>
              </div>
            </Scroll>
          );

        case "none":
          return (
            <div css={container} onClick={onClickMenuNav}>
              <div css={wrapper}>
                {icon}
                <b>{caption}</b>
              </div>
            </div>
          );

        default:
          return (
            <NavLink to={path}>
              <div
                css={[container, pathname === path ? active : null]}
                onClick={onClickMenuNav}
              >
                <div css={wrapper}>
                  {icon}
                  <b>{caption}</b>
                </div>
              </div>
            </NavLink>
          );
      }
    };

    return generateNav(navType);
  }
);

export default MenuNav;

const container = css`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 80%;
  height: 55px;
  border-radius: 20px;
  background-color: transparent;
  margin: 0px auto 2vh auto;
  border: none;
  color: #ffffff;
  transition: 0.7s;
  cursor: default;

  &:hover {
    background-color: #3c3aa6;
  }

  @media (max-height: 850px) {
    height: 55px;
  }

  @media (max-height: 750px) {
    height: 40px;
  }
`;

const wrapper = css`
  display: flex;
  gap: 15px;
  width: 200px;
  padding: 15px;
`;

const active = css`
  background-color: #474b9f;
`;
