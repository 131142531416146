export const TASK_STATUS = {
  DONE: 0,
  DOING: 1,
  NOT_START: 2,
};

export const CONTRACT_STATUS = {
  END: 0,
  UNDER_CONTRACT: 1,
  BEFORE_CONTRACT: 2,
};

export const PROJECT_STATUS = {
  END: 0,
  PROGRESS: 1,
  BEFORE_START: 2,
};

export const PROJECT_PROGRESS_STATUS = {
  ACCORDING_TO_SCHEDULE: 0,
  AHEAD: 1,
  BEHIND: 2,
};

export const INVOICE_MANAGEMENT_STATUS = {
  SENT: 0,
  CREATED: 1,
  NOT_CREATED: 2,
};

export const PRIORITY_STATUS = {
  LOW: 0,
  MIDDLE: 1,
  HIGH: 2,
};
