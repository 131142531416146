import { FC, memo } from "react";
import TemplateContainer from "../../atoms/container/template-container";
import SummaryFrame from "../../molecules/frame/summary/summary-frame";
import InformationFrame from "../../molecules/frame/information/information-frame";
import {
  SCHEDULE_TITLE,
  PROJECTS_TO_START_THIS_MONTH_TITLE,
  PROJECTS_TO_END_THIS_MONTH_TITLE,
} from "../../../constants/title/title.constant";
import {
  WHITE_COLOR,
  SCHEDULE_SUMMARY_COLOR,
} from "../../../constants/style/color.constant";
import IndicatorCard from "../../molecules/cards/indicator-card/indicator-card";
import { ScheduleData } from "../../../types/schedule/schedule.type";
import { KEN_UNIT } from "../../../constants/unit/unit.constant";
import Calendar from "../../organisms/calendar/calendar";
import {
  DEFAULT_PAGE_WIDTH,
  DEFAULT_SUMMARY_MARGIN,
} from "../../../constants/style/size.constant";

interface ScheduleProps {
  numberOfProjectsToStartThisMonth: number;
  numberOfProjectsToEndThisMonth: number;
  scheduleData: ScheduleData[];
}

const Schedule: FC<ScheduleProps> = memo(
  ({
    numberOfProjectsToStartThisMonth,
    numberOfProjectsToEndThisMonth,
    scheduleData,
  }) => {
    return (
      <TemplateContainer width={DEFAULT_PAGE_WIDTH}>
        <SummaryFrame
          title={SCHEDULE_TITLE}
          gradation={true}
          backgroundColor={SCHEDULE_SUMMARY_COLOR}
          titleColor={WHITE_COLOR}
        >
          <InformationFrame
            titleColor={WHITE_COLOR}
            title={PROJECTS_TO_START_THIS_MONTH_TITLE}
            margin={DEFAULT_SUMMARY_MARGIN}
          >
            <IndicatorCard
              indicatorValue={numberOfProjectsToStartThisMonth}
              indicatorUnit={KEN_UNIT}
              color={WHITE_COLOR}
            />
          </InformationFrame>

          <InformationFrame
            titleColor={WHITE_COLOR}
            title={PROJECTS_TO_END_THIS_MONTH_TITLE}
            margin={DEFAULT_SUMMARY_MARGIN}
          >
            <IndicatorCard
              indicatorValue={numberOfProjectsToEndThisMonth}
              indicatorUnit={KEN_UNIT}
              color={WHITE_COLOR}
            />
          </InformationFrame>
        </SummaryFrame>

        <Calendar
          scheduleData={scheduleData}
          height="75vh"
          minHeight={"500px"}
        />
      </TemplateContainer>
    );
  }
);

export default Schedule;
