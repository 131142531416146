/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { css } from "@emotion/react";
import userProfile from "../../../../assets/images/user-icon.png";
import { MEDIA_QUERY } from "../../../../constants/style/media.constant";
import { resizePCtoSP } from "../../../../lib/style-composer";

interface UserIconProps {
  iconSize: number;
}

const UserIcon: FC<UserIconProps> = memo(({ iconSize }) => {
  const container = css`
    border-radius: 50%;
    width: ${iconSize}px;
    height: ${iconSize}px;

    ${MEDIA_QUERY.SP} {
      width: ${resizePCtoSP(iconSize)}px;
      height: ${resizePCtoSP(iconSize)}px;
    }
  `;

  return (
    <div css={container}>
      <img src={userProfile} alt="user icon" css={imageWrapper} />
    </div>
  );
});

export default UserIcon;

const imageWrapper = css`
  width: 100%;
  height: 100%;
`;
