import { FC, memo } from "react";
import TemplateContainer from "../../atoms/container/template-container";
import SummaryFrame from "../../molecules/frame/summary/summary-frame";
import InformationFrame from "../../molecules/frame/information/information-frame";
import BasicCard from "../../molecules/cards/basic-card/basic-card";
import {
  PERSON_HOURS_TITLE,
  ANNUAL_WORKING_HOURS_TITLE,
  WORKING_HOURS_LAST_MONTH_TITLE,
  PROJECTS_USE_MANY_PERSON_HOURS_LAST_MONTH_TITLE,
  ANNUAL_PROJECTS_USE_MANY_PERSON_HOURS_TITLE,
  WORKING_DAYS_TITLE,
  WORKING_HOURS_TITLE,
  PERSON_HOURS_HISTORY_TITLE,
  PERSON_HOURS_RATIO_LAST_MONTH_TITLE,
  ANNUAL_PERSON_HOURS_RATIO_TITLE,
  WORK_HOURS_AND_INCOME_TRENDS_TITLE,
  REGISTER_PERSON_HOURS_TITLE,
} from "../../../constants/title/title.constant";
import {
  WHITE_COLOR,
  PERSON_HOURS_SUMMARY_COLOR,
  QUICK_START_CARD_COLORS,
} from "../../../constants/style/color.constant";
import {
  DEFAULT_PAGE_WIDTH,
  DEFAULT_SUMMARY_MARGIN,
} from "../../../constants/style/size.constant";
import { PersonHoursData } from "../../../types/person-hours/person-hours.type";
import Text from "../../atoms/characters/text/text";
import { PERSON_HOURS_NOT_REGISTERD } from "../../../constants/description/description.constant";
import { formatValueWithUnit } from "../../../lib/formatter";
import {
  DAY_UNIT,
  HOUR_UNIT,
  PERCENT_UNIT,
} from "../../../constants/unit/unit.constant";
import QuickStartCard from "../../molecules/cards/quick-start-card/quick-start-card";
import ChartFrame from "../../molecules/frame/charts/chart-frame";
import PersonHoursRatio from "./charts/person-hours-ratio";
import DetailFrame from "../../molecules/frame/detail/detail-frame";
import { PERSON_HOURS_RATIO_DATA } from "../../../constants/charts/charts.constant";
import WorkHoursAndIncomeTrends from "./charts/work-hours-and-income-trends";
import { WorkHoursAndIncomeData } from "../../../types/person-hours/person-hours.type";
import { QUICK_START_ICON } from "../../../constants/element/element.constant";

interface PersonHoursProps {
  annualWorkingDays: number;
  annualWorkingHours: number;
  lastMonthWorkingDays: number;
  lastMonthWorkingHours: number;
  personHoursLastMonthData: PersonHoursData[];
  annualPersonHoursData: PersonHoursData[];
  workHoursAndIncomeData: WorkHoursAndIncomeData[];
  onClickRegisterPersonHours: () => void;
  onClickPersonHoursHistory: () => void;
}

const PersonHours: FC<PersonHoursProps> = memo(
  ({
    annualWorkingDays,
    annualWorkingHours,
    lastMonthWorkingDays,
    lastMonthWorkingHours,
    personHoursLastMonthData,
    annualPersonHoursData,
    workHoursAndIncomeData,
    onClickRegisterPersonHours,
    onClickPersonHoursHistory,
  }) => {
    // 工数の多いTOP5のプロジェクト(先月)
    const projectUseManyPersonHoursLastMonth = personHoursLastMonthData
      .sort((a, b) => b.personHoursRatio - a.personHoursRatio)
      .slice(0, 5);

    // 工数の多いTOP5のプロジェクト(今年)
    const annualProjectUseManyPersonHours = annualPersonHoursData
      .sort((a, b) => b.personHoursRatio - a.personHoursRatio)
      .slice(0, 5);

    return (
      <TemplateContainer width={DEFAULT_PAGE_WIDTH}>
        <SummaryFrame
          title={PERSON_HOURS_TITLE}
          backgroundColor={PERSON_HOURS_SUMMARY_COLOR}
          gradation={true}
          titleColor={WHITE_COLOR}
        >
          <InformationFrame
            title={PROJECTS_USE_MANY_PERSON_HOURS_LAST_MONTH_TITLE}
            fontSize="21px"
            titleColor={WHITE_COLOR}
            margin={DEFAULT_SUMMARY_MARGIN}
          >
            {projectUseManyPersonHoursLastMonth ? (
              projectUseManyPersonHoursLastMonth.map((project, index) => (
                <BasicCard
                  key={index}
                  title={project.projectName}
                  description={formatValueWithUnit(
                    project.personHoursRatio,
                    PERCENT_UNIT
                  )}
                  fontSize="18px"
                  color={WHITE_COLOR}
                />
              ))
            ) : (
              <Text text={PERSON_HOURS_NOT_REGISTERD} />
            )}
          </InformationFrame>

          <InformationFrame
            title={ANNUAL_PROJECTS_USE_MANY_PERSON_HOURS_TITLE}
            fontSize="21px"
            titleColor={WHITE_COLOR}
            margin={DEFAULT_SUMMARY_MARGIN}
          >
            {annualProjectUseManyPersonHours ? (
              annualProjectUseManyPersonHours.map((project, index) => (
                <BasicCard
                  key={index}
                  title={project.projectName}
                  description={formatValueWithUnit(
                    project.personHoursRatio,
                    PERCENT_UNIT
                  )}
                  fontSize="18px"
                  color={WHITE_COLOR}
                />
              ))
            ) : (
              <Text text={PERSON_HOURS_NOT_REGISTERD} />
            )}
          </InformationFrame>

          <InformationFrame
            title={WORKING_HOURS_LAST_MONTH_TITLE}
            fontSize="21px"
            titleColor={WHITE_COLOR}
            margin={DEFAULT_SUMMARY_MARGIN}
          >
            <BasicCard
              title={WORKING_DAYS_TITLE}
              description={formatValueWithUnit(lastMonthWorkingDays, DAY_UNIT)}
              fontSize="18px"
              color={WHITE_COLOR}
            />

            <BasicCard
              title={WORKING_HOURS_TITLE}
              description={formatValueWithUnit(
                lastMonthWorkingHours,
                HOUR_UNIT
              )}
              fontSize="18px"
              color={WHITE_COLOR}
            />
          </InformationFrame>

          <InformationFrame
            title={ANNUAL_WORKING_HOURS_TITLE}
            fontSize="21px"
            titleColor={WHITE_COLOR}
            margin={DEFAULT_SUMMARY_MARGIN}
          >
            <BasicCard
              title={WORKING_DAYS_TITLE}
              description={formatValueWithUnit(annualWorkingDays, DAY_UNIT)}
              fontSize="18px"
              color={WHITE_COLOR}
            />

            <BasicCard
              title={WORKING_HOURS_TITLE}
              description={formatValueWithUnit(annualWorkingHours, HOUR_UNIT)}
              fontSize="18px"
              color={WHITE_COLOR}
            />
          </InformationFrame>
        </SummaryFrame>

        <InformationFrame direction="row" gap="20px">
          <QuickStartCard
            boldText={REGISTER_PERSON_HOURS_TITLE}
            lineColor={QUICK_START_CARD_COLORS[1]}
            flexGrow={1}
            icon={QUICK_START_ICON.ADD}
            onClickQuickStart={onClickRegisterPersonHours}
          />

          <QuickStartCard
            boldText={PERSON_HOURS_HISTORY_TITLE}
            lineColor={QUICK_START_CARD_COLORS[7]}
            flexGrow={1}
            icon={QUICK_START_ICON.HISTORY}
            onClickQuickStart={onClickPersonHoursHistory}
          />
        </InformationFrame>

        <InformationFrame direction="row" gap="3%">
          <ChartFrame
            title={PERSON_HOURS_RATIO_LAST_MONTH_TITLE}
            width="450px"
            flexGrow={1}
            gap="3%"
          >
            <PersonHoursRatio
              data={personHoursLastMonthData}
              width="100%"
              height="100%"
              containerWidth="290px"
              containerHeight="250px"
              flexGrow={1}
            />

            <DetailFrame
              width="290px"
              contentWidth="90%"
              flexGrow={1}
              data={personHoursLastMonthData}
              nameKey={PERSON_HOURS_RATIO_DATA.NAME}
              valueKey={PERSON_HOURS_RATIO_DATA.VALUE}
              dataType="percent"
            />
          </ChartFrame>

          <ChartFrame
            title={ANNUAL_PERSON_HOURS_RATIO_TITLE}
            width="450px"
            flexGrow={1}
            gap="3%"
          >
            <PersonHoursRatio
              data={annualPersonHoursData}
              width="100%"
              height="100%"
              containerWidth="290px"
              containerHeight="250px"
              flexGrow={1}
            />

            <DetailFrame
              width="290px"
              contentWidth="90%"
              flexGrow={1}
              data={annualPersonHoursData}
              nameKey={PERSON_HOURS_RATIO_DATA.NAME}
              valueKey={PERSON_HOURS_RATIO_DATA.VALUE}
              dataType="percent"
            />
          </ChartFrame>
        </InformationFrame>

        <ChartFrame title={WORK_HOURS_AND_INCOME_TRENDS_TITLE} scrollX={true}>
          <WorkHoursAndIncomeTrends
            data={workHoursAndIncomeData}
            width="95%"
            height={400}
          />
        </ChartFrame>
      </TemplateContainer>
    );
  }
);

export default PersonHours;
