import { FC, memo } from "react";
import TemplateContainer from "../../atoms/container/template-container";
import SummaryFrame from "../../molecules/frame/summary/summary-frame";
import InformationFrame from "../../molecules/frame/information/information-frame";
import IndicatorCard from "../../molecules/cards/indicator-card/indicator-card";
import QuickStartCard from "../../molecules/cards/quick-start-card/quick-start-card";
import {
  INVOICE_TITLE,
  CREATED_INVOICE_LAST_MONTH_TITLE,
  TOTAL_BILLING_AMOUNT_LAST_MONTH_TITLE,
  CREATE_INVOICE_TITLE,
  CREATED_INVOICE_TITLE,
  SETTING_INVOICE_FORMAT_TITLE,
} from "../../../constants/title/title.constant";
import {
  WHITE_COLOR,
  INVOICE_SUMMARY_COLOR,
  QUICK_START_CARD_COLORS,
} from "../../../constants/style/color.constant";
import { DEFAULT_SUMMARY_MARGIN } from "../../../constants/style/size.constant";
import { KEN_UNIT, YEN_UNIT } from "../../../constants/unit/unit.constant";
import { QUICK_START_ICON } from "../../../constants/element/element.constant";
import InvoiceManagementList from "../../organisms/list/invoice/invoice-management-list";
import { InvoiceManagementData } from "../../../types/invoice/invoice.type";
import { INVOICE_MANAGEMENT_TITLE } from "../../../constants/title/title.constant";

interface InvoiceProps {
  numberOfIssuedInvoiceLastMonth: number;
  totalBillingAmountLastMonth: number;
  invoiceManagementData: InvoiceManagementData[];
  onClickCreateInvoice: () => void;
  onClickCreatedInvoice: () => void;
  onClickInvoiceSetting: () => void;
}

const Invoice: FC<InvoiceProps> = memo(
  ({
    numberOfIssuedInvoiceLastMonth,
    totalBillingAmountLastMonth,
    invoiceManagementData,
    onClickCreateInvoice,
    onClickCreatedInvoice,
    onClickInvoiceSetting,
  }) => {
    return (
      <TemplateContainer width="90%">
        <SummaryFrame
          title={INVOICE_TITLE}
          backgroundColor={INVOICE_SUMMARY_COLOR}
          gradation={true}
          titleColor={WHITE_COLOR}
        >
          <InformationFrame
            titleColor={WHITE_COLOR}
            title={CREATED_INVOICE_LAST_MONTH_TITLE}
            margin={DEFAULT_SUMMARY_MARGIN}
            textAlign="center"
          >
            <IndicatorCard
              indicatorValue={numberOfIssuedInvoiceLastMonth}
              indicatorUnit={KEN_UNIT}
              color={WHITE_COLOR}
            />
          </InformationFrame>

          <InformationFrame
            titleColor={WHITE_COLOR}
            title={TOTAL_BILLING_AMOUNT_LAST_MONTH_TITLE}
            margin={DEFAULT_SUMMARY_MARGIN}
            textAlign="center"
          >
            <IndicatorCard
              indicatorValue={totalBillingAmountLastMonth}
              indicatorUnit={YEN_UNIT}
              color={WHITE_COLOR}
              withComma={true}
            />
          </InformationFrame>
        </SummaryFrame>

        <InformationFrame direction="row" gap="20px">
          <QuickStartCard
            boldText={CREATE_INVOICE_TITLE}
            lineColor={QUICK_START_CARD_COLORS[0]}
            flexGrow={1}
            icon={QUICK_START_ICON.ADD}
            onClickQuickStart={onClickCreateInvoice}
          />

          <QuickStartCard
            boldText={CREATED_INVOICE_TITLE}
            lineColor={QUICK_START_CARD_COLORS[1]}
            flexGrow={1}
            icon={QUICK_START_ICON.ARROW}
            onClickQuickStart={onClickCreatedInvoice}
          />

          <QuickStartCard
            boldText={SETTING_INVOICE_FORMAT_TITLE}
            lineColor={QUICK_START_CARD_COLORS[2]}
            flexGrow={1}
            icon={QUICK_START_ICON.SETTING}
            onClickQuickStart={onClickInvoiceSetting}
          />
        </InformationFrame>

        <InvoiceManagementList
          invoiceManagementData={invoiceManagementData}
          title={INVOICE_MANAGEMENT_TITLE}
        />
      </TemplateContainer>
    );
  }
);

export default Invoice;
