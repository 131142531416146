/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { css } from "@emotion/react";
import PortalHeader from "../../organisms/header/portal-header";
import HeroSection from "./sections/hero-section";
import ThemeScreen from "../../atoms/screen/theme-screen";
import { DEFAULT_GRADATION_SCREEN_COLOR } from "../../../constants/style/color.constant";
import AboutSection from "./sections/about-section";
import FeaturesSection from "./sections/features-section";
import PricesSection from "./sections/prices-section";
import QASection from "./sections/qa-section";
import { QAData } from "../../../types/index.type";
import EndSection from "./sections/end-section";
import PortalFooter from "../../organisms/footer/portal-footer";
import PortalMenu from "../../organisms/menu/portal-menu";
import { MenuNavStatus } from "../../../types/menu/menu.type";

interface IntroductionProps {
  menuButtonDisabledFlg: boolean;
  standardPrice: number;
  proPrice: number;
  freeFeaturesList?: string[];
  standardFeaturesList?: string[];
  proFeaturesList?: string[];
  qaData: QAData[];
  menuNavStatus: MenuNavStatus;
  onClickPlayDemo: () => void;
  onClickLogin: () => void;
  onClickSignup: () => void;
  onClickMenuButton: () => void;
  onClickMenuNav: () => void;
  onClickMenuClose: () => void;
  onClickNoAvailable: () => void;
}

const Introduction: FC<IntroductionProps> = memo(
  ({
    menuButtonDisabledFlg,
    standardPrice,
    proPrice,
    freeFeaturesList,
    standardFeaturesList,
    proFeaturesList,
    qaData,
    menuNavStatus,
    onClickLogin,
    onClickSignup,
    onClickPlayDemo,
    onClickMenuButton,
    onClickMenuNav,
    onClickMenuClose,
    onClickNoAvailable,
  }) => {
    return (
      <ThemeScreen
        height="auto"
        color={DEFAULT_GRADATION_SCREEN_COLOR}
        gradation={true}
        style={screenStyle}
      >
        <PortalHeader
          menuButtonDisabledFlg={menuButtonDisabledFlg}
          onClickLogin={onClickLogin}
          onClickSignup={onClickSignup}
          onClickPlayDemo={onClickPlayDemo}
          onClickMenuButton={onClickMenuButton}
        />

        <main>
          <PortalMenu
            menuNavStatus={menuNavStatus}
            onClickClose={onClickMenuClose}
            onClickMenuNav={onClickMenuNav}
            onClickNoAvailable={onClickNoAvailable}
          />

          <HeroSection onClickPlayDemo={onClickPlayDemo} />
          <AboutSection />
          <FeaturesSection />
          <PricesSection
            standardPrice={standardPrice}
            proPrice={proPrice}
            freeFeaturesList={freeFeaturesList}
            standardFeaturesList={standardFeaturesList}
            proFeaturesList={proFeaturesList}
          />
          <QASection qaData={qaData} />
          <EndSection />
        </main>

        <PortalFooter />
      </ThemeScreen>
    );
  }
);

export default Introduction;

const screenStyle = css`
  overflow-x: hidden;
`;
