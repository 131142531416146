import { FC, memo } from "react";
import { DefaultChartData } from "../../../../types/charts/charts.type";
import VerticalBarChart from "../../../organisms/charts/bar-charts/vertical-bar-chart";
import { YEN_UNIT } from "../../../../constants/unit/unit.constant";

interface MonthlyIncomeAndExpendituresProps {
  incomeAndExpendituresData: DefaultChartData[];
  width: string;
  minWidth: number;
  height: string;
  dataKey: string;
  valueKey: string;
}

const MonthlyIncomeAndExpenditures: FC<MonthlyIncomeAndExpendituresProps> =
  memo(
    ({
      incomeAndExpendituresData,
      width,
      minWidth,
      height,
      dataKey,
      valueKey,
    }) => {
      return (
        <VerticalBarChart
          width={width}
          minWidth={minWidth}
          height={height}
          data={incomeAndExpendituresData}
          dataKey={dataKey}
          valueKey={valueKey}
          unit={YEN_UNIT}
        />
      );
    }
  );

export default MonthlyIncomeAndExpenditures;
