/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { css, Interpolation, Theme } from "@emotion/react";
import BasicButton from "../../atoms/buttons/basic-button";
import { ButtonType } from "../../../types/index.type";
import {
  DEFAULT_BUTTON_BG_COLOR,
  WHITE_COLOR,
} from "../../../constants/style/color.constant";

interface HeaderButtonProps {
  caption: string;
  buttonType: ButtonType;
  style?: Interpolation<Theme>;
  onClickHeaderButton: () => void;
}

const HeaderButton: FC<HeaderButtonProps> = memo(
  ({ caption, buttonType, style, onClickHeaderButton }) => {
    const generateButton = (buttonType: ButtonType) => {
      switch (buttonType) {
        case "primary":
          return (
            <BasicButton
              caption={caption}
              borderRadius="30px"
              width="150px"
              height="40px"
              border={`solid 2px ${DEFAULT_BUTTON_BG_COLOR}`}
              backgroundColor={DEFAULT_BUTTON_BG_COLOR}
              onClickButton={onClickHeaderButton}
              hoverAction={false}
              style={[hoverPrimary, style]}
            />
          );

        case "secondary":
          return (
            <BasicButton
              caption={caption}
              borderRadius="30px"
              width="150px"
              height="40px"
              border={`solid 2px ${DEFAULT_BUTTON_BG_COLOR}`}
              backgroundColor={WHITE_COLOR}
              color={DEFAULT_BUTTON_BG_COLOR}
              onClickButton={onClickHeaderButton}
              hoverAction={false}
              style={[hoverSecondary, style]}
            />
          );

        case "info":
          return (
            <BasicButton
              caption={caption}
              borderRadius="10px"
              width="110px"
              height="40px"
              backgroundColor={"transparent"}
              color={DEFAULT_BUTTON_BG_COLOR}
              onClickButton={onClickHeaderButton}
              hoverAction={false}
              style={[hoverInfo, style]}
            />
          );

        default:
          return <></>;
      }
    };

    return generateButton(buttonType);
  }
);

export default HeaderButton;

const hoverPrimary = css`
  transition: 0.5s;
  :hover {
    background-color: #2c3264;
  }
`;

const hoverSecondary = css`
  transition: 0.5s;
  :hover {
    color: ${WHITE_COLOR};
    background-color: ${DEFAULT_BUTTON_BG_COLOR};
  }
`;

const hoverInfo = css`
  transition: 0.5s;
  :hover {
    color: ${WHITE_COLOR};
    background-color: ${DEFAULT_BUTTON_BG_COLOR};
  }
`;
