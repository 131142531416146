import {
  TASK_STATUS,
  CONTRACT_STATUS,
  PROJECT_STATUS,
  PROJECT_PROGRESS_STATUS,
  INVOICE_MANAGEMENT_STATUS,
  PRIORITY_STATUS
} from "../status/status.constant";

export const TASK_STATUS_LABEL = [
  { status: TASK_STATUS.DONE, label: "完了" },
  { status: TASK_STATUS.DOING, label: "着手中" },
  { status: TASK_STATUS.NOT_START, label: "未着手" }
];

export const CONTRACT_STATUS_LABEL = [
  { status: CONTRACT_STATUS.END, label: "契約終了" },
  { status: CONTRACT_STATUS.UNDER_CONTRACT, label: "契約中" },
  { status: CONTRACT_STATUS.BEFORE_CONTRACT, label: "契約開始前" }
];

export const PROJECT_STATUS_LABEL = [
  { status: PROJECT_STATUS.END, label: "終了" },
  { status: PROJECT_STATUS.PROGRESS, label: "進行中" },
  { status: PROJECT_STATUS.BEFORE_START, label: "開始前" }
];

export const PROJECT_PROGRESS_STATUS_LABEL = [
  { status: PROJECT_PROGRESS_STATUS.ACCORDING_TO_SCHEDULE, label: "予定通り" },
  { status: PROJECT_PROGRESS_STATUS.AHEAD, label: "前倒し" },
  { status: PROJECT_PROGRESS_STATUS.BEHIND, label: "遅延" }
];

export const INVOICE_MANAGEMENT_STATUS_LABEL = [
  { status: INVOICE_MANAGEMENT_STATUS.SENT, label: "送付済み" },
  { status: INVOICE_MANAGEMENT_STATUS.CREATED, label: "作成済み" },
  { status: INVOICE_MANAGEMENT_STATUS.NOT_CREATED, label: "未作成" }
];

export const PRIORITY_LABEL = [
  { status: PRIORITY_STATUS.LOW, label: "低" },
  { status: PRIORITY_STATUS.MIDDLE, label: "中" },
  { status: PRIORITY_STATUS.HIGH, label: "高" }
];