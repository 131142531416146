import { FC, memo } from "react";
import TemplateContainer from "../../atoms/container/template-container";
import HeaderTitle from "../../molecules/frame/title/header-title";
import QuickStartCard from "../../molecules/cards/quick-start-card/quick-start-card";
import { DEFAULT_PAGE_WIDTH } from "../../../constants/style/size.constant";
import InformationFrame from "../../molecules/frame/information/information-frame";
import {
  BUSINESS_INFORMATION_TITLE,
  CLIENT_INFORMATION_TITLE,
  FINANCE_INFORMATION_TITLE,
  PASSWORD_TITLE,
  PERSON_HOURS_MANAGEMENT_TITLE,
  PROJECT_INFORMATION_TITLE,
  SETTING_TITLE,
  TASK_INFORMATION_TITLE,
  USER_INFORMATION_TITLE,
} from "../../../constants/title/title.constant";
import { QUICK_START_CARD_COLORS } from "../../../constants/style/color.constant";
import { QUICK_START_ICON } from "../../../constants/element/element.constant";

interface SettingsProps {
  onClickSetUserInformation: () => void;
  onClickSetPassword: () => void;
  onClickSetBusinessInformation: () => void;
  onClickSetFinanceInformation: () => void;
  onClickSetPersonHoursManagement: () => void;
  onClickSetClientInformation: () => void;
  onClickSetProjectInformation: () => void;
  onClickSetTaskInformation: () => void;
}

const Settings: FC<SettingsProps> = memo(
  ({
    onClickSetUserInformation,
    onClickSetPassword,
    onClickSetBusinessInformation,
    onClickSetFinanceInformation,
    onClickSetClientInformation,
    onClickSetPersonHoursManagement,
    onClickSetProjectInformation,
    onClickSetTaskInformation,
  }) => {
    return (
      <TemplateContainer width={DEFAULT_PAGE_WIDTH}>
        <HeaderTitle title={SETTING_TITLE} />
        <InformationFrame direction="row" gap="20px">
          <InformationFrame
            direction="row"
            gap="20px"
            flexGrow={1}
            width="100%"
          >
            <InformationFrame direction="row" gap="20px" flexGrow={1}>
              <QuickStartCard
                boldText={USER_INFORMATION_TITLE}
                lineColor={QUICK_START_CARD_COLORS[0]}
                flexGrow={1}
                icon={QUICK_START_ICON.USER}
                onClickQuickStart={onClickSetUserInformation}
              />

              <QuickStartCard
                boldText={PASSWORD_TITLE}
                lineColor={QUICK_START_CARD_COLORS[1]}
                flexGrow={1}
                icon={QUICK_START_ICON.PASSWORD}
                onClickQuickStart={onClickSetPassword}
              />
            </InformationFrame>

            <InformationFrame direction="row" gap="20px" flexGrow={1}>
              <QuickStartCard
                boldText={BUSINESS_INFORMATION_TITLE}
                lineColor={QUICK_START_CARD_COLORS[2]}
                flexGrow={1}
                icon={QUICK_START_ICON.COMPANY}
                onClickQuickStart={onClickSetBusinessInformation}
              />

              <QuickStartCard
                boldText={FINANCE_INFORMATION_TITLE}
                lineColor={QUICK_START_CARD_COLORS[3]}
                flexGrow={1}
                icon={QUICK_START_ICON.MONEY}
                onClickQuickStart={onClickSetFinanceInformation}
              />
            </InformationFrame>
          </InformationFrame>

          <InformationFrame
            direction="row"
            gap="20px"
            flexGrow={1}
            width="100%"
          >
            <InformationFrame direction="row" gap="20px" flexGrow={1}>
              <QuickStartCard
                boldText={PERSON_HOURS_MANAGEMENT_TITLE}
                lineColor={QUICK_START_CARD_COLORS[4]}
                flexGrow={1}
                icon={QUICK_START_ICON.PERSON_HOURS}
                onClickQuickStart={onClickSetPersonHoursManagement}
              />

              <QuickStartCard
                boldText={CLIENT_INFORMATION_TITLE}
                lineColor={QUICK_START_CARD_COLORS[5]}
                flexGrow={1}
                icon={QUICK_START_ICON.CLIENT}
                onClickQuickStart={onClickSetClientInformation}
              />
            </InformationFrame>

            <InformationFrame direction="row" gap="20px" flexGrow={1}>
              <QuickStartCard
                boldText={PROJECT_INFORMATION_TITLE}
                lineColor={QUICK_START_CARD_COLORS[6]}
                flexGrow={1}
                icon={QUICK_START_ICON.PROJECT}
                onClickQuickStart={onClickSetProjectInformation}
              />

              <QuickStartCard
                boldText={TASK_INFORMATION_TITLE}
                lineColor={QUICK_START_CARD_COLORS[7]}
                flexGrow={1}
                icon={QUICK_START_ICON.TASK}
                onClickQuickStart={onClickSetTaskInformation}
              />
            </InformationFrame>
          </InformationFrame>
        </InformationFrame>
      </TemplateContainer>
    );
  }
);

export default Settings;
