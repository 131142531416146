import { FC, useState } from "react";
import { UserProfile } from "../types/profile/user.type";
import { AuthUserContext } from "../contexts/auth-user.context";

interface AuthUserProviderProps {
  children: React.ReactNode;
}

const AuthUserProvider: FC<AuthUserProviderProps> = ({ children }) => {
  const [userProfile, setUserProfile] = useState<UserProfile>(
    {} as UserProfile
  );

  const login = (id: string, password: string) => {};

  const logout = () => {};

  return (
    <AuthUserContext.Provider value={{ userProfile, login, logout }}>
      {children}
    </AuthUserContext.Provider>
  );
};

export default AuthUserProvider;
