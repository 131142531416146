import { FC, useState } from "react";
import { DarkScreenContext } from "../contexts/dark-screen.context";

interface DarkScreenProviderProps {
  children: React.ReactNode;
}

const DarkScreenProvider: FC<DarkScreenProviderProps> = ({ children }) => {
  const [showDarkScreenFlg, setShowDarkScreenFlg] = useState<boolean>(false);

  const toggleDarkScreen = (showScreenFlg: boolean) => {
    setShowDarkScreenFlg(showScreenFlg);
  };

  return (
    <DarkScreenContext.Provider value={{ showDarkScreenFlg, toggleDarkScreen }}>
      {children}
    </DarkScreenContext.Provider>
  );
};

export default DarkScreenProvider;
