import { FC, memo } from "react";
import ThemeFrame from "../../atoms/frame/theme-frame";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import jaLocale from "@fullcalendar/core/locales/ja";
import { ScheduleData } from "../../../types/schedule/schedule.type";
import CalendarTheme from "./style/calendar-theme";

interface CalendarProps {
  width?: string;
  height?: string;
  minWidth?: string;
  minHeight?: string;
  calendarHeight?: string;
  scheduleData: ScheduleData[];
}

const Calendar: FC<CalendarProps> = memo(
  ({
    width,
    height,
    minWidth,
    minHeight,
    calendarHeight = "100%",
    scheduleData,
  }) => {
    return (
      <ThemeFrame
        width={width}
        height={height}
        minWidth={minWidth}
        minHeight={minHeight}
      >
        <CalendarTheme>
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
            initialView="dayGridMonth"
            events={scheduleData}
            weekends={true}
            datesSet={(dates) => {}}
            headerToolbar={{
              start: "today prevYear,prev,next,nextYear",
              center: "title",
              end: "dayGridMonth,timeGridDay,listWeek",
            }}
            editable={true}
            eventContent={true}
            locale={jaLocale}
            height={calendarHeight}
            selectable={true}
            eventClick={(event) => {}}
            // 必要であれば以下も定義
            // contentHeight={"700px"}
            // aspectRatio={0.6}
          />
        </CalendarTheme>
      </ThemeFrame>
    );
  }
);

export default Calendar;
