/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { css } from "@emotion/react";

interface TemplateContainerProps {
  children: React.ReactNode;
  maxWidth?: string;
  width?: string;
  margin?: string;
  gap?: string;
}

const TemplateContainer: FC<TemplateContainerProps> = memo(
  ({
    children,
    width = "100%",
    margin = "0px auto",
    gap = "0px",
    maxWidth = "1920px",
  }) => {
    const container = css`
      width: ${width};
      margin: ${margin};
      gap: ${gap};
      max-width: ${maxWidth};
    `;

    return <div css={container}>{children}</div>;
  }
);

export default TemplateContainer;
