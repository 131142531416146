import { TaskData } from "../../types/tasks/tasks.type";
import { ProjectInformation } from "../../types/projects/projects.type";
import { DEMO_TASK_DATA } from "./tasks.constant";
import { DEMO_PROJECTS_DATA } from "./projects.constant";
import { PROJECT_STATUS } from "../status/status.constant";

export const DEMO_COMPACT_TASK_DATA: Omit<TaskData, "priority">[] =  DEMO_TASK_DATA.map(({ priority, ...rest  }) => rest);

const projectInProgressData = DEMO_PROJECTS_DATA.filter(
  (project) => project.projectStatus === PROJECT_STATUS.PROGRESS
);

export const DEMO_COMPACT_PROJECT_DATA: Omit<ProjectInformation, "projectStartDate" | "projectEndDate" | "projectStatus" | "amountOfProjectProgress" >[] = 
  projectInProgressData.map(({ projectStartDate, projectEndDate, projectStatus, amountOfProjectProgress,  ...rest  }) => rest);