/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { css } from "@emotion/react";
import Title from "../../../atoms/characters/title/title";
import Text from "../../../atoms/characters/text/text";
import { TITLE_SIZE } from "../../../../constants/element/element.constant";
import BasicCard from "../../cards/basic-card/basic-card";
import { DataType } from "../../../../types/index.type";
import { formatByDataType } from "../../../../lib/formatter";

interface DetailFrameProps {
  data: any[];
  nameKey: string;
  valueKey: string;
  title?: string;
  noDataCaption?: string;
  width?: string;
  height?: string;
  contentWidth?: string;
  dataType?: DataType;
  flexGrow?: number;
  titleMinWidth?: string;
  descriptionMinWidth?: string;
  titleTextAlign?: string;
  descriptionTextAlign?: string;
}

const DetailFrame: FC<DetailFrameProps> = memo(
  ({
    data,
    nameKey,
    valueKey,
    title,
    width = "auto",
    height = "auto",
    contentWidth,
    noDataCaption,
    dataType,
    titleMinWidth,
    descriptionMinWidth = "110px",
    titleTextAlign,
    descriptionTextAlign = "right",
    flexGrow = 0,
  }) => {
    const container = css`
      width: ${width};
      height: ${height};
      flex-grow: ${flexGrow};
    `;

    if (data.length === 0 && !noDataCaption) return null;
    return (
      <div css={container}>
        {title ? <Title text={title} titleSize={TITLE_SIZE.SMALL} /> : null}
        {data.length === 0 ? <Text text={noDataCaption ?? ""} /> : null}
        <div>
          {data.map((item, index) => (
            <BasicCard
              width={contentWidth}
              margin="5px auto"
              key={index}
              title={item[nameKey]}
              description={formatByDataType(item[valueKey], dataType)}
              titleMinWidth={titleMinWidth}
              descriptionMinWidth={descriptionMinWidth}
              titleTextAlign={titleTextAlign}
              descriptionTextAlign={descriptionTextAlign}
            />
          ))}
        </div>
      </div>
    );
  }
);

export default DetailFrame;
