/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { css } from "@emotion/react";
import { TITLE_SIZE } from "../../../../constants/element/element.constant";
import Title from "../../../atoms/characters/title/title";
import ThemeFrame from "../../../atoms/frame/theme-frame";

interface DashboardFrameProps {
  text: string;
  children: React.ReactNode;
  width: string;
  height: string;
  minHeight?: string;
  fontSize?: string;
  flexGrow?: number;
  margin?: string;
  scrollX?: boolean;
  scrollY?: boolean;
}

const DashboardFrame: FC<DashboardFrameProps> = memo(
  ({
    text,
    children,
    width,
    height,
    minHeight = "unset",
    fontSize,
    flexGrow = 0,
    margin = "0px 0px",
    scrollX = false,
    scrollY = false,
  }) => {
    const contentWrapper = css`
      overflow-x: ${scrollX ? "scroll" : "hidden"};
      overflow-y: ${scrollY ? "scroll" : "hidden"};
      padding-bottom: 25px;
      height: 100%;
    `;

    return (
      <ThemeFrame
        width={width}
        height={height}
        flexGrow={flexGrow}
        margin={margin}
        minHeight={minHeight}
      >
        <div css={wrapper}>
          <Title
            text={text}
            titleSize={TITLE_SIZE.MEDIUM}
            fontSize={fontSize}
          />
          <div css={contentWrapper}>{children}</div>
        </div>
      </ThemeFrame>
    );
  }
);

export default DashboardFrame;

const wrapper = css`
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
`;
