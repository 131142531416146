/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { css } from "@emotion/react";
import Title from "../../../atoms/characters/title/title";
import { TITLE_SIZE } from "../../../../constants/element/element.constant";
import {
  INTRODUCTION_TEXT_COLOR,
  WHITE_COLOR,
} from "../../../../constants/style/color.constant";
import PricesCard from "../../../molecules/cards/prices-card/prices-card";
import IntroductionContainer from "../../../atoms/container/introduction-container";

interface PricesSectionProps {
  standardPrice: number;
  proPrice: number;
  freeFeaturesList?: string[];
  standardFeaturesList?: string[];
  proFeaturesList?: string[];
}

const PricesSection: FC<PricesSectionProps> = memo(
  ({
    standardPrice,
    proPrice,
    freeFeaturesList,
    standardFeaturesList,
    proFeaturesList,
  }) => {
    return (
      <section id="prices">
        <IntroductionContainer contentWidth="80%" margin=" 50px auto">
          <Title
            color={WHITE_COLOR}
            text="料金プラン（月額）"
            titleSize={TITLE_SIZE.LARGE}
          />

          <div css={contentWrapper}>
            <PricesCard
              planName="Free"
              width="280px"
              price={0}
              textColor={INTRODUCTION_TEXT_COLOR}
              availableFeaturesList={freeFeaturesList}
            />

            <PricesCard
              planName="Standard"
              width="280px"
              price={standardPrice}
              textColor={INTRODUCTION_TEXT_COLOR}
              availableFeaturesList={standardFeaturesList}
            />

            <PricesCard
              planName="Pro"
              width="280px"
              price={proPrice}
              textColor={INTRODUCTION_TEXT_COLOR}
              availableFeaturesList={proFeaturesList}
            />
          </div>
        </IntroductionContainer>
      </section>
    );
  }
);

export default PricesSection;

const contentWrapper = css`
  display: flex;
  justify-content: center;
  gap: 5%;
  margin: 30px 0px;
  flex-wrap: wrap;
`;
