/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { css } from "@emotion/react";
import heroImg from "../../../../../assets/images/hero-image.png";
import { MEDIA_QUERY } from "../../../../../constants/style/media.constant";

const HeroImage: FC = memo(() => {
  return (
    <div css={imgWrapper}>
      <img src={heroImg} alt="hero img" css={imgStyle} />
    </div>
  );
});

export default HeroImage;

const imgWrapper = css`
  width: 550px;
  margin: 15px;

  ${MEDIA_QUERY.SP} {
    width: 360px;
  }
`;

const imgStyle = css`
  width: 100%;
  height: auto;
`;
