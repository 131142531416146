/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { css } from "@emotion/react";
import ThemeScreen from "../../atoms/screen/theme-screen";
import Title from "../../atoms/characters/title/title";
import Text from "../../atoms/characters/text/text";
import {
  DEFAULT_GRADATION_SCREEN_COLOR,
  WHITE_COLOR,
} from "../../../constants/style/color.constant";
import { TITLE_SIZE } from "../../../constants/element/element.constant";

const PageNotFound: FC = memo(() => {
  return (
    <ThemeScreen
      style={container}
      color={DEFAULT_GRADATION_SCREEN_COLOR}
      gradation={true}
    >
      <div>
        <Title
          text="404"
          fontSize="70px"
          titleSize={TITLE_SIZE.LARGE}
          color={WHITE_COLOR}
        />
        <Title
          text="Page not found"
          titleSize={TITLE_SIZE.MEDIUM}
          color={WHITE_COLOR}
        />
        <Text text="お探しのページが見つかりませんでした" color={WHITE_COLOR} />
      </div>
    </ThemeScreen>
  );
});

export default PageNotFound;

const container = css`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
