import DemoDashboardPage from "../../page/portal/demo/dashboard/demo-dashboard-page";
import DemoFinancePage from "../../page/portal/demo/finance/demo-finance-page";
import DemoClientsPage from "../../page/portal/demo/clients/demo-clients-page";
import DemoInvoicePage from "../../page/portal/demo/invoice/demo-invoice-page";
import DemoPersonHoursPage from "../../page/portal/demo/person-hours/demo-person-hours-page";
import DemoProjectsPage from "../../page/portal/demo/projects/demo-projects-page";
import DemoSchedulePage from "../../page/portal/demo/schedule/demo-schedule-page";
import DemoSettingsPage from "../../page/portal/demo/settings/demo-settings-page";
import {
  DEMO_INDEX,
  DEMO_CLIENTS,
  DEMO_FINANCE,
  DEMO_INVOICE,
  DEMO_PERSON_HOURS,
  DEMO_PROJECTS,
  DEMO_SCHEDULE,
  DEMO_TASKS,
  DEMO_SETTINGS,
} from "../../constants/path/path.constant";
import DemoTasksPage from "../../page/portal/demo/tasks/demo-tasks-page";

export const demoRouterList = [
  {
    path: DEMO_INDEX,
    element: <DemoDashboardPage />,
  },
  {
    path: DEMO_FINANCE,
    element: <DemoFinancePage />,
  },
  {
    path: DEMO_CLIENTS,
    element: <DemoClientsPage />,
  },
  {
    path: DEMO_INVOICE,
    element: <DemoInvoicePage />,
  },
  {
    path: DEMO_PERSON_HOURS,
    element: <DemoPersonHoursPage />,
  },
  {
    path: DEMO_PROJECTS,
    element: <DemoProjectsPage />,
  },
  {
    path: DEMO_SCHEDULE,
    element: <DemoSchedulePage />,
  },
  {
    path: DEMO_TASKS,
    element: <DemoTasksPage />,
  },
  {
    path: DEMO_SETTINGS,
    element: <DemoSettingsPage />,
  },
];
