import { FC, memo } from "react";
import DashboardFrame from "../../molecules/frame/dashboard/dashboard-frame";
import AnnualIncome from "../finance/charts/annual-income";
import {
  DASHBOARD_FRAME_HEIGHT,
  DASHBOARD_CALENDER_HEIGHT,
  DASHBOARD_FRAME_MIN_HEIGHT,
  DASHBOARD_CALENDER_MIN_HEIGHT,
} from "../../../constants/style/size.constant";
import {
  ANNUAL_TARGET_INCOME_TITLE,
  PERSON_HOURS_RATIO_LAST_MONTH_TITLE,
  CLIENT_TITLE,
  SCHEDULE_TITLE,
  TASK_TITLE,
  PROJECT_TITLE,
  MONTHLY_INCOME_TITLE,
  INCOME_TITLE,
  EXPENDITURE_TITLE,
} from "../../../constants/title/title.constant";
import Calendar from "../../organisms/calendar/compact-calendar";
import MonthlyIncomeAndExpenditures from "../finance/charts/monthly-income-and-expenditures";
import PersonHoursRatio from "../person-hours/charts/person-hours-ratio";
import { DashboardData } from "../../../types/dashboard/dashboard.type";
import CompactTaskList from "../../organisms/list/tasks/compact-task-list";
import CompactClientList from "../../organisms/list/clients/compact-client-list";
import CompactProjectInProgressList from "../../organisms/list/projects/compact-project-in-progress-list";
import InformationFrame from "../../molecules/frame/information/information-frame";
import TemplateContainer from "../../atoms/container/template-container";
import { DefaultChartData } from "../../../types/charts/charts.type";
import { DEFAULT_PAGE_WIDTH } from "../../../constants/style/size.constant";

interface DashboardProps {
  dashboardData: DashboardData;
}

const Dashboard: FC<DashboardProps> = memo(({ dashboardData }) => {
  const IncomeAndExpenditureData: DefaultChartData[] = [
    {
      name: INCOME_TITLE,
      value: dashboardData.incomeAndExpenditure.monthlyIncome,
    },
    {
      name: EXPENDITURE_TITLE,
      value: dashboardData.incomeAndExpenditure.monthlyExpenditure,
    },
  ];

  return (
    <TemplateContainer width={DEFAULT_PAGE_WIDTH} gap="30px">
      <InformationFrame
        width="100%"
        direction="row"
        gap="30px"
        margin="30px 0px"
      >
        <InformationFrame direction="row" flexGrow={1} gap="30px">
          <DashboardFrame
            text={ANNUAL_TARGET_INCOME_TITLE}
            width="250px"
            minHeight={DASHBOARD_FRAME_MIN_HEIGHT}
            height={DASHBOARD_FRAME_HEIGHT}
            fontSize="20px"
            flexGrow={1}
          >
            <AnnualIncome
              targetAnnualIncome={
                dashboardData.incomeAndExpenditure.targetAnnualIncome
              }
              totalMonthlyIncome={
                dashboardData.incomeAndExpenditure.totalMonthlyIncome
              }
              width="100%"
              height="100%"
              containerWidth="100%"
              containerHeight="100%"
            />
          </DashboardFrame>
          <DashboardFrame
            text={MONTHLY_INCOME_TITLE}
            width="250px"
            minHeight={DASHBOARD_FRAME_MIN_HEIGHT}
            height={DASHBOARD_FRAME_HEIGHT}
            fontSize="20px"
            flexGrow={1}
          >
            <MonthlyIncomeAndExpenditures
              incomeAndExpendituresData={IncomeAndExpenditureData}
              width="100%"
              minWidth={200}
              height="100%"
              dataKey="name"
              valueKey="value"
            />
          </DashboardFrame>
        </InformationFrame>

        <InformationFrame direction="row" flexGrow={1} gap="30px">
          <DashboardFrame
            text={PERSON_HOURS_RATIO_LAST_MONTH_TITLE}
            minHeight={DASHBOARD_FRAME_MIN_HEIGHT}
            width="250px"
            height={DASHBOARD_FRAME_HEIGHT}
            fontSize="20px"
            flexGrow={1}
          >
            <PersonHoursRatio
              data={dashboardData.personHours}
              width="100%"
              height="100%"
              containerWidth="100%"
              containerHeight="100%"
            />
          </DashboardFrame>

          <DashboardFrame
            text={CLIENT_TITLE}
            width="250px"
            minHeight={DASHBOARD_FRAME_MIN_HEIGHT}
            height={DASHBOARD_FRAME_HEIGHT}
            fontSize="20px"
            flexGrow={1}
            scrollY={true}
          >
            <CompactClientList clientData={dashboardData.clients} />
          </DashboardFrame>
        </InformationFrame>
      </InformationFrame>

      <InformationFrame direction="row" gap="30px" margin="30px 0px">
        <DashboardFrame
          text={SCHEDULE_TITLE}
          width={"530px"}
          minHeight={DASHBOARD_CALENDER_MIN_HEIGHT}
          height={DASHBOARD_CALENDER_HEIGHT}
          fontSize="20px"
          flexGrow={1}
        >
          <Calendar scheduleData={dashboardData.schedule} />
        </DashboardFrame>

        <InformationFrame direction="column" width="530px" flexGrow={1}>
          <DashboardFrame
            text={TASK_TITLE}
            width={"100%"}
            minHeight={DASHBOARD_FRAME_MIN_HEIGHT}
            height={DASHBOARD_FRAME_HEIGHT}
            fontSize="20px"
            flexGrow={1}
            scrollY={true}
          >
            <CompactTaskList taskData={dashboardData.tasks} />
          </DashboardFrame>

          <DashboardFrame
            text={PROJECT_TITLE}
            width={"100%"}
            minHeight={DASHBOARD_FRAME_MIN_HEIGHT}
            height={DASHBOARD_FRAME_HEIGHT}
            fontSize="20px"
            flexGrow={1}
            margin="30px 0px"
            scrollY={true}
          >
            <CompactProjectInProgressList
              projectData={dashboardData.projects}
            />
          </DashboardFrame>
        </InformationFrame>
      </InformationFrame>
    </TemplateContainer>
  );
});

export default Dashboard;
