/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { css } from "@emotion/react";
import Text from "../../../atoms/characters/text/text";

interface BasicCardProps {
  title: string;
  description: string;
  fontSize?: string;
  width?: string;
  margin?: string;
  gap?: string;
  color?: string;
  titleBold?: boolean;
  titleMinWidth?: string;
  descriptionMinWidth?: string;
  titleTextAlign?: string;
  descriptionTextAlign?: string;
}

const BasicCard: FC<BasicCardProps> = memo(
  ({
    title,
    description,
    fontSize = "16px",
    width = "100%",
    margin = "5px 0px",
    gap = "25px",
    color,
    titleBold = true,
    titleMinWidth,
    descriptionMinWidth,
    titleTextAlign,
    descriptionTextAlign,
  }) => {
    const container = css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: ${width};
      margin: ${margin};
      gap: ${gap};
    `;

    return (
      <div css={container}>
        <Text
          text={title}
          minWidth={titleMinWidth}
          textAlign={titleTextAlign}
          isBold={titleBold}
          fontSize={fontSize}
          color={color}
        />
        <Text
          minWidth={descriptionMinWidth}
          textAlign={descriptionTextAlign}
          text={description}
          fontSize={fontSize}
          color={color}
        />
      </div>
    );
  }
);

export default BasicCard;
