import { FC, memo } from "react";
import TemplateContainer from "../../atoms/container/template-container";
import SummaryFrame from "../../molecules/frame/summary/summary-frame";
import {
  CLIENT_TITLE,
  CLIENTS_IN_PROGRESS_TITLE,
  BUSINESS_MEETINGS_IN_PROGRESS_TITLE,
  ANNUAL_CLOSING_RATE_TITLE,
  CONTRACT_CONTINUATION_RATE_TITLE,
  CLIENT_LIST_TITLE,
  ADD_CLIENTS_TITLE,
  MANAGE_BUSINESS_MEETINGS_TITLE,
} from "../../../constants/title/title.constant";
import {
  CLIENTS_SUMMARY_COLOR,
  WHITE_COLOR,
  QUICK_START_CARD_COLORS,
} from "../../../constants/style/color.constant";
import InformationFrame from "../../molecules/frame/information/information-frame";
import IndicatorCard from "../../molecules/cards/indicator-card/indicator-card";
import {
  COMPANY_UNIT,
  PERCENT_UNIT,
} from "../../../constants/unit/unit.constant";
import QuickStartCard from "../../molecules/cards/quick-start-card/quick-start-card";
import { INCLUDE_CANCEL_BY_USER } from "../../../constants/description/description.constant";
import ClientList from "../../organisms/list/clients/client-list";
import { ClientInformation } from "../../../types/clients/clients.type";
import {
  DEFAULT_PAGE_WIDTH,
  DEFAULT_SUMMARY_MARGIN,
} from "../../../constants/style/size.constant";
import { QUICK_START_ICON } from "../../../constants/element/element.constant";

interface ClientsProps {
  numberOfClientsInProgress: number;
  numberOfBusinessMeetingsInProgress: number;
  annualClosingRate: number;
  contractContinuationRate: number;
  clients: ClientInformation[];
  onClickAddClients: () => void;
  onClickManageBusinessMeeting: () => void;
}

const Clients: FC<ClientsProps> = memo(
  ({
    numberOfClientsInProgress,
    numberOfBusinessMeetingsInProgress,
    annualClosingRate,
    contractContinuationRate,
    clients,
    onClickAddClients,
    onClickManageBusinessMeeting,
  }) => {
    return (
      <TemplateContainer width={DEFAULT_PAGE_WIDTH}>
        <SummaryFrame
          title={CLIENT_TITLE}
          backgroundColor={CLIENTS_SUMMARY_COLOR}
          gradation={true}
          titleColor={WHITE_COLOR}
        >
          <InformationFrame
            titleColor={WHITE_COLOR}
            title={CLIENTS_IN_PROGRESS_TITLE}
            margin={DEFAULT_SUMMARY_MARGIN}
            textAlign="center"
          >
            <IndicatorCard
              indicatorValue={numberOfClientsInProgress}
              indicatorUnit={COMPANY_UNIT}
              color={WHITE_COLOR}
            />
          </InformationFrame>

          <InformationFrame
            titleColor={WHITE_COLOR}
            title={BUSINESS_MEETINGS_IN_PROGRESS_TITLE}
            margin={DEFAULT_SUMMARY_MARGIN}
            textAlign="center"
          >
            <IndicatorCard
              indicatorValue={numberOfBusinessMeetingsInProgress}
              indicatorUnit={COMPANY_UNIT}
              color={WHITE_COLOR}
            />
          </InformationFrame>

          <InformationFrame
            titleColor={WHITE_COLOR}
            title={ANNUAL_CLOSING_RATE_TITLE}
            margin={DEFAULT_SUMMARY_MARGIN}
            textAlign="center"
          >
            <IndicatorCard
              indicatorValue={annualClosingRate}
              indicatorUnit={PERCENT_UNIT}
              color={WHITE_COLOR}
              note={INCLUDE_CANCEL_BY_USER}
            />
          </InformationFrame>

          <InformationFrame
            titleColor={WHITE_COLOR}
            title={CONTRACT_CONTINUATION_RATE_TITLE}
            margin={DEFAULT_SUMMARY_MARGIN}
            textAlign="center"
          >
            <IndicatorCard
              indicatorValue={contractContinuationRate}
              indicatorUnit={PERCENT_UNIT}
              color={WHITE_COLOR}
              note={INCLUDE_CANCEL_BY_USER}
            />
          </InformationFrame>
        </SummaryFrame>

        <InformationFrame direction="row" gap="20px">
          <QuickStartCard
            boldText={ADD_CLIENTS_TITLE}
            lineColor={QUICK_START_CARD_COLORS[2]}
            flexGrow={1}
            icon={QUICK_START_ICON.ADD}
            onClickQuickStart={onClickAddClients}
          />

          <QuickStartCard
            boldText={MANAGE_BUSINESS_MEETINGS_TITLE}
            lineColor={QUICK_START_CARD_COLORS[4]}
            flexGrow={1}
            icon={QUICK_START_ICON.MANAGEMENT}
            onClickQuickStart={onClickManageBusinessMeeting}
          />
        </InformationFrame>

        <ClientList title={CLIENT_LIST_TITLE} clientData={clients} />
      </TemplateContainer>
    );
  }
);

export default Clients;
