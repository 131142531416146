/**
 * caution
 */
export const INCLUDE_CANCEL_BY_USER = "ユーザーによるキャンセルも含む";

/**
 * no data
 */

// project
export const PROJECT_NOT_REGISTERD = "プロジェクトが登録されていません";
export const NO_PROJECTS_IN_PROGRESS = "進行中のプロジェクトがありません";

// person hours
export const PERSON_HOURS_NOT_REGISTERD = "工数が登録されていません";

// client
export const NO_CONTRACTED_CLIENTS_REGISTERD = "契約中のクライアントが登録されていません";
export const NO_CLIENTS_REGISTERD = "クライアントが登録されていません";

// task
export const NO_PENDING_TASKS = "未完了のタスクはありません";
export const NO_TASKS_REGISTERD = "タスクが登録されていません";

// invoice
export const NO_CLIENTS_REGISTERD_TO_SEND_INVOICE = "請求書を送付するクライアントが登録されていません";