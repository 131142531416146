/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { css } from "@emotion/react";
import CategoryPieChart from "../../../organisms/charts/pie-charts/category-pie-chart";
import { PersonHoursData } from "../../../../types/person-hours/person-hours.type";
import { PERSON_HOURS_RATIO_DATA } from "../../../../constants/charts/charts.constant";

interface PersonHoursRatioProps {
  data: PersonHoursData[];
  containerWidth?: string;
  containerHeight?: string;
  width: number | string;
  height: number | string;
  flexGrow?: number;
}

const PersonHoursRatio: FC<PersonHoursRatioProps> = memo(
  ({
    data,
    width,
    height,
    containerWidth = "100%",
    containerHeight = "100%",
    flexGrow = 0,
  }) => {
    const container = css`
      width: ${containerWidth};
      height: ${containerHeight};
      display: flex;
      justify-content: center;
      flex-grow: ${flexGrow};
    `;

    return (
      <div css={container}>
        <CategoryPieChart
          width={width}
          height={height}
          data={data}
          displayLabel={true}
          nameKey={PERSON_HOURS_RATIO_DATA.NAME}
          dataKey={PERSON_HOURS_RATIO_DATA.VALUE}
          dataType="percent"
        />
      </div>
    );
  }
);

export default PersonHoursRatio;
