import { TaskData } from "../../types/tasks/tasks.type"
import { getFutureDate } from "../../lib/calendar"
import { convertDateToString } from "../../lib/converter"
import { TASK_STATUS, PRIORITY_STATUS} from "../status/status.constant"

export const DEMO_TASK_DATA: TaskData[] = [
  
  {
    id: 1,
    taskTitle: "報告書作成",
    status: TASK_STATUS.DOING,
    priority: PRIORITY_STATUS.HIGH,
    dueDate: convertDateToString(new Date()),
  },
  {
    id: 2,
    taskTitle: "UIコンポーネント作成",
    status: TASK_STATUS.DOING,
    priority: PRIORITY_STATUS.MIDDLE,
    dueDate: convertDateToString(getFutureDate(1)),
  },
  {
    id: 3,
    status: TASK_STATUS.NOT_START,
    taskTitle: "ソースコードリファクタ",
     priority: PRIORITY_STATUS.LOW,
    dueDate: convertDateToString(getFutureDate(20))
  }
]