import { StatusLabel } from "../types/index.type"

/**
 * ステータスからテキストへ変換
 * @param labelList 
 * @param index 
 * @returns string
 */
export const convertStatusToLabel = (labelList: StatusLabel[], index: number) => {
  return labelList.find((item) => item.status === index)?.label ?? "";
};

/**
 * 簡略名称へ変換
 * @param value 
 * @param maxLength 
 * @returns string
 */
export const convertToShortName = (value: string, maxLength: number) => {
  if (value.length <= maxLength) return value;
  return value.substring(0, maxLength) + "...";
};

/**
 * 会社正式名称から簡略名称に変換
 * @param companyOfficialName 
 * @param maxLength 
 * @returns string
 */
export const convertToShortCompanyName = (companyOfficialName: string, maxLength: number) => {
  const removeCompanyType = companyOfficialName.replace(/株式会社|合名会社|有限会社|合同会社|合資会社/g, '');
  return convertToShortName(removeCompanyType, maxLength);
};

/**
 * 円単位から万単位に変更
 * @param value 
 * @returns number
 */
export const convertToTenThousand = (value: number): number => {
  return Math.floor(value / 10000);
};


/**
 * Date型からstring型へ変換
 * @param date 
 * @returns string
 */
export const convertDateToString = (date: Date): string => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
};