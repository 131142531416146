import { CSSProperties } from "react";
import { DEFAULT_FRAME_SHADOW } from "./color.constant";

export const CHART_TOOLTIP_STYLE: CSSProperties = {
  background: "#ffffff",
  border: "none",
  borderRadius: "8px",
  color: "#585858",
  boxShadow: DEFAULT_FRAME_SHADOW,
  position: "absolute",
};
