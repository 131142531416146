import { FC, memo } from "react";
import { TaskData } from "../../../types/tasks/tasks.type";
import TemplateContainer from "../../atoms/container/template-container";
import SummaryFrame from "../../molecules/frame/summary/summary-frame";
import InformationFrame from "../../molecules/frame/information/information-frame";
import IndicatorCard from "../../molecules/cards/indicator-card/indicator-card";
import AllTaskList from "../../organisms/list/tasks/all-task-list";
import {
  DEFAULT_PAGE_WIDTH,
  DEFAULT_SUMMARY_MARGIN,
} from "../../../constants/style/size.constant";
import {
  TASK_TITLE,
  TASK_LIST_TITLE,
  NOT_STARTED_TASKS_TITLE,
  DOING_TASKS_TITLE,
  COMPLETED_TASKS_TITLE,
  ADD_TASKS_TITLE,
  TASKS_MANAGEMENT_TITLE,
} from "../../../constants/title/title.constant";
import {
  WHITE_COLOR,
  TASK_SUMMARY_COLOR,
  QUICK_START_CARD_COLORS,
} from "../../../constants/style/color.constant";
import { TASK_STATUS } from "../../../constants/status/status.constant";
import { KEN_UNIT } from "../../../constants/unit/unit.constant";
import QuickStartCard from "../../molecules/cards/quick-start-card/quick-start-card";
import { QUICK_START_ICON } from "../../../constants/element/element.constant";

interface TasksProps {
  taskData: TaskData[];
  onClickAddTasks: () => void;
  onClickTasksManagement: () => void;
  onClickDeleteTask: (taskId: number) => void;
}

const Tasks: FC<TasksProps> = memo(
  ({
    taskData,
    onClickDeleteTask,
    onClickAddTasks,
    onClickTasksManagement,
  }) => {
    const notStartedTasks = taskData.filter(
      (task) => task.status === TASK_STATUS.NOT_START
    ).length;

    const doingTasks = taskData.filter(
      (task) => task.status === TASK_STATUS.DOING
    ).length;

    const completedTasks = taskData.filter(
      (task) => task.status === TASK_STATUS.DONE
    ).length;

    return (
      <TemplateContainer width={DEFAULT_PAGE_WIDTH}>
        <SummaryFrame
          title={TASK_TITLE}
          gradation={true}
          titleColor={WHITE_COLOR}
          backgroundColor={TASK_SUMMARY_COLOR}
        >
          <InformationFrame
            titleColor={WHITE_COLOR}
            title={NOT_STARTED_TASKS_TITLE}
            margin={DEFAULT_SUMMARY_MARGIN}
            textAlign="center"
          >
            <IndicatorCard
              indicatorValue={notStartedTasks}
              indicatorUnit={KEN_UNIT}
              color={WHITE_COLOR}
            />
          </InformationFrame>

          <InformationFrame
            titleColor={WHITE_COLOR}
            title={DOING_TASKS_TITLE}
            margin={DEFAULT_SUMMARY_MARGIN}
            textAlign="center"
          >
            <IndicatorCard
              indicatorValue={doingTasks}
              indicatorUnit={KEN_UNIT}
              color={WHITE_COLOR}
            />
          </InformationFrame>

          <InformationFrame
            titleColor={WHITE_COLOR}
            title={COMPLETED_TASKS_TITLE}
            margin={DEFAULT_SUMMARY_MARGIN}
            textAlign="center"
          >
            <IndicatorCard
              indicatorValue={completedTasks}
              indicatorUnit={KEN_UNIT}
              color={WHITE_COLOR}
            />
          </InformationFrame>
        </SummaryFrame>

        <InformationFrame direction="row" gap="20px">
          <QuickStartCard
            boldText={ADD_TASKS_TITLE}
            lineColor={QUICK_START_CARD_COLORS[1]}
            flexGrow={1}
            icon={QUICK_START_ICON.ADD}
            onClickQuickStart={onClickAddTasks}
          />

          <QuickStartCard
            boldText={TASKS_MANAGEMENT_TITLE}
            lineColor={QUICK_START_CARD_COLORS[5]}
            flexGrow={1}
            icon={QUICK_START_ICON.MANAGEMENT}
            onClickQuickStart={onClickTasksManagement}
          />
        </InformationFrame>

        <AllTaskList
          taskData={taskData}
          title={TASK_LIST_TITLE}
          onClickDeleteTask={onClickDeleteTask}
        />
      </TemplateContainer>
    );
  }
);

export default Tasks;
