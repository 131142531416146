// import Router from "./routes/router";
import { router } from "./routes/router";
import UserProfileProvider from "./providers/user-profile-provider";
import AuthUserProvider from "./providers/auth-user-provider";
import DarkScreenProvider from "./providers/dark-screen-provider";
import DarkScreen from "./components/atoms/screen/dark-screen";
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import CustomDialog from "./components/organisms/dialog/custom-dialog";
import DialogProvider from "./providers/dialog-provider";

function App() {
  return (
    <AuthUserProvider>
      <UserProfileProvider>
        <DarkScreenProvider>
          <DialogProvider>
            <CustomDialog />
            <DarkScreen />
            <RouterProvider
              router={createBrowserRouter(createRoutesFromElements(router))}
            ></RouterProvider>
          </DialogProvider>
        </DarkScreenProvider>
      </UserProfileProvider>
    </AuthUserProvider>
  );
}

export default App;
