/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { css } from "@emotion/react";
import Text from "../../../atoms/characters/text/text";
import Number from "../../../atoms/characters/number/number";
import { DEFAULT_TEXT_COLOR } from "../../../../constants/style/color.constant";
import {
  checkUnitIsPixel,
  removeUnitFromStyle,
  resizePCtoSP,
} from "../../../../lib/style-composer";
import { MEDIA_QUERY } from "../../../../constants/style/media.constant";

interface IndicatorCardProps {
  indicatorFontSize?: string;
  unitFontSize?: string;
  noteFontSize?: string;
  indicatorValue: number;
  indicatorUnit: string;
  note?: string;
  color?: string;
  withComma?: boolean;
}

const IndicatorCard: FC<IndicatorCardProps> = memo(
  ({
    indicatorFontSize = "80px",
    unitFontSize = "16px",
    indicatorValue,
    indicatorUnit,
    note,
    noteFontSize = "13px",
    color = DEFAULT_TEXT_COLOR,
    withComma = false,
  }) => {
    const container = css`
      display: flex;
      justify-content: center;
      align-items: end;
    `;

    const spTextStyle = css`
      ${MEDIA_QUERY.SP} {
        font-size: ${resizePCtoSP(
          removeUnitFromStyle(indicatorFontSize),
          0.6
        )}px;
      }
    `;

    const mediaQuery = checkUnitIsPixel(indicatorFontSize)
      ? spTextStyle
      : undefined;

    return (
      <div>
        <div css={container}>
          {withComma ? (
            <Text
              text={indicatorValue.toLocaleString()}
              fontSize={indicatorFontSize}
              color={color}
              isBold={true}
              style={mediaQuery}
            />
          ) : (
            <Number
              value={indicatorValue}
              fontSize={indicatorFontSize}
              color={color}
              isBold={true}
              style={mediaQuery}
            />
          )}

          <Text text={indicatorUnit} fontSize={unitFontSize} color={color} />
        </div>
        {note ? (
          <Text text={`※${note}`} fontSize={noteFontSize} color={color} />
        ) : null}
      </div>
    );
  }
);

export default IndicatorCard;
