import { ClientInformation } from "../../types/clients/clients.type";
import { getPreviousDate, getFutureDate} from "../../lib/calendar";
import { convertDateToString } from "../../lib/converter";
import { CONTRACT_STATUS } from "../status/status.constant";
import { DEMO_COMPANIES } from "./common.constant";

export const DEMO_CLIENTS_DATA: ClientInformation[] = [
  {
    companyName: DEMO_COMPANIES.A,
    contractStartDate: convertDateToString(getPreviousDate(40)),
    contractEndDate: convertDateToString(getFutureDate(200)),
    contractStatus: CONTRACT_STATUS.UNDER_CONTRACT
  },
  {
    companyName: DEMO_COMPANIES.B,
    contractStartDate: convertDateToString(getPreviousDate(23)),
    contractEndDate: convertDateToString(getFutureDate(250)),
    contractStatus: CONTRACT_STATUS.UNDER_CONTRACT
  },
  {
    companyName: DEMO_COMPANIES.C,
    contractStartDate: convertDateToString(getPreviousDate(12)),
    contractEndDate: convertDateToString(getFutureDate(290)),
    contractStatus: CONTRACT_STATUS.UNDER_CONTRACT
  },
  {
    companyName: DEMO_COMPANIES.D,
    contractStartDate: convertDateToString(getPreviousDate(170)),
    contractEndDate: convertDateToString(getPreviousDate(65)),
    contractStatus: CONTRACT_STATUS.END
  },
  {
    companyName: DEMO_COMPANIES.E,
    contractStartDate: convertDateToString(getPreviousDate(260)),
    contractEndDate: convertDateToString(getPreviousDate(110)),
    contractStatus: CONTRACT_STATUS.END
  },
  {
    companyName: DEMO_COMPANIES.F,
    contractStartDate: convertDateToString(getPreviousDate(290)),
    contractEndDate: convertDateToString(getPreviousDate(180)),
    contractStatus: CONTRACT_STATUS.END
  },
  {
    companyName: DEMO_COMPANIES.G,
    contractStartDate: convertDateToString(getPreviousDate(350)),
    contractEndDate: convertDateToString(getPreviousDate(210)),
    contractStatus: CONTRACT_STATUS.END
  }
]